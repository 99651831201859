import React, { Component } from "react";
import '.././../assets/reactCustomStyle.css';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // const { idLoader } = this.props;

    return (
      <div className="loader" id="newLoader">
        <div className="sk-circle">
          <div className="sk-circle1 sk-child" />
          <div className="sk-circle2 sk-child" />
          <div className="sk-circle3 sk-child" />
          <div className="sk-circle4 sk-child" />
          <div className="sk-circle5 sk-child" />
          <div className="sk-circle6 sk-child" />
          <div className="sk-circle7 sk-child" />
          <div className="sk-circle8 sk-child" />
          <div className="sk-circle9 sk-child" />
          <div className="sk-circle10 sk-child" />
          <div className="sk-circle11 sk-child" />
          <div className="sk-circle12 sk-child" />
        </div>
      </div>
    );
  }
}

export default Loader;

























































// import React, { Component } from "react";
// import Loader from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//  class Load extends Component {

//     constructor(props) {
//         super(props);
//       this.state = {
//           data:{
//             loader: false
//           }

//       }
//         this.sayHello = this.sayHello.bind(this);
//       }
    
//       sayHello() {
//           
      
//        this.setState({loader: true});
//       }
     
//     //other logic
//    render() {
//     return(
//         <React.Fragment>
//         <center>
//        <Loader
//         type="Oval"
//         color="red"
//         height={200}
//         width={300}
//         visible = "true"
//        /> 
//      </center>
//      </React.Fragment>
//     );
   
//    }
// }

// export default Load;

