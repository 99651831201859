import React, { Component, useRef } from "react";
import Leftbar from "./Leftbar";
import CarCard from "./carcard";
import QuickView from "./quickview";
import Breadcrum from "../breadcrum";
import { withRouter, Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetService, PostService } from "../../services/GeneralServices";
import CompareLink from "./CompareLink ";
import Loader from '../General/loader';
import Form from '../common/form'


import {
  FacebookShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
class FeaturedCars extends Form {

  constructor(props) {
    super(props);
    this.state = {

      loader: true,
      SortBy: [
        { Id: "1", Name: "Price: Low to High" },
        { Id: "2", Name: "Price: High to Low" },
        { Id: "3", Name: "Mileage: Low to High" },
        { Id: "4", Name: "Mileage: High to Low" },
        { Id: "5", Name: "Model Year: Latest First" },
        { Id: "6", Name: "Model Year: Oldest First" },
      ],
      sortByVal: "",
      count: 0,
      check: false,
      redirect: false,
      Exception: false,
      changeIndex: 0,
      Cars: [],
      AllCars: [],
      Stc: [],
      onCheck: false,
      changeView: false,
      data: {
        Count: 0
      },
      CompareArray: [],
      FilterValues: {
        Make: [],
        Model: [],
        Variant: [],
        Year: [],
        City: [],
        Price: "",
        Registration_City: [],
        Mileage: "",
        Transmission: [],
        SortBy: "5",
        FilterFrom: "",
      },
      lazyCars: [],
      lazyStart: 0,
      LazyEnd: 8,
      upCars: [],
      loadmore: true,
      advert_stop: false,
      showdiv:false

    };

    this.UpdateFlag = this.UpdateFlag.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.CompareVehicle = this.CompareVehicle.bind(this);
    this.CompareVehicle1 = this.CompareVehicle1.bind(this);
    this.LeftBarEventHandler = this.LeftBarEventHandler.bind(this);
    this.child = React.createRef();
    this.loadMore = this.loadMore.bind(this);

  }
  filterLazyCars(lazyStart, lazyEnd, Cars, action) {

    // ;
    if (lazyStart <= Cars.length) {
      const lazyCars = Cars.slice(lazyStart, (lazyStart + lazyEnd)).map((car) => {
        return car
      })

      //console.log(lazyCars);
      if (action == "searched") {
        this.setState({
          lazyStart: lazyStart + lazyEnd,
          upCars: [...lazyCars], advert_stop: false
          , loadmore: true,
        })
      }
      else {

        this.setState({
          lazyStart: lazyStart + lazyEnd,
          upCars: [...this.state.upCars, ...lazyCars], advert_stop: false,
          loadmore: true,
        })
      }


    }
    else {
      this.setState({
        loadmore: false,
        advert_stop: false
      })
    }


  }
  
  loadMore() {


    this.filterLazyCars(this.state.lazyStart, this.state.LazyEnd, this.state.Cars, "");
  }

  CompareVehicle(id, i) {


    // if( document.getElementById(id).contains('active') == false ){
    //   document.getElementById(id).classList.remove("active")
    // }


    const data = { ...this.state.data };

    var CheckVal = this.state.CompareArray.filter((val, index) => {
      // 
      return val["car"].VehicleID == id
    })
    if (CheckVal.length == 0) {
      // 
      if (this.state.CompareArray.length < 3) {
        if (document.getElementById(id)) {
          document.getElementById(id).classList.add("active");
        }

        this.state.CompareArray.push(this.state.Cars[i]);
        data["Count"] += 1;
        document.getElementById("stsCompareNotification").style.display = "block";
        setTimeout(function () { document.getElementById("stsCompareNotification").style.display = "none"; }, 3000);
      }
      else {
        toast.warn("Only Three Vehicles Can Be Selected", {
          position: toast.POSITION.TOP_RIGHT
        })
      }

    }
    else {
      // 
      if (document.getElementById(id)) {
        document.getElementById(id).classList.remove("active")
      }

      this.state.CompareArray = this.state.CompareArray.filter((val, index) => {

        return val["car"].VehicleID != id
      });
      data["Count"] -= 1;
    }



    this.setState({ data, advert_stop: false })

  }
  CompareVehicle1() {
    // 

    if (document.getElementById("stsCBar")) {
      document.getElementById("stsCBar").className += " active";
    }



  }
  UpdateFlag() {
    // 
    if (this.state.check == false) {
      this.setState({ check: true });
    } else {
      this.setState({ check: false });
    }
  }

  handleClick(i) {
    //console.log(i);
    //  

    if (i >= 0 && i < this.state.Cars.length) {
      this.setState({ changeIndex: i });
      this.setState({ check: true });
    } else {
      // alert("No Item Found");
    }
  }
  TrackingID = 1;
  trackScrolling = () => {
    const wrappedElement = document.getElementById('ScrollEnd');
    if (this.isBottom(wrappedElement)) {
       
      this.TrackingID++;
      if(this.TrackingID !== 1){
        let EndIndex = this.TrackingID * 8; 
        let RnArry = [];
          this.state.Cars.slice(0,EndIndex).map((item,i) => {
            RnArry.push(item);
          });
          
          this.setState({ AllCars: RnArry, loader: false }, ()=>{
            this.filterLazyCars(this.state.lazyStart, this.state.LazyEnd, this.state.AllCars, "");
          });
      }
      console.log('header bottom reached');
     // document.removeEventListener('scroll', this.trackScrolling);
    }
  };
  componentDidMount() {
    // document.getElementById('newLoader').style.display = 'none';
    document.addEventListener('scroll', this.trackScrolling);

  }
  isBottom(el) {
    if(el){
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
  }
  async componentWillMount() {

document.removeEventListener('scroll', this.trackScrolling);
    if (this.props.location.state == undefined) {
    
      await PostService("api/vehicle/GetFDCars", this.state.FilterValues).then(response => {
         
        
        if (response.data.Data.ListOfCarsWithImages.length > 0) {
          
          this.setState({Cars: response.data.Data.ListOfCarsWithImages, loader: false });
          let RnArry = [];
          this.state.Cars.slice(0,8).map((item,i) => {
            RnArry.push(item);
          });
          this.setState({ AllCars: RnArry, loader: false});
        }
        else {
           
          this.setState({showdiv: true });
          // toast.warn("No result found", {
          //   position: toast.POSITION.TOP_RIGHT
          // })
          this.setState({Cars: response.data.Data.ListOfCarsWithImages, loader: false });
          let RnArry = [];
          this.state.Cars.slice(0,8).map((item,i) => {
            RnArry.push(item);
          });
          this.setState({ AllCars: RnArry, loader: false });
          ///this.setState({ Cars: response.data.Data.ListOfCarsWithImages, loader: false });

        }
      })
        .catch(error => {
          this.setState({ loader: false, Exception: true })
        });

    }
    else {

      if (this.props.location.state.data == 'IsCertified') {
        this.state.FilterValues.FilterFrom = "IsCertified";
        await PostService("api/vehicle/GetFDCars", this.state.FilterValues).then(response => {
          if (response.data.Data.ListOfCarsWithImages.length > 0) {
            // var certifiedData = data.Data.ListOfCarsWithImages.filter((val, index) => {

            //   return val.car.IsCertified == true
            // })
            this.setState({Cars: response.data.Data.ListOfCarsWithImages, loader: false });
          let RnArry = [];
          this.state.Cars.slice(0,8).map((item,i) => {
            RnArry.push(item);
          });
          this.setState({ AllCars: RnArry, loader: false });

            //  console.log('iscertified', certifiedData)
          }
          else {

            // toast.warn("No result found", {
            //   position: toast.POSITION.TOP_RIGHT
            // })
            //this.setState({ Cars: response.data.Data.ListOfCarsWithImages, loader: false });
            let RnArry = [];
            this.state.Cars.slice(0,8).map((item,i) => {
              RnArry.push(item);
            });
            this.setState({ AllCars: RnArry, loader: false });
  
          }
        })
          .catch(error => {
             ;
            this.setState({ loader: false, Exception: true })
          })

      }

      else if (this.props.location.state.data.length > 0) {

        this.setState({Cars: this.props.location.state.data, loader: false });
          let RnArry = [];
          this.state.Cars.slice(0,8).map((item,i) => {
            RnArry.push(item);
          });
          this.setState({ AllCars: RnArry, loader: false });

      }
      else {

        // toast.warn("No result found", {
        //   position: toast.POSITION.TOP_RIGHT
        // })
        this.setState({Cars: this.props.location.state.data, loader: false });
          let RnArry = [];
          this.state.Cars.slice(0,8).map((item,i) => {
            RnArry.push(item);
          });
          this.setState({ AllCars: RnArry, loader: false });

      }

    }
    ;
    this.filterLazyCars(this.state.lazyStart, this.state.LazyEnd,this.props.location.state == undefined && this.state.Cars.length == 0 ? [] : this.state.Cars.length == 0 ? this.props.location.state.data : this.state.Cars, "");
    //window.addEventListener("scroll", this.onScroll, false);


  }

  LeftBarEventHandler(data) {

    // ;
    // CompareArray and data object count will be null/0
    this.setState({

      Cars: data.ListOfCarsWithImages,
      upCars: data.ListOfCarsWithImages,
      CompareArray: [], data: {
        ...this.state.data,
        Count: 0
      }, advert_stop: false, lazyStart: 0,
      LazyEnd: 8,

    }, () => { this.filterLazyCars(this.state.lazyStart, this.state.LazyEnd, this.state.upCars, "searched") });

  }

  changeSortBy = ({ currentTarget: input }) => {


     
    this.setState({ sortByVal: input.value }, () => {
      this.child.current.onSearchHandler();
    })




  }
  CompareBtn = () => {

    if (this.state.CompareArray.length == 1) {
      toast.warn("Select atleast Two cars to compare!", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    if (this.state.CompareArray.length != 1) {
      this.setState({
        redirect: true
      })
    }

  }

  renderRedirect = () => {

    if (this.state.redirect) {

      return this.props.history.push({ pathname: '/compare', state: { data: this.state.CompareArray } })

    }
    else {

    }
  }


  renderCarDetails(CompareArray) {

    if (this.state.CompareArray.length > 0) {
      return (<p>{`${CompareArray.slice(-1)[0].car.VariantName} ${CompareArray.slice(-1)[0].car.ModelName} - Added to compare`}</p>)

    }
  }

  renderException = () => {
     ;

    if (this.state.Exception) {

      return <Redirect to={{ pathname: '/404' }} />

    }
    else {

    }
  }



  renderUpdatedCars() {
    var increment = 0;
    // console.log('this.state.Cars.length--', this.state.Cars.length);
    // console.log(this.state.Cars);
    // console.log('this.state.upCars.length--', this.state.upCars.length);
    // console.log(this.state.upCars);


    this.state.advert_stop = false;

    // ;
    return (<div className="row">
      {this.state.upCars.length != 0 ? this.state.upCars.map((item, index) => {

        //console.log('increment---',increment);

        // if (increment  == 5 ) {
        //   var idx = increment;
        //   var randomad = Math.floor(Math.random() * this.state.advertisment.length) ;
        //   increment = 0 ;
        //   return (<div id={`${idx}-${this.state.advert_stop}`}  className="col-lg-4 col-md-6 col-12 card-col" key={this.state.advertisment[randomad].id}>
        //     <img src={this.state.advertisment[randomad].image} alt="" className="h-100 w-100 tys-promotion" />
        //   </div>)

        // }
        // else if (increment  == 4 && this.state.advert_stop != true) {
        //   var idx = increment;
        //   increment = 0 ;
        //   var randomad = Math.floor(Math.random() * this.state.advertisment.length) ;
        //   this.state.advert_stop = true
        //   return (<div id={`${idx}-${this.state.advert_stop}`} className="col-lg-4 col-md-6 col-12 card-col" key={this.state.advertisment[randomad].id}>
        //     <img src={this.state.advertisment[randomad].image} alt="" className="h-100 w-100 tys-promotion" />
        //   </div>)
        //  }

        return <CarCard
           name={index}
          data={item}
          Handler={this.handleClick}
          value="true"
          compareHandler={this.CompareVehicle}
          ChangeView={this.state.changeView}
          key={index}
        />

      } ) : <div className="NoFoundDiv" style={this.state.showdiv ? {visibility: "visible"} : { visibility:"hidden"}}> No Result Found</div>
      
      
      }
    </div>)

  }

  getValueOfLeftBarProps = (propsValue) =>{
    this.setState({
      showdiv: propsValue
    })
  }

  render() {

    // if (this.state.Cars == "" || this.state.Cars == undefined || this.state.Cars == null) {
    //   return this.state.loader ? <Loader /> : '';
    // }

    return (
      <React.Fragment>

        {this.state.loader ? <Loader /> : ''}
        <div className="tys-page">
          <Breadcrum Name="Used Car Gala" />


          {/* -----------end------------ */}


          <div className={this.state.changeView == true ? "tys-cars-list tys-card-wrapper list" : "tys-cars-list tys-card-wrapper"} id="tydCarList">
            <div className="container" style={{ marginTop: 52 }}>
              <div className="row">
                <Leftbar ref={this.child} Sortby={this.state.sortByVal} FilteredData={this.LeftBarEventHandler} data={this.state.Cars} showDivHandler={this.getValueOfLeftBarProps}  />
                <div className="col">
                  <div className="row mt-1 mb-3">
                    <div className="col-md-4 text-left">
                      <div className="">
                        <div className="form-group" >
                          {this.renderSelect("SortBy", "", this.state.SortBy, 'Id', 'Name', this.changeSortBy.bind(this))}
                        </div>
                      </div>
                    </div>
                    <div className="col align-self-end  text-right">
                      <span
                        id="tydListBtn"
                        onClick={() => this.setState({ changeView: true, advert_stop: false })}
                        className={this.state.changeView == true ? "tys-grid-action active" : "tys-grid-action"}
                      >
                        <i className="fas fa-list"></i>
                      </span>
                      <span
                        id="tydGridBtn"
                        onClick={() => this.setState({ changeView: false, advert_stop: false })}
                        className={this.state.changeView == false ? "tys-grid-action active" : "tys-grid-action"}
                      >
                        <i className="fas fa-th"></i>
                      </span>
                    </div>
                  </div>



                  {
                    //RenderUpdated Cars for buy a CAR ///
                    this.renderUpdatedCars()
                    //RenderUpdated Cars for buy a CAR ///
                  }

                  <div className="row" id="ScrollEnd">
                    <div className="col-lg-5 col-md-5 offset-4">
                      <button className="btn btn-primary w-75" style={{display: 'none'}} onClick={this.loadMore} disabled={this.state.loadmore ? false : true}> View More </button>  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------compare panel start-------- */}
          <div id="stsCBarIcon" className="sts-icon-fixed" onClick={this.CompareVehicle1}>

            <span className="sts-icon-toggle">
              <i className="fa fa-balance-scale" aria-hidden="true" onClick={this.CompareVehicle1}></i>

              <span className="sts-notice rehub-main-color-bg">{this.state.data.Count}</span>
            </span>
          </div>
          <div id="stsCBar" className="sts-c-bar from-right">
            <CompareLink data={this.state.CompareArray}></CompareLink>
          </div>
          {/* ----------compare panel end---------- */}
          <div className="sts-compare_notification" id="stsCompareNotification">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-9">
                  <div className="sts-notification_text autocar_notify">
                    <i className="fa fa-car" aria-hidden="true"></i>
                    {this.renderCarDetails(this.state.CompareArray)}
                    {/* <p>{this.state.CompareArray.length > 0  ?  '-->' : this.state.CompareArray.slice(-1)[0].car.VariantName } - Added to compare</p> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="sts-notification_btn">
                    <a style={{ cursor: 'pointer' }} onClick={this.CompareBtn.bind(this)}>compare</a>
                    {this.renderRedirect()}
                    {this.renderException()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QuickView selectCarFunc="" checkFlag="" Check={this.state.check} name={this.state.changeIndex} handler1={this.UpdateFlag} handler2={this.handleClick} data={this.state.Cars[this.state.changeIndex]} />
        </div>

        {/* -----------------compare notification start-------------- */}
        {/* <ToastContainer /> */}


      </React.Fragment>
    );
  }
}
export default withRouter(FeaturedCars);