import React, { Component } from 'react'
import renderHTML from "react-render-html";

export default class FaqQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "",
            currentTab : "",
            checkOpen : "close",
            FAQID : "",
            subid : [],
        }


    }
    componentWillMount(){
        this.setState({ selectedTab : this.props.selectedTab })
    }
    

    OpenQuestion(e) {
      ///  e.preventDefault()
        
        const currentid = e.target.id;
        this.setState({
        
          checkOpen: this.state.currentTab === currentid && this.state.checkOpen === "close" ? "open" : "close",
          checkArea: this.state.currentTab === currentid && this.state.checkArea === "false" ? "true" : "false",
          currentTab: currentid,
        })
      }
      
    render() {
        
        if (this.props == "" || this.props == undefined || this.props == null) {
            return <div></div>;
        }
        this.state.selectedTab = this.props.selectedTab;
        //console.log('this.props.Question',this.props.Question);
        return (
            <div className="tab-content" id="v-pills-tabContent">
            
               
               
            <div className={this.state.selectedTab == this.props.selectedTab? 'tab-pane fade active show' : 'tab-pane fade'} id={this.props.selectedTab} role="tabpanel" aria-labelledby="v-pills-buy-tab">
                  
                <div className="accordion" id="accordionBuy">
                {this.props.Question.map( (Items , index ) => (
                  <div key={index} className="card ab" >
                    <div className="card-header" id="BuyheadingSix">
                      <h2 className="mb-0">
                        <button className={this.props.currentTab == Items.FAQID && this.props.checkOpen == "open" ? 'btn btn-link collapsed' : 'btn bt tn-link'} id={Items.FAQID} type="button" onClick={this.props.Handler} data-toggle="collapse" data-target="BuycollapseSix" aria-expanded={this.props.currentTab == Items.FAQID && this.props.checkArea == "false" ? 'true' : 'false'} aria-controls="BuycollapseSix">
                          {Items.Question}
                          </button>
                      </h2>
                    </div>
                    <div id="BuycollapseSix" className={this.props.currentTab == Items.FAQID && this.props.checkOpen == "open" ? 'collapse show' : 'collapse'} aria-labelledby="BuyheadingSix" data-parent="#accordionBuy">
                      <div className="card-body">
                      {renderHTML(Items.Answer)}
                        
                        </div>
                    </div>
                  </div>
                                ))}

                </div>
              
              
              </div>


</div>




        );
    }
}



