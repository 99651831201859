import React, { Component } from "react";

export default class TopButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isVisible: false
        };
      }

      componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function(e) {
          scrollComponent.toggleVisibility();
        });
      }

      toggleVisibility() {
        if (window.pageYOffset > 100) {
          this.setState({
            isVisible: true
          });
        } else {
          this.setState({
            isVisible: false
          });
        }
      }

      scrollToTopBtn() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

      render() {
        const { isVisible } = this.state;
        return (
          <div className="scroll-to-top">
            {isVisible && (
              
               <span className="fa fa-angle-up ts-top show topst" id="tsTop" aria-hidden="true" onClick={() => this.scrollToTopBtn()} ></span>
              
            )}
          </div>
        );
      }
    }