import React, { Component } from "react";
import BGimage1 from '../../assets/images/about/abt-banner_bg.png'
import BGimage2 from '../../assets/images/about/abt-detail_bg.jpg'







class Banner extends Component {
   
    render() {
        return ( 
    
        
      <div className="tys-about">
        <div className=" tys-section tys-banner" style={{backgroundImage: "url("+ BGimage1 +")" }}>
        <div className="container">
            <div className="w-50">
                <h1 className="tys-title">
                    Revolutionizing Used Car Business in <strong>Pakistan</strong>
                </h1>
                <p className="mb-5 mt-4 mr-auto">
                    Toyota SURE is the new way vehicles are bought, sold or exchanged. The
                    process of used car transactions in Pakistan can be tricky,
                    frustrating and time consuming. Car owners can avail the one window solution to fulfill their
                    automotive
                    needs at Toyota SURE dealerships.
                </p>
            </div>
        </div>  {/* <!-- <img src="assets/images/about/banner-car.png" alt=""> --> */}
        </div>
        <div className="tys-sm-banner"  style={{backgroundImage: "url("+ BGimage2 +")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md">
                        <div className="tys-abt-details">
                            <i className="icon-map"></i>
                            <h2>Availability</h2>
                            <span>Across Pakistan</span>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="tys-abt-details">
                            <i className="icon-handshake"></i>
                            <h2>42+</h2>
                            <span>Certified Dealers</span>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="tys-abt-details">
                            <i className="icon-map"></i>
                            <h2>20K</h2>
                            <span>User In Pakistan</span>
                         </div>
                     </div>
                 </div>
             </div>
        </div>
     </div>


                          
  );
}
}


export default Banner;