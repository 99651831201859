import React, { Component } from 'react';
import SpecialCar from './specialcar.jsx'
import specialContent from './specialContent.jsx'
import SpecialContent from './specialContent.jsx';
import BackgroundImage from "../../assets/images/special_bg.jpg";

class SpecialOffer extends Component {
    constructor(props){
        super(props);
        this.state={
            ArrayLenght : true ,
        }
        this.CheckData = this.CheckData.bind(this);
    }

   CheckData(){
       
       //console.log('----> special offer')
       this.setState({ ArrayLenght : false})
   }

    render() {
    
       
        return (
<div className="tys-section tys-special-offer"   style={{ backgroundImage: `url(${BackgroundImage})` , display: this.state.ArrayLenght == false ? "none" : "block" }} >
		<div className="container">
			<SpecialContent   />
			<SpecialCar handler={this.CheckData} /> 
		</div>
	</div>
        )
    }
}

export default SpecialOffer;