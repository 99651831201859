import React, { Component } from "react";
import Swiper from "react-id-swiper";
import Constumer from "../../assets/images/customer_bg.jpg";
//import Constomer1 from '../../assets/images/cutomer1.png';
import Constomer1 from "../../assets/images/Toyota Capital Motors.jpg";
import Constomer2 from "../../assets/images/Toyota Central Motors.jpg";
import Constomer3 from "../../assets/images/Toyota Faisalabad Motors.jfif";

class Testimonial extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const params = {
      centeredSlides: true, 
      slidesPerView: 3,
      loop: false,
      spaceBetween: 30,
      centeredSlides: true,
      slideClass:"customizeactive",
      containerClass: "tys-testimonial-slider "  ,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
          
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    };
    return (
      // <React.Fragment>
      <div
        className="tys-section tys-customer-testimonials l-mode"
        style={{ backgroundImage: `url(${Constumer})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col align-self-center ">
              <h1 className="tys-title-sub">
                What our customers say about the Toyota Sure Experience
              </h1>
            </div>
            <div className="col-9">
              <Swiper {...params}>
                {/* margin-right: '30px'; */}
                <div
                  className=""
                  style={{ width: "263.333px" }}
                >
                  <div className="card-flip">
                    <div className="flip">
                      <div className="front">
                        {/* <!-- front content --> */}
                        <img src={Constomer1} alt="" className="tys--ts-img" />
                      </div>
                      <div className="back">
                        {/* <!-- back content --> */}
                        <div
                          className="tys--ts-content"
                          style={{ opacity: 1, overflow: "scroll" }}
                        >
                          <i
                            className="fas fa-quote-left ts-icon"
                            aria-hidden="true"
                          ></i>
                          <span className="ts-title"> Iqbal Ahmed</span>
                          <span className="ts-location">
                            I think Toyota Sure is the best place to buy or sell
                            for used car because the available car are authentic
                            and maintained by Toyota 3S dealership.
                          </span>
                          <p>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis vero omnis id
												ratione, provident aliquam veritatis nulla voluptatibus optio temporibus. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide swiper-slide-active"
                  style={{ width: "263.333px" }}
                >
                  <div className="card-flip">
                    <div className="flip">
                      <div className="front">
                        {/* <!-- front content --> */}
                        <img src={Constomer2} alt="" className="tys--ts-img" />
                      </div>
                      <div className="back">
                        {/* <!-- back content --> */}
                        <div
                          className="tys--ts-content"
                          style={{ overflow: "scroll" }}
                        >
                          <i
                            className="fas fa-quote-left ts-icon"
                            aria-hidden="true"
                          ></i>
                          <span className="ts-title"> Mr. Farhan</span>
                          <span className="ts-location">
                            
                            I recently purchased a Corolla GLi for my parents. I
                            was referred to Toyota Central Motors Toyota Sure
                            department by a team member at Work. I was very
                            pleasantly surprised by the comprehensive and very
                            professionally managed services till receipt and
                            transfer of car. Having the surety of an authorized
                            Toyota dealer was a big plus for me.
                          </span>
                          <p>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis vero omnis id
												ratione, provident aliquam veritatis nulla voluptatibus optio temporibus. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide swiper-slide-next"
                  style={{ width: "263.333px" }}
                >
                  <div className="card-flip">
                    <div className="flip">
                      <div className="front">
                        {/* <!-- front content --> */}
                        <img src={Constomer3} alt="" className="tys--ts-img" />
                      </div>
                      <div className="back">
                        {/* <!-- back content --> */}
                        <div
                          className="tys--ts-content"
                          style={{ overflow: "scroll" }}
                        >
                          <i
                            className="fas fa-quote-left ts-icon"
                            aria-hidden="true"
                          ></i>
                          <span className="ts-title">Balwant Singh</span>
                          <span className="ts-location">
                            I’m extremely pleased with the manner I was dealt
                            with at Toyota Sure Faisalabad. The entire process
                            was transparent and I will continue to use Toyota
                            Sure for my future vehicle needs.
                          </span>
                          <p>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis vero omnis id
												ratione, provident aliquam veritatis nulla voluptatibus optio temporibus. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Swiper>
           
            </div>
          </div>
        </div>
      </div>
      // </React.Fragment>
    );
  }
}

export default Testimonial;
