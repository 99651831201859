 import React, { Component } from "react";

 import BGimage3 from '../../assets/images/about/process_bg.jpg'
 import Step1 from '../../assets/images/about/step1.png'
 import Step2 from '../../assets/images/about/step2.png'
 import Step3 from '../../assets/images/about/step3.png'
 import Step4 from '../../assets/images/about/step4.png'
 import Step5 from '../../assets/images/about/step5.png'
 
 
 
 class CertifiedProcess extends Component {
    
    constructor() {
		super();
		this.state = {
    
        seletedStep : '1',
        certifiedProcess : [
        {"step" : "1","process" : "A detailed inspection (203 check points) is conducted to ensure that the right quality of the vehicle is identified" },
        {"step" : "2","process" : "A 210 check points survey is conducted to further evaluate the vehicle" },
        {"step" : "3","process" : "A repair order is generated for any defective or damaged parts and replaced with OEM parts" },
        {"step" : "4","process" : "A Field Operations Manager reviews the repair progress and checks the 210 check points sheet" },
        {"step" : "5","process" : "Once all steps have been completed only then the vehicle gets its stamp of certification from IMC" },
        {"step" : "6","process" : "After certification the vehicle is given its 1 year/15K KM warranty booklet and can be displayed on the dealership floor" }],

       
        
    }
}

    checkStep(e){
        //console.log(e.target.id);
        const id = e.target.id;
         

       try{
            this.setState({
                seletedStep : id,
            })

        }catch(e){
            console.log(e.message);
        }
       

        

    }
    
    renderProcess(){
        const { seletedStep , certifiedProcess , seletedSteps} = this.state
       // console.log(seletedSteps);
        const currentProcess = certifiedProcess.filter((item) =>{
            return item.step == seletedStep
        })

        //console.log(currentProcess);
        const process = currentProcess.map((item) => {

            return <p className="mt-5" key={item.step}><i className="fa fa-circle color-primary"></i> 
                {item.process}
            </p>

        })

        return process;
    }
    render() {
        return (
 
 
 <div className="tys-section tys-section-process" style={{backgroundImage: `url(${BGimage3})` }}>
            <div className="container text-center">
                <h1 className="tys-title">
                VEHICLE <strong> CERTIFICATION PROCESS
</strong>
                </h1>
                <p className="mb-5 mt-4 w-75 ml-auto mr-auto">
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum
                    has been the industry's standard dummy text ever since the 1500s, */}
                    </p>
    
                {/* <!-- <img src="./assets/images/about/certification-process.png" alt="" className="w-100"> --> */}
    
                <div className="tys-steps" id="tysSteps">
                    <div className={this.state.seletedStep == '1' ? "step-item active" : "step-item"}>
                        <span className="step-title">Step 1</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="1">1</span>
                        <span className="step-desc">
                        Toyota Vehicle Inspection
                        </span>
                        <img src={Step1} alt="" />
                    </div>
                    <div className={this.state.seletedStep == '2' ? "step-item active" : "step-item"} >
                        <span className="step-title">Step 2</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="2">2</span>
                        <span className="step-desc">
                        Post Purchase Analysis
                        </span>
                        <img src={Step2} alt=""/>
                    </div>
                    <div className={this.state.seletedStep == '3' ? "step-item active" : "step-item"} >
                        <span className="step-title">Step 3</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="3">3</span>
                        <span className="step-desc">
                        Repair
                        </span>
                        <img src={Step3} alt="" />
                    </div>
                    <div className={this.state.seletedStep == '4' ? "step-item active" : "step-item"} >
                        <span className="step-title">Step 4</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="4">4</span>
                        <span className="step-desc">
                        Post Repair Review
                        </span>
                        <img src={Step4}alt="" />
                    </div>
                    <div className={this.state.seletedStep == '5' ? "step-item active" : "step-item"} >
                        <span className="step-title">Step 5</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="5">5</span>
                        <span className="step-desc">
                        Certification
                        </span>
                        <img src={Step5} alt="" />
                    </div>
                    <div className={this.state.seletedStep == '6' ? "step-item active" : "step-item"} >
                        <span className="step-title">Step 6</span>
                        <span className="step-no" onClick={this.checkStep.bind(this)} id="6">6</span>
                        <span className="step-desc">
                        Warranty
                        </span>
                    </div>
                </div>
    
                {this.renderProcess()}
                {/* <p className="mt-5"><i className="fa fa-circle color-primary"></i> At every used car has different aspects, each
                    cars is checked on 203 check points to ensure
                    the high
                    quality and provide through evaluation.</p> */}
            </div>
        </div>

);
}
}


export default CertifiedProcess;