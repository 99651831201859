import React, { Component } from 'react'
import { PostService, GetService } from '../../services/GeneralServices';
import FaqSubject from './faqsubject'
import FaqQuestion from './faqQuestion'

export default class faqQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
      currentTab: '1',
      checkOpen: "open",
      selectedTab: "1",
      checkArea: 'false',
      faqs:[],
      FaqQuestion:[]
    
    }
    this.OpenSideBar = this.OpenSideBar.bind(this)
    this.OpenQuestion = this.OpenQuestion.bind(this)

  }

  async componentWillMount() {
    //
    const { data } = await GetService('api/faqs/GetFAQsWtihSubject');
    
    this.setState({ faqs: data.Data });

    //console.log("FAQ Data", data.Data);
    
   
      //code to render questions onload
      const faqQues = this.state.faqs.filter((val,index)=>{
        return val.FAQSubjectId == this.state.selectedTab
      })
      this.setState({ FaqQuestion:faqQues })
      //code to render questions onload
  }




  OpenQuestion(e) {
  ///  e.preventDefault()
    
    //console.log('question -- ', e.target.id)
    const currentid = e.target.id;
    this.setState({
      
       checkOpen: this.state.currentTab === currentid && this.state.checkOpen === "open" ? "close" : "open",
       checkArea: this.state.currentTab === currentid && this.state.checkArea === "false" ? "true" : "false",
      currentTab: currentid,
    })

  }




  OpenSideBar(e) {
 //   e.preventDefault()
    
    if(e.length == undefined)
    {
    const faqQues = this.state.faqs.filter((val,index)=>{
      return val.FAQSubjectId == e.target.id
    })
    this.setState({ selectedTab: e.target.id, FaqQuestion:faqQues })
  }
  else 
  {
   
    const faqQues = this.state.faqs.filter((val,index)=>{
      return val.FAQSubjectId == e
    })
    this.setState({ selectedTab: e, FaqQuestion:faqQues })
  
  }
}




  render() {
    
    //faqQuery Render
    return (
      <div className="row">
        <div className="col-3">
   

      {this.state.faqs ?  <FaqSubject 
       Subject={this.state.faqs}
       Handler={this.OpenSideBar}
       selectedTab={this.state.selectedTab}
       /> : <div></div>}
      
        </div>
        <div className="col-9">
        {this.state.faqs ? <FaqQuestion 
         Question={ this.state.FaqQuestion}
         Handler={this.OpenQuestion}
         selectedTab={this.state.selectedTab}
         currentTab = {this.state.currentTab}
         checkArea = {this.state.checkArea}
         checkOpen = {this.state.checkOpen}
           /> : <div></div>}

        </div>
      </div>



    );
  }
}



