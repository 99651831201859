import React from 'react';
import Form from '../common/form'
import RenderMap from "../Map/renderMap";
import { GetService } from "../../services/GeneralServices.js";
import dealerListImg from "../../assets/images/dealers/dealers.png";
import { withRouter, Link, Redirect } from 'react-router-dom';
import Loader from '../General/loader';
import { ToastContainer, toast } from 'react-toastify';

class DealersList extends Form {
    constructor(props) {
        super(props);
        this.state = {
            Dealers: [],
            FilterDealers: [],
            TsureCities: [],
            FilterCites: [],
            selectedIndex: 0,
            Count: 0,
            SelectedName:"",
            check: false,
            isloader: true,
            data: {
                Id: '',
                Name: ''
            }
        }


        this.getautoComplete = this.getautoComplete.bind(this)
    }
    async componentDidMount() {
        try {

            const { data } = await GetService('api/vehicle/GetDealers');

            //console.log(data)
            this.setState({ TsureCities: data.Data.Tsurecities, Dealers: data.Data.la, FilterDealers: data.Data.la, Count: data.Data.la.length, isloader: false })
            this.DisplaySpecification = this.DisplaySpecification.bind(this)
        } catch (error) {
            this.setState({
                isloader: false,
            })
        }


    }


    DisplaySpecification(index) {

    }

    getautoComplete(e) {
         ;

        //console.log(e.target.value);
        const FilterData = this.state.FilterCites.filter((dealer) => {

            return dealer.Name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        })

        // if(FilterDealers.length > 0){
        this.setState({
            FilterDealers: FilterData,
            selectedIndex: 0,
        })

    }


    CityChange = (e) => {
         ;
        var index = e.nativeEvent.target.selectedIndex;
        var SelectedName = e.nativeEvent.target[index].text;

        if (e.target.value == "") {
            let count  = this.state.Dealers.length;
            this.setState({
                FilterDealers: this.state.Dealers,
                Count: count,
                SelectedName : ""
                
            })
        }
        else {
            const FilterValues = this.state.Dealers.filter((dealer) => {

                return dealer.CityId == e.target.value;
            });

            this.setState({
                FilterDealers: FilterValues,
                FilterCites: FilterValues,
                selectedIndex: 0,
                Count: FilterValues.length,
                SelectedName
            })
        }

    }
    render() {
         ;
        return (

            <React.Fragment>

                {this.state.isloader ? <Loader /> :
                    this.state.Dealers.length > 0 ?
                        <div className="tys-dealers-map w-100">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    {this.state.FilterDealers.length > 0 ?
                                        <RenderMap isRefresh={this.props.isRefresh} Heigth={"100vh"} lat={this.state.FilterDealers[this.state.selectedIndex].Latitude} lon={this.state.FilterDealers[this.state.selectedIndex].Longitude} data={this.state.FilterDealers[this.state.selectedIndex]} /> :
                                        <RenderMap isRefresh={this.props.isRefresh} Heigth={"100vh"} lon={undefined} lat={undefined} />

                                    }
                                </div>
                            </div>
                            <div className="tys-dm-list">
                                <img src={dealerListImg} />
                                <div className="w-100">
                                    <div>
                                        <select name="City" id="City" className="form-control" onChange={this.CityChange.bind(this)}>
                                            <option value="">Select City</option>
                                            {this.state.TsureCities ? this.state.TsureCities.map(option => (
                                                <option key={option["Id"]} value={option["Id"]}>
                                                    {option["Name"]}
                                                </option>
                                            )) : ''}
                                        </select>
                                    </div>
                                    <div className="input-group">

                                        <input type="text" className="form-control" id="validationDefaultUsername" onKeyUp={this.getautoComplete} placeholder="Find your Dealership" aria-describedby="inputGroupPrepend2" required />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend2"><button className="btn"><i className="fa fa-search"></i></button></span>
                                        </div>
                                    </div>
                                </div>
                                <li style={{ display: 'inline-block', textAlign: "center", padding: "10px", background: "black", width: "100%", height:"6%" }}>

                                    <span className="tys-dm-loc-name" style={{ fontSize: "12px", color: "white", display: this.state.Count == 0 ? 'none' : 'inline-block' }}>{this.state.Count} Dealers found <span style={{ display: this.state.SelectedName != "" ? 'inline-block' : 'none'}}>in</span> {this.state.SelectedName}</span>

                                </li>
                                <ul style={{ display: this.state.check == true ? 'none' : 'inline-block' }}>

                                    {
                                        this.state.FilterDealers.length > 0 ?
                                            this.state.FilterDealers.map((dealer, Index) => {
                                                return (
                                                    <li key={Index}>
                                                        <button onClick={() => {
                                                             ;
                                                            this.setState({ selectedIndex: Index, check: true });
                                                        }
                                                        }>
                                                            <span className="tys-dm-loc-name">{dealer.Name}</span>
                                                            <div className="tys-dml-loc">
                                                                <span><i className="icon-location2"></i>  {dealer.DealerAddress}</span>
                                                                {/* <span>121 KM <i className="icon-Shape-1"></i></span>                                       */}
                                                            </div>
                                                        </button>
                                                    </li>
                                                );
                                            }) : <li > <span className="tys-dm-loc-name">NOT FOUND</span> </li>}
                                </ul>
                                <div className="tys-dm-detail" style={{ display: this.state.check == true ? 'block' : 'none' }}>
                                    <div className="d-flex justify-content-between tys-dm-d-action mb-2">
                                        <div>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    this.setState({ check: false });
                                                }
                                                }
                                            ><i className="fa fa-arrow-left"></i></button>
                                        </div>
                                        <div className="tys-dm-d-action-other">
                                            <button className="btn" disabled={this.state.selectedIndex == 0 ? true : false} onClick={() => this.setState({ selectedIndex: this.state.selectedIndex - 1 })}><i className="fa fa-caret-left"></i></button>
                                            <button className="btn" disabled={this.state.selectedIndex == this.state.FilterDealers.length - 1 ? true : false} onClick={() => this.setState({ selectedIndex: this.state.selectedIndex + 1 })} ><i className="fa fa-caret-right"></i></button>
                                        </div>

                                    </div>

                                    {this.state.FilterDealers.length > 0 ? <div className="tys-dm-d-content d-flex flex-column">
                                        <h2>{this.state.FilterDealers[this.state.selectedIndex].Name}</h2>
                                        <span>
                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem magni doloribus magnam quaerat ad? Eius accusamus facere ipsam recusandae corrupti. */}
                                        </span>
                                        <span><i className="fa fa-building-o"></i> <strong>Office: </strong> {this.state.FilterDealers[this.state.selectedIndex].CityId}</span>
                                        <span><i className="fa fa-phone"></i> <strong>Phone: </strong>{this.state.FilterDealers[this.state.selectedIndex].Phone}</span>
                                    </div> : <div className="tys-dm-d-content d-flex flex-column">
                                            <h2>NOT FOUND</h2>
                                        </div>}
                                    <div className="tys-dm-d-links">
                                        <div className="d-flex">
                                            < Link to="/car"  >car</Link>
                                            < Link to="/sell-a-car" >Sell</Link>
                                            < Link to="/exchange-a-car" >Exchange</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <RenderMap Heigth={600} /> */}
                        </div>
                        : ''}
                <ToastContainer />
            </React.Fragment>
        );
    }
}

export default DealersList;