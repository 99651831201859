import React, { Component } from "react";








class NotificationCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (



// {/* <!--Compare Notification--> */}


<div className="sts-compare_notification" id="stsCompareNotification">
    <div className="container">
        <div className="row">
            <div className="col-lg-9 col-md-9">
                <div className="sts-notification_text autocar_notify"><i className="fa fa-car"></i><p>Toyota Vitz - Added to compare</p></div>
            </div>
            <div className="col-lg-3 col-md-3">
                <div className="sts-notification_btn">
                    <a href="./compare.html">compare</a>
                </div>
            </div>
        </div>
    </div>
</div>

// {/* <!--Compare Notification End-->


    
            
);
}
}
export default NotificationCompare;