import React from "react";

const Select = ({ name, label, options, optionKey, optionText, error, id, ...rest }) => {

  // 
  
    return (
      <React.Fragment>
        <label htmlFor={name}>{label}</label>
        <select name={name} id={id} {...rest} className="custom-select">
        <option value="">{name == "SortBy" ? "SortBy" : "Please Select"}</option>
          {options ? options.map(option => (
            <option key={option[optionKey]} value={option[optionKey]}>
              {option[optionText]}
            </option>
          )) : ''}
        </select>
       
  </React.Fragment>
    );
  };

export default Select;
