import React, { Component } from 'react';
import logo from '../../assets/images/logo.png';
import BGimage from '../../assets/images/footer_bg.jpg'
import toyotaLogo from '../../assets/images/toyota_logo.png';
import {  Link } from "react-router-dom";
import TopButton from '../General/topButton';

class Footer extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <footer className="tys-footer" style={{ backgroundImage: `url(${BGimage})`}}>
	<div className="container">
		<div className="row">
			<div className="col-lg col-md-12">
				<a href="https://www.toyota-indus.com/" target="_blank">
                <img src={toyotaLogo} alt="" className="ml-lg-0 mr-lg-0 ml-auto mr-auto mb-4 mb-lg-0"/>
				</a>
			</div>
			<div className="col-lg-2 col-md-6 text-center text-md-left">
				<span className="tys-title">Quick Links</span>
				<ul className="nav flex-column">
					<li className="nav-item">
						<Link className="nav-link active" to="/Home">Home</Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/about">About Us</Link>
					</li>
					{/* <li className="nav-item">
						<Link className="nav-link" to="/media">Media Gallery</Link>
					</li> */}
					<li className="nav-item">
						<Link className="nav-link" to ="/dealer">Dealers Locator</Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to ="/Privacypolicy">Privacy policy</Link>
					</li>
				</ul>
			</div>
			<div className="col-lg-3 col-md-6 text-center text-md-left">
				<div>
					<span className="tys-title">Head Office</span>
					<p>Plot No. N.W.Z/P-1, Port Qasim
						Authority, Karachi, Pakistan.</p>
				</div>
				<div>
					<span className="tys-title">Customer Assistance Center</span>
					<p>
						Phone: +92-21-34721100
						<br />
						UAN: +92-111-86-96-82 (+92-111-TOYOTA)
					</p>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 text-center text-md-left">
				<div>
					<span className="tys-title">For Queries</span>
					<a href="mailto:customer.relations@toyota-indus.com" style={{fontSize: '13px'}}>customer.relations@toyota-indus.com</a>
				</div>
				<div className="mt-5">
					<span className="tys-title">Social Links</span>
					<ul className="list-inline social-list">
						<li className="list-inline-item"><a href="https://www.facebook.com/ToyotaSurePk/?modal=admin_todo_tour"><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
						{/* <li className="list-inline-item"><a href="https://www.twitter.com"><i className="fab fa-twitter" aria-hidden="true"></i></a></li> */}
						<li className="list-inline-item"><a href="https://www.instagram.com/toyotasurepk/?hl=en"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
						<li className="list-inline-item"><a href="https://www.youtube.com/channel/UC5YRUqR4n953JeHscbqCqqg/featured?view_as=subscriber"><i className="fab fa-youtube" aria-hidden="true"></i></a></li>
						<li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=923001869682"><i className="fab fa-whatsapp" aria-hidden="true"></i></a></li>
                        <li className="list-inline-item"><a href="https://www.toyota-indus.com/"><i className="icon-toyota-mini"></i></a></li>
					
                    </ul>
				</div>
			</div>
			<div className="col-lg col-md-12 text-center text-lg-center">
            <Link  to="/Home"><img src={logo} alt="" className="ml-auto mr-auto ml-auto mr-auto mb-2"/></Link>
				<small>Toyota Sure © All Right resevers</small>
			</div>
		</div>
	</div>
    <TopButton/>
</footer>
/* <React.Fragment>

<React.Fragment/> */
        //     <div className="tys-footer"  style={{ backgroundImage: `url(${BGimage})`}}>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-lg-2 col-md-12">
        //               <a href='https://www.toyota-indus.com/'><img src={toyotaLogo} alt="" className="ml-lg-0 mr-lg-0 ml-auto mr-auto mb-4 mb-lg-0"/></a>  
        //             </div>
        //             <div className="col-lg-2 col-md-6">
        //                 <span className="tys-title">Quick Links</span>
        //                 <ul className="nav flex-column">
        //                     <li className="nav-item">
        //                         <a className="nav-link active" href="#">Home</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">About Us</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">Media Gallery</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link " href="#">Dealer Locator</a>
        //                     </li>
        //                 </ul>
        //             </div>
        //             <div className="col-lg col-md-6">
        //                 <div>
        //                     <span className="tys-title">Head Office</span>
        //                     <p>Plot No. N.W.Z/P-1, Port Qasim Authority, Karachi, Pakistan.</p>
        //                 </div>
        //                 <div>
        //                     <span className="tys-title">Customer Assistance Center</span>
        //                     <p>
        //                     Phone: +92-21-34721100
        //                     <br/>
        //                     UAN: +92-111-86-96-82 (+92-111-TOYOTA)
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="col-lg-3 col-md-6">
        //                 <div>
        //                     <span className="tys-title">For Queries</span>
        //                     <a  href="mailto:">customer.relations@toyota-indus.com</a>
        //                 </div>
        //                 <div className="mt-5">
        //                   <span className="tys-title">Social Links</span>
        //                   <ul className="list-inline social-list">
        //                       <li className="list-inline-item"><a href="#"><i className="icon-Sfb" ></i></a></li>
        //                       <li className="list-inline-item"><a href="#"><i className="icon-Stwitter"></i></a></li>
        //                       <li className="list-inline-item"><a href="#"><i className="icon-toyota-mini"></i></a></li>
        //                   </ul>
        //               </div>
        //             </div>
        //             <div className="col-lg col-md-12 align-self-lg-center">
        //             <Link  to="/Home"><img src={logo} alt=""/></Link>
        //                 <small>Toyota TSure © All Right resevers</small>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        )
    }
}

export default Footer;