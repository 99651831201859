import React, { Component } from "react";
import SideBar from '../compare/sideBar';
import CarDetails from '../compare/carDetails';
import CardCar from '../compare/cardCar';
import NotificationCompare from '../compare/notificationCompare';
import QucikView from '../cars/quickview';
import Breadcrum from "../../components/breadcrum";
import { GetService, PostService } from "../../services/GeneralServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../General/loader';
import { withRouter, Link, Redirect } from 'react-router-dom';
// import EmiCalculator from '../EmiCalculator/emiCalculator';



import CarTitle from "../../assets/images/carpage/car-tile.png";
import { array } from "prop-types";
class Compare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      FilterValues: [],
      CompareVehicle: [],
      Exception: false,
      loader : true,
      hoverState : '',
      Message : false
    }
    this.CompareHandle = this.CompareHandle.bind(this);
    this.Remove = this.Remove.bind(this);
    this.ChangeHoverState = this.ChangeHoverState.bind(this);
  }


 
  renderException = () => {
    
    if (this.state.Exception) {

      return <Redirect to={{ pathname: '/404' }} />

    }
    else {

    }
  }

  
  async componentWillMount() {
     ;
    console.log('this.props.location.state-----',this.props.location.state);
    if(this.props.location.state != undefined){
       ;
      this.setState({ CompareVehicle: this.props.location.state.data })
    }
    // if(this.props.location.state == undefined){
    //    ;
    //   this.setState({ CompareVehicle:  })
    // }
    try {
      const { data } = await PostService("api/vehicle/GetCars", this.state.FilterValues);
      console.log('-->>', data)
      this.setState({ data: data.Data.ListOfCarsWithImages })
      
      //this.setState({ CompareVehicle: this.props.location.state.data })
      if(this.props.location.state != undefined){
         ;
        this.setState({ CompareVehicle: this.props.location.state.data })
      }

      if (this.props.location.state == '' || this.props.location.state == undefined || this.props.location.state == null) {
        this.setState({ loader: false})
      }
    } catch (error) {
      this.setState({ loader: false, Exception: true })
    }
  
   
    //document.getElementById('newLoader').style.display = 'none';
  }



  CompareHandle(Item) {
    
    console.log(Item)

    const arr = { ...this.state.CompareVehicle };
    if (this.state.CompareVehicle.length < 3) {



      // if(this.state.CompareVehicle.car.VehicleID == Item.car.VehicleID){
      //   console.log('already')
      // }
      let NewCar = this.state.CompareVehicle.filter(val => {
        return val.car.VehicleID == Item.car.VehicleID
      })
      if (NewCar.length > 0) {
        toast.warn('already exist', {
          position:  toast.POSITION.TOP_RIGHT
        });
       
      }
      else {
        let PushedVal = this.state.CompareVehicle.concat(Item);
        this.setState({
          CompareVehicle: PushedVal
        })
        console.log(arr)
      }
    }
    else {
      toast.warn('Maximum three allow', {
        position:  toast.POSITION.TOP_RIGHT
      });
    }
    console.log(this.state.CompareVehicle)
    //  if( this.state.CompareVehicle.lenght > 3){

    //  }

  }
  Hello() {


  }
  Remove(index) {
    
    console.log(index);

    let NewCar = this.state.CompareVehicle.filter(val => {
      return val.car.VehicleID != index
    })
    
    this.setState({ CompareVehicle: NewCar })

  }


  
  ChangeHoverState(e){
    //console.log('hoverfeild',e.target.id);
    this.setState({
      hoverState : e.target.id
    })
  }

  render() {
 
    return (

      <React.Fragment>
        
        <div className="tys-page tys-page-" >


          <Breadcrum Name="COMPARE" />


          <div className="tys-compare">

            <div className="container">
              <div className="table-Wrapper">
                <SideBar ChangeHover={this.ChangeHoverState}/>
              {/* { (this.props.location.state <= 0 || )? <div className="compare-list compare-datatable ui-sortable-handle"> There is no car to compare.</div> : ''} */}
                {this.state.CompareVehicle ? <CarDetails data={this.state.CompareVehicle} RemoveFunc={this.Remove} hoverState={this.state.hoverState}/> : ''}


                {this.state.data ?  <CardCar
                  Data={this.state.data}
                  CompareHandle={this.CompareHandle}
                /> : ''}
               
                <NotificationCompare />
              </div>

            </div>
          </div>

          {this.renderException()}

        </div>
        {/* </div> */}
        <ToastContainer />
      </React.Fragment>
    );
  }



}
export default Compare;