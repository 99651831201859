import React, { Component } from "react";
import Image23 from "../../assets/images/170x230.png";
import { withRouter, Link, Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";
class SpecificationCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Redirect: false,
    };
  }

  renderRedirect = () => {
    if (this.state.Redirect) {
      return (
        <Redirect to={{ pathname: "/car", state: { data: this.props.Data } }} />
      );
    }
  };

  render() {
    //console.log("Cars",this.props.Data)
    // if (this.state.Cars == "" || this.state.Cars == undefined || this.state.Cars == null) {
    //     return <div></div>;
    // }
    return (
      <div className="tys--pc-data">
        <div className="row mb-5">
          {this.props.Data.slice(0, 4).map((car, id) => (
            <div
              className="col-md-3 mb-5 mb-sm-0"
              style={{ padding: "0" }}
              key={id}
            >
              <div
                className="tys--pc-card d-flex flex-column"
                style={{ height: "230px" }}
              >
                <img
                  className="card-img-top"
                  src={car.images.FrontImageName}
                  alt="used car"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />

                <div className="tys--pc-details d-flex flex-column">
                  <span className="car-and-price" style={{textAlign: 'center'}}>
                    <strong className="tys--pc-car" style={{fontSize:"15px"}}>
                      {car.car.MakerName} {car.car.VariantName}{" "}
                      {car.car.ModelYear}
                    </strong><br />
                    <span className="tys--pc-price" style={{fontSize:"14px"}}>
                      <strong>Demand : RS </strong>
                      <NumberFormat
                        value={car.car.RetailPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </span>
                  </span>
                  <Link
                    className="btn btn-primary"
                    onClick={() => {
                      //console.log('hello')
                    }}
                    to={{
                      pathname:
                        "/cardetails/" +
                        car.car.VehicleID +
                        "/" +
                        car.car.DealershipStationID,
                    }}
                  >
                    View Specification
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="text-center d-inline-block w-100 mt-4"
          onClick={() => this.setState({ Redirect: true })}
        >
          <span className="btn btn-red">View More</span>
          {this.renderRedirect()}
        </div>
      </div>
    );
  }
}

export default SpecificationCar;
