import React, { Component } from 'react';
import Img from "../../assets/images/vehicle_not_available.jpg";
import Swiper from 'react-id-swiper';
import NumberFormat from 'react-number-format';


import { GetService, PostService } from "../../services/GeneralServices";

class CarSlider extends Component {
    constructor(props) {
        super(props);
        this.state={
            sliderbtn1: true,
            sliderbtn2: false,
            sliderbtn3: false,
            // data:this.props.data,
        }
    }

    Getcar = data => {
    this.props.Details(data)
    }

 
    render() {
        
        console.log(this.props)
        const params = {
            pagination: '.swiper-pagination',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 4,
            check:false,
            spaceBetween: 15,
            breakpoints: {
                360: {
                slidesPerView: 1,
                spaceBetween: 20
                },
                1024:{
                slidesPerView: 4,
                spaceBetween: 20
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            

        }
    
 
        return (
        <React.Fragment>
       {this.props.data.length < 4 ? 
       	<div className="container">
        <div className="tys-so-card tys-card-wrapper mt-5 ">
        <div className="row">
        {this.props.data.map((item, index) => {
                        return (
                            <div className="col-md-4 mb-4 mb-sm-0 pr-md-4 pl-md-4"  key={item.car.VehicleID} onClick={ () => this.Getcar(item)}>
                                <div className="card-col">
                                    <div className="card tys-card tys-clist-card">
                                        <a  className="tys-card-link">
                                            <div className="tys-quickview">
                                                {/* <span className="tysQuickViewLink"><i className="fas fa-eye"></i></span>
                                                <span className="tysCompareLink "><i className="fas fa-exchange-alt"
                                                    aria-hidden="true"></i></span> */}
                                            </div>
                                            <img className="card-img-top" 
                                            src={item.images.FrontImageName}
                                            style={{ height: "250px", width: "286" }}
                                                alt="Card image cap" />
                                            <span className="tys--tc-price tys-tile-hide"  >
                                                RS <strong><NumberFormat value={item.car.RetailPrice} displayType={'text'} thousandSeparator={true} />
                                    </strong>
                                            </span>
    
                                        </a>
                                        <div className="card-body">
                                            <span className="tys--tc-discount tys-tile-hide " style={{ display: item.car.IsSpecialOffer == true && this.props.ChangeView == true ? "block" : "none" }}><strong>Special Offer available</strong></span>
                                            <h5 className="card-title">{item.car.MakerName} {item.car.ModelName} <br/> {item.car.VariantName}</h5>
                                            <span className="card-location">
                                                {/* <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                                {item.car.DealershipStationCityName} , {item.car.MakerName} 
                                                  Certified Dealer */}
                                                   {item.car.Name}
                                                </span>
                                        </div>
                                        <div className="car-props tys-tile-hide">
                                            <p className="card-text">
                                                <span>{item.car.ModelYear}</span>
                                                <span>{item.car.Transmission}</span>
                                                <span>{item.car.DealershipStationCityName}</span>
                                                <span>{item.car.Mileage}</span>
                                               
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        );
                    })}
                    </div> </div> </div>:  
       <Swiper {...params} >
                    {this.props.data.map((item, index) => {
                        return (
                            <div className="swiper-slide" key={item.car.VehicleID} onClick={ () => this.Getcar(item)}>
                                <div className="card-col">
                                    <div className="card tys-card tys-clist-card">
                                        <a  className="tys-card-link">
                                            <div className="tys-quickview">
                                                {/* <span className="tysQuickViewLink"><i className="fas fa-eye"></i></span>
                                                <span className="tysCompareLink "><i className="fas fa-exchange-alt"
                                                    aria-hidden="true"></i></span> */}
                                            </div>
                                            <img className="card-img-top" 
                                            src={item.images.FrontImageName}
                                            style={{ height: "250px", width: "250px" }}
                                                alt="Card image cap" />
                                            <span className="tys--tc-price tys-tile-hide">
                                                    Rs <strong><NumberFormat value={item.car.RetailPrice} displayType={'text'} thousandSeparator={true} />
                                    </strong>
                                            </span>
    
                                        </a>
                                        <div className="card-body">
                                            <span className="tys--tc-discount tys-tile-hide">
                                                {/* <strong>10%off</strong> */}
                                                </span>
                                            <h5 className="card-title">{item.car.MakerName} {item.car.ModelName} <br/> {item.car.VariantName}</h5>
                                            <span className="card-location">
                                                {/* <i className="fas fa-map-marker-alt" aria-hidden="true"></i> */}
                                               {/* {item.car.DealershipStationCityName}, */}
                                               {item.car.Name}
                                                </span>
                                        </div>
                                        <div className="car-props tys-tile-hide">
                                            <p className="card-text">
    
    
                                               <span>{item.car.ModelYear}</span>
                                                <span>{item.car.Transmission}</span>
                                                <span>{item.car.DealershipStationCityName}</span>
                                                <span>{item.car.Mileage} KM</span>
                                                {/* <span>{item.car.Mileage}</span>
                                                <span>{item.car.FuelType}</span>
                                                <span> {item.car.CC}  CC</span>
                                                <span>   {item.car.Transmission}</span>
                                                <span>7/10</span> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        );
                    })}
                </Swiper>
      }
     </React.Fragment>
           
     


        );

    }
} export default CarSlider;