import React from 'react';
import Breadcrum from "../breadcrum";
import image from '../../assets/images/emi_bg.jpg';
import img1 from '../../assets/images/cars.svg'
// import Carimage from '../../assets/images/Sell-A-Car-265x250.jpg'
// import Carimage from '../../assets/images/buyAds.jpg?0000000000000010'
import Carimage from '../../assets/images/sellacar265x250.png'

import EmiCalculator from '../emi/emiCalculator';
import CardCar from '../compare/cardCar'
import { PostService, GetService } from '../../services/GeneralServices';
import Form from '../common/form'
import Loader from '../General/loader';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter, Link, Redirect } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import QuickView from "../cars/quickview";
import { pageview,event } from '../../services/GeneralServices';
//import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


class SellACar extends Form {
	
	constructor() {
		super();
		this.state = {
			VehicleID: 0,
			DealershipStationID: 0,
			changeIndex: 0,
			check: false,
			error: "",
			DealerCities: [],
			DealershipID: null,
			UsedVehicleUnavailable: "true",
			model: {
				Make: [],
				Model: [],
				Variant: [],
				Year: [],
				City: [],
				Price: "",
				Registration_City: [],
				Mileage: "",
				Transmission: [],
				SortBy: "",
				FilterFrom: ""
			},
			toggleFinishButton: true,
			toggleNewUsed_Car: false,
			toggleHome: false,
			isloader: false,
			NewEnquiryVehicleID: null,
			isredirect: false,
			Exception: false,
			check: false,
			selectedTab: "v-pills-personal-tab",
			arrowBtn: "prevBtn",
			Cars: [],

			Fueltype: '',
			data: {
				maker: [],
				model: [],
				variant: [],
				Newmaker: [],
				Newmodel: [],
				Newvariant: [],
				Usedmaker: [],
				Usedmodel: [],
				Usedvariant: [],
				cities: [],
				tsureCities: [],
				dealershipStation: [],
				Name: '',
				phoneNumber: '',
				Contact_time: '',
				City: '',
				error: "",
				Model: '',
				Make: '',
				Expected_Price: '',
				SaleCarVariant: '0',
				SaleRegCity: '0',
				Year: '',
				Color: '',
				Mileage: '',
				Fuel_type: '',
				InspectionType: "",
				Owner_No: '',
				Dealer_Location: '',
				Customer_Address: '',
				Id: '',
				DealerShip: '0',
				UsedMake: '',
				UsedModel: '',
				UsedVariant: '',
				UsedCity: '',


			},

			VehicleDetail: {
				maker: [],
				model: [],
				variant: [],
			},
			Model: [],
			Variant: [],
			UsedModel1: [],
			NewModel: [],
			UsedVariant: [],
			NewVariant: [],
			vehicleVariant: [],
			vehicleDetailVariant: [],
			VehicleDetailModel: [],

			_personal_d: true,
			_vehicle_d: true,
			_vehicle_i: true,
			_exchange_v: true,
		}
		this.UpdateFlag = this.UpdateFlag.bind(this);
		this.selectCar = this.selectCar.bind(this);
		this.SearchHandler = this.SearchHandler.bind(this);
		this.NewCarEnquiryHandler = this.NewCarEnquiryHandler.bind(this);
		this.CheckExpected = this.CheckExpected.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.childRef = React.createRef();

	}

	OnOpenTab = (e) => {
		 ;
		if(e.target.id=="v-pills-vdetails-tab")
		{
			ReactPixel.track('User Clicked', "Next");
		}
		if(e.target.id=="v-pills-inspect-tab")
		{
			ReactPixel.track('User Clicked', "Next");
		}

		const el = e.target == undefined ? e : e.target.id;
		if (el == "v-pills-inspect-tab") {

			this.setState({
				selectedTab: el

			})
		}
		else {
			this.setState({
				selectedTab: el,

			})
		}

	}

	OnArrowBtn = (e) => {
		//
		const el = e.target;

		this.setState({

			arrowBtn: el.id
		})
	}
    

	async componentDidMount() {
		try {
			const { data } = await GetService('api/vehicle/GetDropDown');
			//	this.VehicleDetail();
			//console.log("Data", data);
			let data1 = { ...this.state.data };
			data1["maker"] = data.Data.maker;
			data1["model"] = data.Data.model;
			data1["variant"] = data.Data.variant;
			data1["cities"] = data.Data.cities;
			data1["dealershipStation"] = data.Data.dealershipStation;
			data1["tsureCities"] = data.Data.Tsurecities;
			data1["Allmaker"] = data.Data.Allmaker;
			data1["Allmodel"] = data.Data.Allmodel;
			data1["Allvariant"] = data.Data.Allvariant;
			data1["Newvariant"] = data.Data.Allvariant;
			data1["Newmaker"] = data.Data.Newmaker;

			data1["Newmodel"] = data.Data.Allmodel.filter((x, index) => {
				return x.IsCertifiable == true;
			});



			this.setState({ data: data1, isloader: false })

		} catch (error) {
			this.setState({ isloader: false, Exception: true })
			//alert(error);
		}

	}

	handleChange = ({ currentTarget: input }) => {
		 
		const data = { ...this.state.data };
		data[input.name] = input.value;

		this.setState({ data }, () => { this.checkvalidation() });

	};

	RedirectHome() {

		return  this.props.history.push('/Home')
	}

	renderRedirect = () => {

		if (this.state.isredirect) {

			return  this.props.history.push('/Home')


		}
		else {

		}
	}

	renderException = () => {

		if (this.state.Exception) {

			return <Redirect to={{ pathname: '/404' }} />

		}
		else {

		}
	}

	async NewCarEnquiryHandler(id, val, DealerId, ModelID, VariantID) {

		let dta = { ...this.state.data }

		dta["UsedModel"] = ModelID;
		let values = this.state.data.variant.filter(val => {

			return val.ModelID == ModelID;
		});
		this.setState({ UsedVariant: values }, () => {
			dta["UsedVariant"] = VariantID;
			this.setState({ NewEnquiryVehicleID: id, UsedVehicleUnavailable: val, DealershipID: DealerId, data: dta }, () => {
			})



		})
	}
	CheckExpected() {

		if (parseFloat(this.state.data.Expected_Price.replace(/,/g, '')) <= 100000) {
			// toast.warn("Amount Not Less then 100,000", {
			//  	position: toast.POSITION.TOP_RIGHT
			// })
			this.setState({ error: "Amount must be greater than 100,000" })
			return
		}
		else {
			this.setState({ error: "" })
		}
		//console.log('onBlur',this.state.data.Expected_Price)

	}
	handleClick(i) {
		//console.log(i);
		//  

		if (i >= 0 && i < this.state.Cars.length) {
			this.setState({ changeIndex: i });
			this.setState({ check: true });
		} else {
			// alert("No Item Found");
		}
	}
	UpdateFlag() {
		// 
		if (this.state.check == false) {
			this.setState({ check: true });
		} else {
			this.setState({ check: false });
		}
	}
	selectCar(data) {
		 ;
		this.setState({ Vcheck: false }, () => {
			this.childRef.current.selecttoexchangecar(data);
		}, this);
	}
	onSubmit = async (e) => {
 ;
 debugger;
if(e.target.id=="thanksPanelBtn")
{
	ReactPixel.track('User Clicked', "Finish");
}
		const { isExchange } = this.props;
		console.log('Contact_time', this.state.data.Contact_time);
		var model = {
			Sno: null,
			Source: "Web",
			Remarks: "Tsure Enquiry",
			CustomerPurposeOfVisit: isExchange ? 'T' : 'S',
			CustomerInterestLevel: 'O',
			CustomerName: this.state.data.Name,
			CustomerMobile: this.state.data.phoneNumber,
			CustomerMobile2: null,
			ExpectedPriceRange: this.state.data.Expected_Price,
			NextVehExpectedPrice: null,
			NextVehSellingPrice: null,
			IsNextUsedVehicleUnavailable: this.state.UsedVehicleUnavailable == "false" ? false : true,
			City: this.state.data.City,
			MilageRange: this.state.data.Mileage,
			Fuel_type: this.state.Fueltype,
			customerAddress: this.state.data.Customer_Address,
			Dealer_Locator: this.state.data.Dealer_Location,
			DealershipStationID: this.state.UsedVehicleUnavailable == "false" ? this.state.DealershipID : this.state.data.DealerShip,
			PreferedContactTime: this.state.data.Contact_time,
			IsNextVehicleNew: isExchange && !this.state.toggleNewUsed_Car ? true : false,

			CurrentVehicle: {
				MakeID: this.state.data.Make,
				ModelID: this.state.data.Model,
				VariantID: this.state.data.SaleCarVariant,
				RegCityID: this.state.data.SaleRegCity,
				Year: this.state.data.Year,
				RegNum: "xx",
				EngineNum: "xx",
				ChasisNum: "xx",
				Mileage: this.state.data.Mileage,
				Color: this.state.data.Color,
				IsInspected: false,
				HasArived: false,
				FinalPurchasePrice: 0,
				RetailPrice: 0,
				InspectionOneSummary: {
					InspectionDate: null,
					ExteriorScore: null,
					InteriorScore: null,
					EngineScore: null,
					FrameScore: null,
					TotalScore: null,
					QualityGrade: null,
					Remarks: null,
					VehicleImages: {
						HoodImageName: null,
						FrontImageName: null,
						BackImageName: null,
						InteriorImageName: null,
						LeftImageName: null,
						RightImageName: null,
						OtherImageName: null
					}
				},
				InspectionTwoSummary: {
					InspectionDate: null,
					ExteriorScore: null,
					InteriorScore: null,
					EngineScore: null,
					FrameScore: null,
					TotalScore: null,
					QualityGrade: null,
					Remarks: null,
					VehicleImages: {
						HoodImageName: null,
						FrontImageName: null,
						BackImageName: null,
						InteriorImageName: null,
						LeftImageName: null,
						RightImageName: null,
						OtherImageName: null
					}
				},

				ArivalOfVehicle: {
					DateOfArival: null,
					Remarks: null
				},
				Refurbishments: {
					ReFubDataSheet: null,
					ReFubDataSheetName: null
				}
			},

			NextUsedVehicle: {
				VehicleID: isExchange && this.state.toggleNewUsed_Car ? this.state.NewEnquiryVehicleID : null,
				UsedCarModel: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedModel : null,
				UsedCarVaraint: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedVariant : null,
				UsedCarRegCity: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedCity : null,
				UsedCarColor: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedCarColor : null,
				UsedCarMake: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedMake : null,
				UsedCarCity: isExchange && this.state.toggleNewUsed_Car ? this.state.data.UsedCity : null,
			},

			UnavailableUsedVehicle: {
				Make: null,
				Model: null,
				Variant: null,
				Year: null,
				Color: null
			},
			NextNewVehicle: {
				MakeID: isExchange && !this.state.toggleNewUsed_Car ? this.state.data.NewMake : null,
				ModelID: isExchange && !this.state.toggleNewUsed_Car ? this.state.data.NewCarModel : null,
				VariantID: isExchange && !this.state.toggleNewUsed_Car ? this.state.data.NewCarVariant : null,
				Year: isExchange && !this.state.toggleNewUsed_Car ? 'xx' : null,
				Color: isExchange && !this.state.toggleNewUsed_Car ? this.state.data.NewCarColor : null,
				PBONum: isExchange && !this.state.toggleNewUsed_Car ? 'xx' : null,
			},

			EnquiryStatus: {
				Status: "W",
				DealFollowupData: {
					date: null,
					Reason: null
				},
				DealLostData: {
					Reason: null
				},
				DealDoneData: {
					NumberOfKeys: null,
					HasWarrentyBook: null,
					HasOwnerManual: null,
					HasSaleInvoice: null,
					HasRegistrationBook: null,
					HasRunningPage: null,
					IsCPLCClear: null,
					HasJackedNRod: null,
					HasToolkit: null,
					HasSpareWheel: null,
					HasNumPlate: null,
					HasChasisIdPlate: null
				}
			}
		};
		console.log('Model Submitted---- ', model)

		this.setState({ isloader: true , toggleFinishButton : true })
		
		await PostService('api/enquiry/new', model).then(response => {
			console.log('response get---', response);
			if (response.status == 200 || response.statusText == "OK") {
				this.setState({ isloader: false })

				this.setState({
					selectedTab: 'v-pills-thanks', UsedVehicleUnavailable: "true"
				})


				toast.success("Enquiry submit successfully", {
					position: toast.POSITION.TOP_RIGHT
				})
				setTimeout(() => {
					this.setState({
						isredirect: true
					})
				}, 3000);


			} else {
				this.setState({ isloader: false , toggleFinishButton : false })
				toast.warn(`${response.statusText} -- ${response.status}`, {
					position: toast.POSITION.TOP_RIGHT
				})

			}


		})
			.catch(data => {
				this.setState({ isloader: false , toggleFinishButton : false })
				toast.warn(`Error : ${data}`, {
					position: toast.POSITION.TOP_RIGHT
				})
				//alert(data);
			})


	}

	VehicleDetailchangeMaker = ({ currentTarget: input }) => {
		console.log('VehicleDetailchangeMaker')

		let values = this.state.data.Allmodel.filter(val => {

			return val.MakerID == input.value;
		});
		this.setState({ VehicleDetailModel: values })
		console.table(values)

	}

	VehicleDetailchangeVariant = ({ currentTarget: input }) => {

		console.log('VehicleDetailchangeVariant')
		let values = this.state.data.Allvariant.filter(val => {

			return val.ModelID == input.value;
		});
		this.setState({ vehicleDetailVariant: values })
		console.table(values)


	}
	changeMaker = ({ currentTarget: input }) => {
		console.log('changeMaker')

		let values = this.state.data.model.filter(val => {

			return val.MakerID == input.value;
		});
		this.setState({ Model: values })
		console.table(values)

	}

	changeVariant = ({ currentTarget: input }) => {

		console.log('changeVariant')
		let values = this.state.data.variant.filter(val => {

			return val.ModelID == input.value;
		});
		this.setState({ vehicleVariant: values })
		console.table(values)


	}

	Fuel_type(e) {
		let fuel = e.target.value;
		this.setState({ Fueltype: fuel })
	}
	changeusemaker = ({ currentTarget: input }) => {

		console.log('changenewMaker')


		let values = this.state.data.model.filter(val => {

			return val.MakerID == input.value;
		});

		const data = { ...this.state.data };
		data['UsedMake'] = input.value;
		let Model = { ...this.state.model };
		Model["Make"] = [];
		Model["Model"] = [];
		Model["Variant"] = [];
		Model["Make"].push({ Id: input.value, Name: input.Name });
		this.setState({ UsedModel1: values, data, model: Model, UsedVehicleUnavailable: "true", NewEnquiryVehicleID: 0 }, () => {
			this.SearchHandler(input);
		})
		//console.table(values);

		//	this.setState({ UsedModel1: values, data,model: Model});


	}
	changeusemodel = ({ currentTarget: input }) => {

		console.log('changenewmodel')


		let values = this.state.data.variant.filter(val => {

			return val.ModelID == input.value;
		});
		let Model = { ...this.state.model };
		Model["Model"] = [];
		Model["Variant"] = [];
		Model["Model"].push({ Id: input.value, Name: input.Name });
		this.setState({ UsedVariant: values, model: Model, UsedVehicleUnavailable: "true", NewEnquiryVehicleID: 0 }, () => {
			this.SearchHandler(input);
		})
		console.table(values)

	}
	changeusevariant = ({ currentTarget: input }) => {

		let Model = { ...this.state.model };
		Model["Variant"] = [];
		Model["Variant"].push({ Id: input.value, Name: input.Name });;
		this.setState({ model: Model, UsedVehicleUnavailable: "true", NewEnquiryVehicleID: 0 }, () => {
			this.SearchHandler(input);
		})
	}

	changecitySearch = ({ currentTarget: input }) => {

		console.log('changecitySearch')



		let Model = { ...this.state.model };
		Model["City"] = [];
		Model["City"].push({ Id: input.value, Name: input.Name });
		this.setState({ model: Model, UsedVehicleUnavailable: "true", NewEnquiryVehicleID: 0 }, () => {
			this.SearchHandler(input);
		})
		// console.table(value)

	}

	async SearchHandler(event) {
		 ;
		// var model = {
		// 	Maker: event.name == "UsedMake" ? event.value : "",
		// 	Model: event.name == "UsedModel" ? event.value : "",
		// 	Variant: event.name == "UsedVariant" ? event.value : "",
		// 	Year: "",
		// 	City: "",
		// 	Registraion_City: "",
		// 	KM: "",
		// 	Transmission: "",
		// 	Price: ""
		// }
		this.setState({ isloader: true });
		const { data } = await PostService("api/vehicle/GetCars", this.state.model);

		//console.table('ExchangeSearch', data.Data.ListOfCarsWithImages)
		this.setState({ Cars: data.Data.ListOfCarsWithImages, isloader: false });
	}

	changenewmaker = ({ currentTarget: input }) => {
		 ;
		let values = this.state.data.Newmodel.filter(val => {

			return val.MakerID == input.value;
		});

		const data = { ...this.state.data };
		data['NewMake'] = input.value;

		this.setState({ NewModel: values, data })
		console.table(values)

	}

	changenewmodel = ({ currentTarget: input }) => {



		let values = this.state.data.Newvariant.filter(val => {

			return val.ModelID == input.value && val.IsCurrntlyAvailable == 1;
		});
		this.setState({ NewVariant: values })
		console.table(values)

	}

	changeModel = ({ currentTarget: input }) => {


		let values = this.state.data.variant.filter(val => {

			return val.ModelID == input.value;
		});
		this.setState({ Variant: values })


	}

	onchangeCity = ({ currentTarget: input }) => {
		 ;
		let dta = { ...this.state.data }
		dta["DealerShip"] = '0';

		let values = this.state.data.dealershipStation.filter(val => {

			return val.CityId == parseInt(input.value);
		});
		this.setState({ DealerCities: values, data: dta }
			, () => {
				 ;
				console.log("check state", this.state.data.DealerShip)
			})

	}


	toggleExchangeCar(e) {
		 ;
		const toggle = e.target;
		if (toggle.checked) {
			this.setState({ UsedVehicleUnavailable: false, toggleNewUsed_Car: toggle.checked }, () => {
			})
		} else {
			this.setState({
				toggleNewUsed_Car: toggle.checked,
				UsedVehicleUnavailable: true,
			})
		}
	}

	toggleHomeCheckbox(e) {

		const toggle = e.target;
		this.setState({
			toggleHome: toggle.checked
		})
	}


	renderYear() {

		let minOffset = 0, maxOffset = 40;
		let thisYear = (new Date()).getFullYear();
		let allYears = [];
		for (let x = 0; x <= maxOffset; x++) {
			allYears.push(thisYear - x)
		}

		const yearList = allYears.map((x) => { return (<option key={x}>{x}</option>) });
		return (
			<div>
				<select style={{ width: 200 }} className="custom-select" id="Year" name="Year" onChange={this.handleChange} required>
					{yearList}
				</select>
			</div>
		);
	}

	checkvalidation() {

		// check conditions for PERSONAL INFORMATION
		if (this.state.data.Name !== "" &&
			this.state.data.phoneNumber !== "" && this.state.data.phoneNumber.length == 11) {
			this.setState({
				_personal_d: false,
			})
		} else {
			this.setState({
				_personal_d: true,
			})
		}
		// check conditions for PERSONAL INFORMATION



		// check conditions for VEHICAL DETAILS
		var _expectedPrice = parseFloat(this.state.data.Expected_Price.replace(/,/g, ''))
		if (this.state.data.Mileage !== "" && parseInt(_expectedPrice) > 100000 &&
			this.state.data.Make !== "" && this.state.data.Make !== undefined &&
			this.state.data.Model !== "" && this.state.data.Model !== undefined &&
			this.state.data.SaleCarVariant !== "" && this.state.data.SaleCarVariant !== undefined && this.state.data.SaleCarVariant !== "0" &&
			this.state.data.Color !== "" && this.state.data.Color !== undefined
		) {
			this.setState({
				_vehicle_d: false,
			})
		} else {
			this.setState({
				_vehicle_d: true,
			})
		}
		// check conditions for VEHICAL DETAILS


		// check conditions for EXCHANGE VEHICLE DETAILS
		if (!this.state.toggleNewUsed_Car) {
			if (this.state.data.NewMake !== "" && this.state.data.NewMake !== undefined &&
				this.state.data.NewCarModel !== "" && this.state.data.NewCarModel !== undefined &&
				this.state.data.NewCarVariant !== "" && this.state.data.NewCarVariant !== undefined) {
				this.setState({
					_exchange_v: false,
				})
			} else {
				this.setState({
					_exchange_v: true,
				})
			}
		} else if (this.state.toggleNewUsed_Car) {
			if (this.state.data.UsedMake !== "" && this.state.data.UsedMake !== undefined &&
				this.state.data.UsedModel !== "" && this.state.data.UsedModel !== undefined &&
				this.state.data.UsedVariant !== "" && this.state.data.UsedVariant !== undefined) {
				this.setState({
					_exchange_v: false,
				})
			} else {
				this.setState({
					_exchange_v: true,
				})
			}
		}

		// check conditions for EXCHANGE VEHICLE DETAILS

		if (this.state.data.Name !== "" &&
			this.state.data.phoneNumber !== "" &&
			this.state.data.Mileage !== ""

		) {
			this.setState({
				toggleFinishButton: false,
			})
		} else {
			this.setState({
				toggleFinishButton: true,
			})
		}
		//console.log("toggleFinishButton",this.state.toggleFinishButton);
		console.log("validation", this.state.data.DealerShip);
		console.log("toggleFinishButton", this.state.toggleFinishButton);

	}

	// 	CheckExpected(){
	//  
	// 		if(parseFloat(this.state.data.Expected_Price.replace(/,/g, '')) < 100000 ){
	// 			toast.warn("Amount Not Less then 100,000", {
	// 				position: toast.POSITION.TOP_RIGHT
	// 			})
	// 			return 
	// 		}
	// 		//console.log('onBlur',this.state.data.Expected_Price)

	// 	}

	render() {
		const { isExchange } = this.props;

		return (
			<React.Fragment>
				{this.state.isloader ? <Loader /> : ''}
				<EmiCalculator />
				<div className="tys-main" aria-label="Content" >
					<div className="tys-page tys-page-" style={{ backgroundImage: `url(${image})` }}>
						{isExchange ? <Breadcrum Name="EXCHANGE A CAR" /> : <Breadcrum Name="SELL A CAR" />}
						<div className="container">
							<div className="row">
								<div className="col-lg-8 ml-auto mr-auto">
									<div className="tys-internal-details">
										{isExchange ?
											<div>
												<h1 className="titleDark">Exchange your car with <strong>tsure</strong></h1>
												<p>
													{/* Calculate the lowest Equated Monthly Installment (EMI) that you require to pay for your favourite Car. Please enter the loan amount that you are applying for. The EMI (Equated Monthly Installment) DOES NOT include any other processing fee or charges that may be applicable as per the rules of financing institutions and banks. This calculation is for illustrative purposes. Check with the dealer for exact monthly payments. Please also see our LEGAL NOTICE */}
												</p>
											</div>
											: <div>
												<h1 className="titleDark">Sell your car with <strong>tsure</strong></h1>
												<p>
													{/* Calculate the lowest Equated Monthly Installment (EMI) that you require to pay for your favourite Car. Please enter the loan amount that you are applying for. The EMI (Equated Monthly Installment) DOES NOT include any other processing fee or charges that may be applicable as per the rules of financing institutions and banks. This calculation is for illustrative purposes. Check with the dealer for exact monthly payments. Please also see our LEGAL NOTICE */}
												</p>
											</div>}


									</div>
								</div>
							</div>
						</div>


						<div className="tys-emi">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 mb-3">
										{/* <!--EMI Start--> */}
										<div className="tys-wizard-content wizard-steps" >

											<form className="was-validated" id="form1" onBlurCapture={this.checkvalidation.bind(this)}>
												<div className="row">
													<div className="col-md-2">
														<div className="nav flex-md-column nav-pills wizard-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
															{/* <a className="nav-link active" id="v-pills-personal-tab" data-toggle="pill" href="javascript:void(0)" role="tab" aria-controls="v-pills-personal" aria-selected="true" onClick={this.OnOpenTab.bind(this)}> */}
															<a className={this.state.selectedTab === "v-pills-personal-tab" ? "nav-link active" : "nav-link"} id="v-pills-personal-tab" data-toggle="pill" role="tab" aria-controls="v-pills-personal" aria-selected={this.state.selectedTab == "v-pills-personal-tab" ? 'true' : 'false'} onClick={this.OnOpenTab.bind(this)} ref={element => {
																if (element) element.style.setProperty("color", "#9d9d9d", "important");
															}} >

																<i className="icon-detail-user" id="v-pills-personal-tab" onClick={this.OnOpenTab.bind(this)}></i>
																<span className="d-none d-md-block" id="v-pills-personal-tab" onClick={this.OnOpenTab.bind(this)} >Personal Details</span>
															</a>
															{/* <a className="nav-link" id="v-pills-vdetails-tab" data-toggle="pill"  href="javascript:void(0)" role="tab" aria-controls="v-pills-vdetails" aria-selected="false" onClick={this.OnOpenTab.bind(this)}> */}

															<span className={this.state._personal_d ? "tab-disable" : ""}>
																<a className={this.state.selectedTab == "v-pills-vdetails-tab" ? "nav-link active" : "nav-link"} id="v-pills-vdetails-tab" data-toggle="pill" role="tab" aria-controls="v-pills-vdetails" aria-selected={this.state.selectedTab == "v-pills-vdetails-tab" ? 'true' : 'false'} onClick={this.OnOpenTab.bind(this)} ref={element => {
																	if (element) element.style.setProperty("color", "#9d9d9d", "important");
																}}>
																	<i className="icon-car-o" id="v-pills-vdetails-tab" onClick={this.OnOpenTab.bind(this)}></i>
																	<span className="d-none d-md-block" id="v-pills-vdetails-tab" onClick={this.OnOpenTab.bind(this)}>Vehicle Details</span>
																</a>
															</span>
															<span className={this.state._vehicle_d ? "tab-disable" : ""}>
																{isExchange ? <a className={this.state.selectedTab == "v-pills-exchange-tab" ? "nav-link active" : "nav-link"} id="v-pills-exchange-tab" data-toggle="pill" role="tab" aria-controls="v-pills-exchange" aria-selected={this.state.selectedTab == "v-pills-exchange-tab" ? 'true' : 'false'} onClick={this.OnOpenTab.bind(this)} ref={element => {
																	if (element) element.style.setProperty("color", "#9d9d9d", "important");
																}}>
																	<i className="fa fa-exchange" aria-hidden="true" id="v-pills-exchange-tab" onClick={this.OnOpenTab.bind(this)}></i>

																	<span className="d-none d-md-block" id="v-pills-exchange-tab" onClick={this.OnOpenTab.bind(this)}>Exchange Vehicle</span>
																</a> : ''}
															</span>
															{/* <a className="nav-link" id="v-pills-inspect-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
					aria-controls="v-pills-inspect" aria-selected="false" onClick={this.OnOpenTab.bind(this)}> */}



															<span className={isExchange ? this.state._exchange_v ? "tab-disable" : "" : this.state._vehicle_d ? "tab-disable" : ""}>
																<a className={this.state.selectedTab == "v-pills-inspect-tab" ? "nav-link active" : "nav-link"} id="v-pills-inspect-tab" data-toggle="pill" role="tab"
																	aria-controls="v-pills-inspect" aria-selected={this.state.selectedTab == "v-pills-inspect-tab" ? 'true' : 'false'} onClick={this.OnOpenTab.bind(this)} ref={element => {
																		if (element) element.style.setProperty("color", "#9d9d9d", "important");
																	}}>

																	<i className="fas fa-car-battery" aria-hidden="true" id="v-pills-inspect-tab" onClick={this.OnOpenTab.bind(this)}></i>
																	{/* <i className="fa fa-car-battery" aria-hidden="true"></i> */}
																	<span className="d-none d-md-block" id="v-pills-inspect-tab" onClick={this.OnOpenTab.bind(this)}>Vehicle Inspection</span>
																</a>
															</span>

														</div>
													</div>
													<div className="col-md-10 pl-xl-0">
														<div className="tab-content wizard-tab-content" id="v-pills-tabContent">
															{/* <div className="tab-pane fade active show" id="v-pills-personal" role="tabpanel"
					aria-labelledby="v-pills-personal-tab"> */}

															<div className={this.state.selectedTab == "v-pills-personal-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-personal" role="tabpanel"
																aria-labelledby="v-pills-personal-tab">


																<h2 className="wizard-title text-left">Personal Details</h2>

																<div className="wizard-form dd-border tys-select-wrapper">
																	<div className="row">
																		<div className="col-md-12">
																			<label htmlFor="userName">Name</label>
																			<div className="input-group mb-2">
																				<input type="text" className="form-control" id="Name" onBlur={this.handleChange} placeholder="Enter Name" name="Name" required />
																				<div className="input-group-prepend">
																					<div className="input-group-text">
																						<i className="fa fa-user-o" aria-hidden="true"></i>
																					</div>
																				</div>

																				{/* <div className="valid-feedback">Valid.</div> */}
																				<div className="invalid-feedback">Please fill out this field.</div>
																			</div>
																		</div>
																		<div className="col-md-12">
																			<label htmlFor="userNum">Phone Number</label>
																			<div className="input-group mb-2">
																				<input type="text" className="form-control" placeholder="Enter Phone Number" id="phoneNumber" pattern="[0-9]{11}" onBlur={this.handleChange} name="phoneNumber" required />
																				<div className="input-group-prepend">
																					<div className="input-group-text">
																						<i className="fa fa-mobile" aria-hidden="true"></i>
																					</div>
																				</div>

																				{/* <div className="valid-feedback">Valid.</div> */}
																				<div className="invalid-feedback">Please fill out proper number.</div>
																			</div>
																		</div>
																		<div className="col-md-6">
																			{this.renderSelect("City", "City", this.state.data.cities, 'Id', 'Name')}
																		</div>
																		{/* <div className="col-md-6">
								<label htmlFor="">State:</label>
								<select className="custom-select">
									<option selected="">Sindh</option>
									<option value="1">Balochistan</option>
								</select>
							</div> */}
																		{/* <div className="col-md-6">
								<label htmlFor="">City:</label>
								<select className="custom-select">
									<option selected="">Karachi</option>
									<option value="1">Quetta</option>
								</select>
							</div> */}
																		{/* <div className="col-12">
								<label htmlFor="">Preferred Dealership:</label>
								<select className="custom-select">
									<option selected="">Select your Dealer</option>
									<option value="1">Dealer 1</option>
								</select>
								<hr className="border-0" />
							</div> */}
																		<div className="col-md-6">
																			<label htmlFor="userTime">Contact Time</label>
																			<div className="input-group mb-2">
																				<select className="custom-select" id="Contact_time" onBlur={this.handleChange} name="Contact_time">
																					<option value="" >Please Select</option>
																					<option value="1">9AM - 1PM</option>
																					<option value="2">2PM - 6PM</option>
																				</select>

																				<div className="input-group-prepend">
																					<div className="input-group-text">
																						<i className="fa fa-clock-o" aria-hidden="true"></i>
																					</div>
																				</div>
																			</div>
																		</div>

																		{/* <div className="col-md-6">
								<label htmlFor="userTime">Day</label>
								<div className="input-group mb-2">
									<input type="text" className="form-control" id="userTime" placeholder=""
										value="Mon- Tue" />
									<div className="input-group-prepend">
										<div className="input-group-text">
											<i className="fa fa-calendar" aria-hidden="true"></i>
										</div>
									</div>
								</div>
							</div> */}
																	</div>
																	<div className="row mt-5">
																		<div className="col-md-6">
																			{/* Lorem ipsum dolor sit amet consectetur. */}
																		</div>
																		<div className="col-md-6 align-self-center text-right">
																			<button type="button" className="btn btn-primary w-75" id="v-pills-vdetails-tab" onClick={this.OnOpenTab.bind(this)} disabled={this.state._personal_d ? true : false}>Next</button>
																		</div>
																	</div>
																</div>
															</div>
															{/* <div className="tab-pane fade" id="v-pills-vdetails" role="tabpanel" aria-labelledby="v-pills-vdetails-tab"> */}
															<div className={this.state.selectedTab == "v-pills-vdetails-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-vdetails" role="tabpanel" aria-labelledby="v-pills-vdetails-tab">

																<h2 className="wizard-title text-left">Vehicle Details</h2>
																<div className="wizard-form dd-border tys-select-wrapper">
																	<div className="row">
																		<div className="col-md-6">
																			{this.renderSelectRequired("Make", "Make", this.state.data.Allmaker, 'Id', 'Name', this.VehicleDetailchangeMaker.bind(this))}
																			{/* <label htmlFor="">Make:</label>
																			<div className="align-items-center d-flex">
																				<select className="custom-select" name="Make" id="Make" onChange={this.changeMaker.bind(this)}>
																					<option value="">Please Select</option>
																					<option value="1">Toyota</option>
																					<option value="4">Daihatsu</option>
																				</select>
																				<i className="dd-icon icon-car-mini"></i>
																			</div> */}

																		</div>
																		<div className="col-md-6">
																			{/* <label htmlFor="">Model:</label>
																				<select className="custom-select">
																					<option selected="">Karachi</option>
																					<option value="1">Quetta</option>
																				</select> */}
																			{this.renderSelectRequired("Model", "Model", this.state.VehicleDetailModel, 'Id', 'Name', this.VehicleDetailchangeVariant.bind(this))}
																		</div>
																		{<div className="col-md-6">{this.renderSelectRequired("SaleCarVariant", "Variant", this.state.vehicleDetailVariant, 'Id', 'Name')}</div>}
																		<div className="col-6">
																			<label htmlFor="Year">Year:</label>
																			<div className="align-items-center d-flex">
																				{/* <select className="custom-select" id="Year" name="Year" onChange={this.handleChange} required>
																					<option value="" selected >please Select</option>
																					<option value="2018" >2018</option>
																					<option value="2019">2019</option>
																				</select> */}
																				{this.renderYear()}
																				<i className="dd-icon icon-date"></i>
																			</div>
																		</div>

																		<div className="col-6">
																			<label htmlFor="">Color:</label>
																			<div className="align-items-center d-flex">
																				<input type="text" className="form-control" id="Color" onBlur={this.handleChange} placeholder="Color" name="Color" required />
																				<i className="dd-icon icon-car-color"></i>
																			</div>
																		</div>

																		{/* <div className="col-6">
																			<label htmlFor="">Transmission:</label>
																			<div className="align-items-center d-flex">
																				<select className="custom-select">
																					<option selected="">Auto</option>
																					<option value="1">Manual</option>
																				</select>
																				<i className="dd-icon fa fa-gears" aria-hidden="true"></i>
																			</div>  
																		</div>

																		<div className="col-6">
																			<label htmlFor="">Engine:</label>
																			<div className="align-items-center d-flex">
																				<select className="custom-select">
																					<option selected="">1300CC</option>
																					<option value="1">Dealer 1</option>
																				</select>
																				<i className="dd-icon fa fa-car-alt" aria-hidden="true"></i>
																			</div>
																		</div> */}




																		{isExchange ? '' : <div className="col-md-6">{this.renderSelect("SaleRegCity", "RegCity", this.state.data.cities, 'Id', 'Name')} </div>}

																		<div className="col-md-12">
																			<label htmlFor="Mileage">Mileage:</label>
																			<div className="align-items-center d-flex">
																				<NumberFormat className="form-control" id="Mileage" displayType={'input'} thousandSeparator={true} onBlur={this.handleChange} name="Mileage" required />
																				{/* <input type="text" className="form-control" id="Mileage" pattern="[0-9]" onChange={this.handleChange} name="Mileage" required/> */}

																				{/* <select className="custom-select" id="Mileage" onChange={this.handleChange} name="Mileage" required>
																					<option value="">Please Select</option>
																					<option value="50,000KM - 100,000KM" >50,000KM - 100,000KM</option>
																					<option value="50,000KM - 100,000KM">50,000KM - 100,000KM</option>
																				</select> */}
																				<i className="dd-icon fa fa-road" aria-hidden="true"></i>
																			</div>

																		</div>
																		<div className="col-md-12">
																			<label htmlFor="Expected_Price">Expected Price:</label>
																			{/* pattern="[0-9]{6,20}" */}
																			<div className="input-group mb-2">
																				<NumberFormat className="form-control" id="Expected_Price" thousandSeparator={true} displayType={'input'} onBlur={this.CheckExpected} onKeyUp={this.handleChange} name="Expected_Price" required />
																				<div className="input-group-prepend">
																					<div className="input-group-text">
																						<i className="dd-icon icon-cash" aria-hidden="true"></i>
																					</div>
																				</div>

																				{/* <div className="valid-feedback">Valid.</div> {this.state.error}*/}
																			</div>
																			<span style={{ color: "red" }}>{this.state.error}</span>

																		</div>
																		{/* <div className="col-md-12 mt-3">
																			
																			<div className="align-items-center d-flex">
																			
																				{/* {/ <input type="text" className="form-control" id="Expected_Price" pattern="[0-9]" onChange={this.handleChange} name="Expected_Price" required /> /} */}
																		{/* <select className="custom-select" id="Expected_Price" onChange={this.handleChange} name="Expected_Price" required>
																					<option value="">Please Select</option>
																					<option value="1,000,000 - 1,200,000">PKR 1,000,000 - 1,200,000</option>
																					<option value="1,000,000 - 1,200,000">PKR 1,000,000 - 1,200,000</option>
																				</select> */}
																		{/* <div className="input-group-prepend">
																				<div className="input-group-text">
																				
																				</div>
																			</div>
																			
																			<div className="invalid-feedback">Invalid Expected Price</div>
																			</div>
																			
																		</div> */}






																	</div>
																	<div className="row mt-5">
																		<div className="col-md-6 align-self-center">
																			{/* Lorem ipsum dolor sit amet consectetur. */}
																		</div>
																		<div className="col-md-6 align-self-center text-right">
																			<button type="button" className="btn btn-primary w-75" id={isExchange ? 'v-pills-exchange-tab' : 'v-pills-inspect-tab'} onClick={this.OnOpenTab.bind(this)} disabled={this.state._vehicle_d ? true : false}>Next</button>
																		</div>
																	</div>
																</div>

															</div>
															{/* <div className="tab-pane fade" id="v-pills-inspect" role="tabpanel" aria-labelledby="v-pills-inspect-tab"> */}
															<div className={this.state.selectedTab == "v-pills-exchange-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-exchange" role="tabpanel" aria-labelledby="v-pills-exchange">

																<div className="row">
																	<div className="col-md-6">
																		<h2 className="wizard-title text-left">Exchange Vehicle Details</h2>
																	</div>
																	<div className="col-md-6 pt-4">
																		<div className="d-flex justify-content-end pr-4">
																			<span className="pr-2">NEW</span>
																			<div className="custom-control custom-switch">
																				<input type="checkbox" onChange={this.toggleExchangeCar.bind(this)} className="custom-control-input" id="customSwitch1" />
																				<label className="custom-control-label" htmlFor="customSwitch1" style={{ color: "unset" }}>USED</label>
																			</div>
																		</div>
																	</div>
																</div>


																<div className="wizard-form dd-border tys-select-wrapper">
																	<div id="New_carForm" className={this.state.toggleNewUsed_Car ? "hide-carfrom" : "show-carfrom"}>
																		<div className="row">



																			<div className="col-md-6">

																				{this.renderSelectRequired("NewMake", "Make", this.state.data.Newmaker, 'Id', 'Name', this.changenewmaker.bind(this))}
																				{/* <label htmlFor="NewMake">Make</label>
																				<select className="custom-select" name="NewMake" id="NewMake" required onChange={this.changenewmaker.bind(this)}>
																					<option value="">Please Select</option>
																					<option value="1">Toyota</option>
																					<option value="4">Daihatsu</option>
																				</select> */}
																			</div>
																			<div className="col-md-6">
																				{this.renderSelectRequired("NewCarModel", "Model", this.state.NewModel, 'Id', 'Name', this.changenewmodel.bind(this))}
																			</div>
																			<div className="col-md-6">
																				{this.renderSelectRequired("NewCarVariant", "Variant", this.state.NewVariant, 'Id', 'Name')}
																			</div>
																			{/* <div className="col-md-6">
																				<label htmlFor="">Color:</label>
																				<div className="align-items-center d-flex">
																					<input type="text" className="form-control" id="NewCarColor" onChange={this.handleChange} placeholder="Color" name="NewCarColor" required />
																					<i className="dd-icon icon-car-color"></i>
																				</div>
																			</div> */}
																			<div className="col-md-6">
																			</div>
																			<div className="col-md-6">
																			</div>


																		</div>
																	</div>

																	<div id="Used_carForm" className={this.state.toggleNewUsed_Car ? "show-carfrom" : "hide-carfrom"}>
																		<div className="row">



																			<div className="col-md-6">
																				{this.renderSelectRequired("UsedMake", "Make", this.state.data.maker, 'Id', 'Name', this.changeusemaker.bind(this))}
																				{/* <label htmlFor="UsedMake">Make</label>
																				<select className="custom-select" name="UsedMake" id="UsedMake" required onChange={this.changeusemaker.bind(this)}>
																					<option value="">Please Select</option>
																					<option value="1">Toyota</option>
																					<option value="4">Daihatsu</option>
																			</select> */}
																			</div>
																			<div className="col-md-6">
																				{this.renderSelectRequired("UsedModel", "Model", this.state.UsedModel1, 'Id', 'Name', this.changeusemodel.bind(this))}
																			</div>
																			<div className="col-6">
																				{this.renderSelectRequired("UsedVariant", "Variant", this.state.UsedVariant, 'Id', 'Name', this.changeusevariant.bind(this))}
																			</div>
																			{/* <div className="col-6">
																				<label htmlFor="">Color:</label>
																				<div className="align-items-center d-flex">
																					<input type="text" className="form-control" id="UsedCarColor" onChange={this.handleChange} placeholder="Color" name="UsedCarColor" required />
																					<i className="dd-icon icon-car-color"></i>
																				</div>
																			</div> */}
																			<div className="col-6">
																				{this.renderSelect("UsedCity", "City", this.state.data.cities, 'Id', 'Name', this.changecitySearch.bind(this))}
																			</div>
																			{/* <div className="col-6">
																				{this.renderSelect("RegCity", "RegCity", this.state.data.cities, 'Id', 'Name')}
																			</div> */}


																		</div>
																		{this.state.data.UsedMake !== undefined && this.state.data.UsedMake != null ?

																			<CardCar
																				ref={this.childRef}
																				Data={this.state.Cars}
																				CheckLocation="exchange"
																				TabFunc={this.OnOpenTab}
																				Handler={this.handleClick}
																				NewCarEnquiryHandler={this.NewCarEnquiryHandler}
																				selectCar={this.selectCar}
																			/>

																			: <div></div>}
																	</div>


																	<div className="row mt-5">
																		<div className="col-md-6 align-self-center">
																			{/* Lorem ipsum dolor sit amet consectetur. */}
																		</div>
																		<div className="col-md-6 align-self-center text-right">
																			<button type="button" className="btn btn-primary w-75" id="v-pills-inspect-tab" onClick={this.OnOpenTab.bind(this)} disabled={this.state._exchange_v ? true : false}>Next</button>
																		</div>
																	</div>
																</div>
															</div>


															<div className={this.state.selectedTab == "v-pills-inspect-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-inspect" role="tabpanel" aria-labelledby="v-pills-inspect-tab">

																<h2 className="wizard-title text-left">Vehicle Inspection</h2>
																<div className="wizard-form dd-border tys-select-wrapper">
																	<div className="row">
																		<div className="col-md-6" >
																			{/* style={{display : "none"}} this is in above line with md 6 */}
																			<label htmlFor="FuelType">Fuel Type</label>
																			<select className="form-control" onBlur={this.Fuel_type.bind(this)} id="Fuel_type" name="Fuel_type" required>
																				<option value="" >Please select</option>
																				<option value='P' >Petrol</option>
																				<option value='D' >Diesel</option>
																				<option value='G' >Gas</option>
																				<option value='L' >LPG</option>
																			</select>

																		</div>


																		<div className="col-md-6">
																			<div className="d-flex justify-content-end">
																				<span className="pr-2">HOME</span>
																				<div className="custom-control custom-switch">
																					<input type="checkbox" onChange={this.toggleHomeCheckbox.bind(this)} className="custom-control-input" id="customSwitch2" />
																					<label className="custom-control-label" style={{ color: "unset" }} htmlFor="customSwitch2">Dealer</label>
																				</div>
																			</div>
																		</div>
																		{/* -------------------------- */}
																		{/* <div className="col-12 mt-3 mb-3">
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="userTime">Inspection at home</label>
										<div className="input-group mb-2">
											<input type="text" className="form-control sm-txt" id="userTime" placeholder="Enter your address here" value="" />
											<div className="input-group-prepend">
												<div className="input-group-text">
													<i className="fa fa-location-arrow" aria-hidden="true"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
																		{/* ------------------------------ */}
																		{/* <div className="col-12 mb-3">
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="userTime" className="mb-2">Upload Images <i className="icon-image"></i>
											(OPtional)</label>
										<button type="button" className="btn btn-primary btn-upload"><i className="fa fa-plus" aria-hidden="true"></i></button>
									</div>
								</div>
							</div> */}

																		{/* <div className="col-12 mb-3">
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="userTime" className="mb-2">Upload Video <i className="icon-film"></i>
											(OPtional)</label>
										<button type="button" className="btn btn-primary btn-upload"><i className="fa fa-plus" aria-hidden="true"></i></button>
									</div>
								</div>
							</div> */}



																	</div>

																	<div className="row">
																		{/* <div className="col-md-6">
																			<label htmlFor="InspectionType">Home/Dealer</label>
																			<select className="form-control" onChange={this.Location} id="InspectionType" name="InspectionType">
																				<option value="" selected disabled>Please select</option>
																				<option >At Home</option>
																				<option>At Dealership</option>
																			</select>
																		</div> */}
																		<div className="col-md-6">
																			<div className="form-group show-carfrom" id='Dealer_LocationDiv' style={{ display: this.state.UsedVehicleUnavailable == "false" ? "none" : "block" }} >
																				{this.renderSelectRequired("InspectionCity", "City:", this.state.data.tsureCities, 'Id', 'Name', this.onchangeCity.bind(this))}

																			</div>
																			<div id='Dealer_LocationDiv' style={{ display: this.state.UsedVehicleUnavailable == "false" ? "none" : "block" }} >
																				{this.renderSelectRequired("DealerShip", "Preferred Dealership:", this.state.DealerCities, 'Id', 'Name')}

																			</div>
																			<div className='form-group ' id="AddressDiv" style={{ display: this.state.toggleHome ? "none" : "block" }}>
																				<label htmlFor="Address">INSPECTION AT HOME</label>
																				{/* ---added div for arrow location styling-------- */}

																				<div className="input-group mb-2">

																					{/* -------------------- */}

																					<input type="text" className="form-control" id="Address" onBlur={this.handleChange} placeholder="Enter  address" name="Customer_Address" />

																					{/* ----------added location arrow icon-------------------- */}

																					<div className="input-group-prepend">
																						<div className="input-group-text">
																							<i className="fa fa-location-arrow" aria-hidden="true"></i>
																						</div>
																					</div>
																					{/* --------added closing div for arrow location styling--------- */}
																				</div>
																				{/* ------------------------------ */}
																				<div className="valid-feedback">Valid.</div>
																				<div className="invalid-feedback">Please fill out this field.</div>
																			</div>



																		</div>
																	</div>


																	<div className="row mt-5">
																		<div className="col-md-6 align-self-center">
																			{/* Lorem ipsum dolor sit amet consectetur. */}
																		</div>
																		<div className="col-md-6 align-self-center text-right">


																			<button type="button" onClick={this.onSubmit.bind(this)} className="btn btn-primary w-75" disabled={this.state.toggleFinishButton ? true : false} id="thanksPanelBtn">Finish</button>
																			{this.renderRedirect()}
																			{this.renderException()}
																		</div>
																	</div>
																</div>

															</div>




															<div className={this.state.selectedTab == "v-pills-thanks" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-thanks" role="tabpanel" aria-labelledby="v-pills-thanks-tab">
																<div className="tys-thankyou-panel">
																	<img src={img1} alt="" />
																	<h1>THANK YOU!</h1>
																	<p>
																		Your request have been submitted
																					</p>
																	<div className="tys-tp-btn">
																		<span className="btn btn-primary"><Link style={{ color: 'white' }} to="/">View more car</Link></span>
																	</div>
																</div>
															</div>



														</div>
													</div>
												</div>

											</form>

										</div>
										{/* <!--EMI End--> */}
									</div>
									<div className="col-lg-4">
										<Link to="/exchange-a-car" onClick={() => {window.scrollTo(0,0)}}><img src={Carimage} alt="" className="w-100 mb-4" /></Link>
										<div className="seller-guide mb-4">
											{/* ---------------------- */}

											{/* <!-- Swiper --> */}

											<div className="swiper-container swiper-container-initialized swiper-container-horizontal" id="tysSeller">

												<div className="swiper-wrapper">

													<div className={this.state.arrowBtn == "prevBtn" ? 'swiper-slide swiper-slide-active swiper-slide-show' : 'swiper-slide swiper-slide-next swiper-slide-hide'}>
														{/* --------in progress------------------------ */}
														{/* <div className={this.state.selectedTab == "v-pills-vdetails-tab" ? "tab-pane fade active show" : "tab-pane fade"} style={{width: 328}} id="one"> */}
														{/* ------------------------------------------------ */}
														{/* <div className={this.state.selectedTab == "v-pills-vdetails-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-vdetails" role="tabpanel" aria-labelledby="v-pills-vdetails-tab"> */}

														{/* ----------------------- */}
														<span className="title">
															Toyota Sure Advantage:
          </span>
														<p style={{ textAlign: 'justify' }}>
															Selling your Car? You have come to the right place, where we guarantee a hassle-free experience and the best price for your vehicle. Use our Exchange to book a Brand new Toyota or Buy our Certified Used Cars for the ultimate Peace of Mind. Toyota Sure “It’s A Promise”
          </p>
													</div>
													{/* ------------ */}

													<div className={this.state.arrowBtn == "nextBtn" ? 'swiper-slide swiper-slide-active swiper-slide-show' : 'swiper-slide swiper-slide-next swiper-slide-hide'} style={{ width: 328 }}>

														<span className="title">

															SELLER GUIDE DETAIL

               </span>

														<p>

															First of all establish a proper value based on your local automotive market that you are expecting from your

															used car. Used cars classified in local newspapers and the used cars dealers estimates and expert judgments

															can help in determining the true value for your used cars.

               </p>

													</div>

												</div>

												{/* ------------ */}
												<span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>

											{/* ------------- */}
											{/* <!-- <span className="title">
	                SELLER GUIDE
	
		 </span>
		  <p>
	       First of all establish a proper value based on your local automotive market that you are expecting from your
	
           used car. Used cars classNameified in local newspapers and the used cars dealers estimates and expert judgments
	
           can help in determining the true value for your used cars.
	      </p> --> */}
											{/* -------------- */}
											<div className="seller-arrows" style={{ display: 'none' }}>
												{/* <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-right" aria-hidden="true"></i> */}
												{/* ------- */}
												{/* <!-- Add Arrows --> */}

												<div className={this.state.arrowBtn == "prevBtn" ? "swiper-button-prev swiper-button-disabled" : "swiper-button-prev"} tabIndex="0" role="button" aria-label="Previous slide" aria-disabled={this.state.selectedTab == "prevBtn" ? 'true' : 'false'} id="prevBtn" onClick={this.OnArrowBtn.bind(this)}></div>

												<div className={this.state.arrowBtn == "nextBtn" ? "swiper-button-next swiper-button-disabled" : "swiper-button-next"} tabIndex="0" role="button" aria-label="Next slide" aria-disabled={this.state.selectedTab == "nextBtn" ? 'true' : 'false'} id="nextBtn" onClick={this.OnArrowBtn.bind(this)}></div>
												{/* -------- */}
											</div>
										</div>
										{/* <img src="http://placehold.it/265x250" alt="" className="w-100" /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<QuickView selectCarFunc={this.selectCar}  checkFlag="exchange" Check={this.state.check} name={this.state.changeIndex} handler1={this.UpdateFlag} handler2={this.handleClick} data={this.state.Cars[this.state.changeIndex]} />
				</div>
				<ToastContainer />
			</React.Fragment>
		)
	}
}

export default withRouter(SellACar);