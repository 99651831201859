import React from 'react';

function FaqPara() {
    return (
        
            <div className="container">
    <div className="row">
      <div className="col-md-8 ml-auto mr-auto">
          <div className="tys-internal-details">
              <h1>Frequently Asked Questions</h1>
             
                <p>
                  {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                  </p>
              
            </div>
      </div>
    </div>
  </div>
      
    )
}

export default FaqPara;