import React, { Component } from 'react'

export default class Breadcrum extends Component {
  constructor(props){
    super(props);
  }
    render() {
        return (
            <div className="tys-breadcrumb-bar" style = {{position:'relative'}}>

              <div className="container">

                <nav aria-label="breadcrumb" >
                
                  <ol className="breadcrumb" style={{backgroundColor: "#d21414",padding:"20px 0",margin:"0px"}}>
                    <li className="breadcrumb-item" >
                      <a href="#">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-uppercase"
                      aria-current="page"
                    >
                      {this.props.Name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
       
        )
    }
}
