import React, { Component } from "react";
import Categories from './categories';
import CheckOut from './checkOut';
import SideBar from './sideBar';
import Breadcrum from "../../components/breadcrum";
import EmiCalculator from '../emi/emiCalculator';





class Certified extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <EmiCalculator />
                <div className="tys-main" aria-label="Content" >
                <div className="tys-page tys-page-certified" >

                {/* ----------breadcrum start-------- */}
{/* 
                <div className="tys-breadcrumb-bar" style = {{position:'relative', bottom: 30}}>
            
              <div className="container">
                <nav aria-label="breadcrumb" >
                
                  <ol className="breadcrumb" style={{backgroundColor: "#d21414",paddingBottom:"20px",paddingTop:"20px",fontFamily:"Gotham,sans-sarif"}}>
                    <li className="breadcrumb-item" >
                      <a href="#">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-uppercase"
                      aria-current="page"
                    >
                      THE CERTIFIED
                    </li>
                  </ol>
                </nav>
              </div>
            </div> */}

          <Breadcrum Name="THE CERTIFIED"/>
                {/* -----------end---------------- */}

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto">
                            <div className="tys-internal-details">
                                <h1>The Certified</h1>

                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>

                            </div>
                        </div>
                    </div>
                </div>

            <div className="tys-certified">
                <div className="container">
                    <div className="row">
                        <div className="col-md">
                            <div className="row">
                                <div className="col-md mb-lg-0 mb-4">
                                    <div className="card tys-blog-card">
                                        <img className="card-img-top" src="http://placehold.it/295x275" alt="Card image cap" />
                                        <p className="card-text text-right pr-3 mb-0"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        <div className="card-body">
                                            <h5 className="card-title">IMC’s accelerates the Million Tree Drive </h5>
                                            <p className="card-text">Karachi, Nov XX, 2019: Indus Motor Company accelerates the million tree plantation drive.</p>
                                            <div className="d-flex align-content-center d-flex justify-content-between">
                                                <a href="#" className="btn btn-primary">Read More</a>
                                                <span className="align-self-center">
                                                    <small className="text-muted pr-3">300 View</small>
                                                    <i className="fas fa-share-alt"></i>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md mb-lg-0 mb-4">
                                    <div className="card tys-blog-card">
                                        <img className="card-img-top" src="http://placehold.it/295x275" alt="Card image cap" />
                                        <p className="card-text text-right pr-3 mb-0"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        <div className="card-body">
                                            <h5 className="card-title">IMC’s accelerates the Million Tree Drive </h5>
                                            <p className="card-text">Karachi, Nov XX, 2019: Indus Motor Company accelerates the million tree plantation drive.</p>
                                            <div className="d-flex align-content-center d-flex justify-content-between">
                                                <a href="#" className="btn btn-primary">Read More</a>
                                                <span className="align-self-center">
                                                    <small className="text-muted pr-3">300 View</small>
                                                    <i className="fas fa-share-alt"></i>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md mb-lg-0 mb-4">
                                    <div className="card tys-blog-card">
                                        <img className="card-img-top" src="http://placehold.it/295x275" alt="Card image cap" />
                                        <p className="card-text text-right pr-3 mb-0"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        <div className="card-body">
                                            <h5 className="card-title">IMC’s accelerates the Million Tree Drive </h5>
                                            <p className="card-text">Karachi, Nov XX, 2019: Indus Motor Company accelerates the million tree plantation drive.</p>
                                            <div className="d-flex align-content-center d-flex justify-content-between">
                                                <a href="#" className="btn btn-primary">Read More</a>
                                                <span className="align-self-center">
                                                    <small className="text-muted pr-3">300 View</small>
                                                    <i className="fas fa-share-alt"></i>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 mb-5">
                                <div className="row">

                                   <CheckOut />

                                  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md mb-lg-0 mb-4">
                                    <div className="card tys-blog-card">
                                        <img className="card-img-top" src="http://placehold.it/295x275" alt="Card image cap" />
                                        <p className="card-text text-right pr-3 mb-0"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        <div className="card-body">
                                            <h5 className="card-title">IMC’s accelerates the Million Tree Drive </h5>
                                            <p className="card-text">Karachi, Nov XX, 2019: Indus Motor Company accelerates the million tree plantation drive.</p>
                                            <div className="d-flex align-content-center d-flex justify-content-between">
                                                <a href="#" className="btn btn-primary">Read More</a>
                                                <span className="align-self-center">
                                                    <small className="text-muted pr-3">300 View</small>
                                                    <i className="fas fa-share-alt"></i>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md mb-lg-0 mb-4">
                                    <div className="card tys-blog-card">
                                        <img className="card-img-top" src="http://placehold.it/295x275" alt="Card image cap" />
                                        <p className="card-text text-right pr-3 mb-0"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        <div className="card-body">
                                            <h5 className="card-title">IMC’s accelerates the Million Tree Drive </h5>
                                            <p className="card-text">Karachi, Nov XX, 2019: Indus Motor Company accelerates the million tree plantation drive.</p>
                                            <div className="d-flex align-content-center d-flex justify-content-between">
                                                <a href="#" className="btn btn-primary">Read More</a>
                                                <span className="align-self-center">
                                                    <small className="text-muted pr-3">300 View</small>
                                                    <i className="fas fa-share-alt"></i>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <Categories />

                               
                            </div>
                        </div>
                      
                        <SideBar />

                    </div>

                    <div className="d-block text-center text-upper mb-5 mt-5">
                        <span>Read More</span>
                    </div>

                    <div className="tys-tags d-flex align-content-center">
                        <div className="align-self-center">
                            <strong>TAGS</strong>
                        </div>
                        <div className="ml-3 align-self-center">
                            <button type="button" className="btn btn-outline-dark">Topics</button>
                            <button type="button" className="btn btn-outline-dark">Topics</button>
                            <button type="button" className="btn btn-outline-dark">Topics</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </React.Fragment>
			
			);
    }
}
 
export default Certified;