import React, { Component } from "react";




class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 


<div className="col-md mb-lg-0 mb-4">

<div className="tys-blog-cat">
    <h2 className="title">Catergories</h2>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-model" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-model" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-1" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-1" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-2" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-2" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-3" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-3" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
</div>

<div className="tys-blog-cat mt-3">
    <h2 className="title">History</h2>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-model-his" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-model-his" className="list-group in collapse">
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-model-his-1" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-model-his-1" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
    <div className="tys-cat-list">
        <div className="tys-collapse collapsed" data-toggle="collapse" data-target="#group-model-his-2" aria-expanded="false">
            <h2>Category <strong>(10)</strong></h2>
        </div>
        <div id="group-model-his-2" className="list-group in collapse" >
            <span className="list-group-item">
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
                <div>
                    <span>ABC</span>
                </div>
            </span>
        </div>
    </div>
</div>
</div>

		
);
}
}

export default Categories;