import React, { Component } from 'react';
import Map from '../../assets/images/map.png';
import BGimage from '../../assets/images/dealer_bg.jpg';
import { Link } from "react-router-dom";

import { GetService, PostService } from "../../services/GeneralServices";
class DealerShip extends Component {
    constructor(props) {
        super(props);
        this.state = { Data: "" }
    }
    async componentDidMount() {
        const { data } = await GetService('api/Vehicle/GetNationWideDealersValue');
         ;
        //console.log("Data", data.Data)
        this.setState({ Data: data })
    }
    render() {

        //console.log(this.state.Data)


        if (this.state.Data == "") {
            return <div></div>
        }
        return (


            <div className="tys-section tys-dealership" style={{ backgroundImage: `url(${BGimage})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <Link to='/dealer' className="dealer-link">
                                <img src={Map} alt="" className="w-75" />
                            </Link>
                        </div>
                        <div className="col">
                            <h1 className="tys-title-sub">
                                NATIONWIDE DEALERSHIP NETWORK
                        </h1>
                            <p>Our dealership network is spread across Pakistan to provide maximum coverage and provide complete peace of mind to our esteemed customers
                        </p>
                        
                            <div className="row mt-4">
                                <div className="col">
                                    <div className="d-flex"><span className="tys-count">{this.state.Data.Data.np.Value1}+</span> <span className="align-self-center tys-sm-txt">
                                        Toyota SURE Dealerships</span></div>
                                    <div className="d-flex"><span className="tys-count">{this.state.Data.Data.np.Value2}+</span> <span className="align-self-center tys-sm-txt">Cities across all 4 provinces
</span></div>
                                </div>
                                <div className="col">
                                    <div className="d-flex"><span className="tys-count">{this.state.Data.Data.np.Value3}+</span> <span className="align-self-center tys-sm-txt">Cars sold every year
</span></div>
                                    <div className="d-flex"><span className="tys-count">{this.state.Data.Data.np.Value4}%</span> <span className="align-self-center tys-sm-txt">Customer Satisfaction guarantee
</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default DealerShip;