import React, { Component } from 'react'




export default class CustomError extends Component {
    render() {
        return (
            <div className="tys-main" aria-label="Content">
    <div className="err-container container">
      <h1>404</h1>
    
      <p><strong>Page not found :(</strong></p>
      <p>The requested page could not be found.</p>
    </div>
    
        </div>
        )
    }
}
