import React, { Component } from "react";





class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        //console.log(this.props.ChangeHover);
        return (

<div className="heading-Wrapper">
<table  style = {{ width : "100%"  , border: "0" , cellspacing : "0" , cellpadding : "0" }}>
    <tbody>
        <tr>
            <td className="remove"></td>
        </tr>
        <tr>
            <td className="car_image">
                <span className="w-75 d-block">Compare and
                    Select the best
                    vehicle for your
                    ride</span>
            </td>
        </tr>
        <tr>
            <td id="price" className="" onMouseEnter={this.props.ChangeHover}>Price</td>
        </tr>
        <tr>
            <td id="year" className="year" onMouseEnter={this.props.ChangeHover}>Year</td>
        </tr>
        <tr>
            <td id="make" className="" onMouseEnter={this.props.ChangeHover}>Make</td>
        </tr>
        <tr>
            <td id="model" className="model" onMouseEnter={this.props.ChangeHover}>Model</td>
        </tr>
        {/* <tr>
            <td className="body_style">Body Style</td>
        </tr> */}
        <tr>
            <td id="mileage" className="mileage" onMouseEnter={this.props.ChangeHover}>Mileage</td>
        </tr>
        {/* <tr>
            <td className="fuel_economy">Fuel Economy</td>
        </tr> */}
        {/* <tr>
            <td className="transmission">Transmission</td>
        </tr> */}
        <tr>
            <td id="condition" className="condition">Condition</td>
        </tr>
        {/* <tr>
            <td className="drivetrain">Drivetrain</td>
        </tr> */}
        <tr>
            <td id="engine" className="engine">Engine</td>
        </tr>
        <tr>
            <td className="exterior_color">Exterior Color</td>
        </tr>
        {/* <tr>
            <td className="interior_color" style={{height: '43px'}}>Interior Color</td>
        </tr> */}
        <tr>
            <td className="stock_number">Stock Number</td>
        </tr>
        <tr>
            <td className="stock_number">Warranty Book</td>
        </tr>
        <tr>
            <td className="stock_number">Owner Manual</td>
        </tr>
        <tr>
            <td className="stock_number">Sale Invoice / Certificate</td>
        </tr>
        <tr>
            <td className="stock_number">Registration Book</td>
        </tr>
        <tr>
            <td className="stock_number">Running Page</td>
        </tr>
        <tr>
            <td className="stock_number">CPLC clear</td>
        </tr> <tr>
            <td className="stock_number">Jack/Rod</td>
        </tr>
        <tr>
            <td className="stock_number">Toolkit</td>
        </tr>
        <tr>
            <td className="stock_number">Spare Wheel</td>
        </tr>
        <tr>
            <td className="stock_number">Number Plate</td>
        </tr>
        <tr>
            <td className="stock_number">Chasis ID plate (Vin plate)</td>
        </tr>
        <tr>
            <td className="stock_number">No of Keys</td>
        </tr>
        {/* <tr>
            <td className="vin_number">Vin Number</td>
        </tr> */}
        <tr>
            <td className="features_options">Features &amp; Options</td>
        </tr>
    </tbody>
</table>
</div>




);
}
}
export default SideBar;