import React, { Component } from 'react';
import Breadcrum from "../../components/breadcrum";     
import CarSlider from '../General/carSlider'
import RenderMap from '../Map/renderMap';
import { withRouter, Link, Redirect } from 'react-router-dom';
import placeholdertwo from '../../assets/images/placeholdertwo.png';
import placeholderthree from '../../assets/images/placeholderthree.png';
import Layer1 from '../../assets/images/bank/Layer-1.png';
import Layer2 from '../../assets/images/bank/Layer-2.png';
import Layer3 from '../../assets/images/bank/Layer-3.png';
import Layer4 from '../../assets/images/bank/Layer-4.png';
import Layer5 from '../../assets/images/bank/Layer-5.png';
import placeholder1 from '../../assets/images/placeholder1.png';
import placeone from '../../assets/images/placeone.png';
import placeholder4 from '../../assets/images/placeholder4.png';
import Swiper from 'react-id-swiper';
import NumberFormat from 'react-number-format';
import Loader from '../General/loader';
import { GetService, PostService } from "../../services/GeneralServices";
import { ToastContainer, toast } from 'react-toastify';
import { array } from 'prop-types';
import Popup from "reactjs-popup";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Service1 from '../../assets/images/Rewards/Service1.png';
import Service2 from '../../assets/images/Rewards/Service2.png';
import Service3 from '../../assets/images/Rewards/Service3.png';
import Service4 from '../../assets/images/Rewards/Service4.png';
import Service5 from '../../assets/images/Rewards/Service5.png';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from "react-share";

class Cardetails extends Component{
    constructor(props) {
        super(props);
        this.state = {   
            place1:"",
            place2:"",      
            place3:"",      
            place4:"",      
            place5:"",      
            IsCardsAvailible: true,
            redirect:false,  
             DealersCar:[] ,
             CarID : '',
             DealerID : '' ,
             Cardetails : [],
             isloader: true,
             check: true,
             num : 0,
             car:[],
             Image: "",
             OpenOffer: false,
             TestDrive : true ,
             CurrentImage : "",
             makeanoffer : '',
             data : {
                 Name : '',
                 phoneNumber : '',
                 offer : '',
             }
        }
        this.myRef = React.createRef();
        this.myRef2 = React.createRef();
        this.OpenOffer = this.OpenOffer.bind(this);
        this.onSliderClick = this.onSliderClick.bind(this);
        this.focus = this.focus.bind(this);
        this.textInput = React.createRef();

    }
 
    focus() {
        this.textInput.current.focus();
      }
    
   

   async componentWillMount(){
         ;
        try {
        

            window.scroll(0,0);
                // remove overlay from component and add scroll bar
            if(document.getElementById('root')){
                document.getElementById('root').classList.remove('tys-draw-body');
                document.children[0].style.overflow = "";
            }
            // remove overlay from component and add scroll bar
             ;
            var Params = this.props.location.pathname.split('/')
        const { data } = await GetService('api/vehicle/GetDealersCars?id='+ Params[3]+'&isSpecial='+true+'&VehicleId='+Params[2])
        //   const {Dealer } = await GetService("api/vehicle/GetDealerShipCars?id='+ Params[3] );
            
        let Values =   data.Data.ListOfCarsWithImages.filter(val=>{
            return val.car.VehicleID == Params[2]
            })
                
            let ob =  data.Data.ListOfCarsWithImages.filter(x => {
                 
                     if(x.car.VehicleID !== parseInt(Params[2])){
                         return x;
                     }
             }); 
             console.log(ob);  
             let Arry = [];
             if(ob.length == 0){
                 this.state.IsCardsAvailible = false;
                 ob = data.Data.ListOfCarsWithImages;
             }else{
                this.state.IsCardsAvailible = true;
             }
            this.setState({DealersCar: ob ,car:Values[0].car,
                Image: Values[0].images.FrontImageName  == "" ? placeone :Values[0].images.FrontImageName,
                place1:Values[0].images.FrontImageName  == "" ? placeone :Values[0].images.FrontImageName,
                place2:Values[0].images.BackImageName == "" ? placeholdertwo :Values[0].images.BackImageName ,
                place3:Values[0].images.RightImageName == "" ? placeholderthree :Values[0].images.RightImageName ,
                place4:Values[0].images.LeftImageName == "" ? placeholder4 :Values[0].images.LeftImageName ,
                place5:Values[0].images.HoodImageName  ,
                isloader: false})
   
                 
                //console.log('componentWillMount  isloader--',this.state.isloader);

        } catch (error) {
            this.setState({ isloader : false })
            alert(error);
        }


   }
CheckOpen()
{
    if (this.state.num == 0){

        this.setState({check:this.state.check== true ? false:true});
     }
     
     
}

    
handleChange = ({ currentTarget: input }) => {
    // 
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    console.log(input)
    const data = { ...this.state.data };
    data[input.name] = input.value;
  
    this.setState({ data });
  };

  onSubmit = async (e) => {
    // 
   

    var model = {
      Sno: null,
      Source: "Website",
      Remarks: "Tsure Enquiry" ,
      CustomerPurposeOfVisit: 'P',
      CustomerInterestLevel: 'o',
      CustomerName: this.state.data.Name,
      CustomerMobile: this.state.data.phoneNumber,
      CustomerMobile2: null,
      CurrentVehExpectedPrice: null,
      NextVehExpectedPrice: this.state.data.offer,
      NextVehSellingPrice: null,
      IsNextUsedVehicleUnavailable: null,
      City: null,
      Mileage:null,
      Fuel_type: null,
      customerAddress:null,
      Dealer_Locator : null,
     DealershipStationID: this.state.car.DealershipStationID ,

      CurrentVehicle: {
        MakeID: null,
        ModelID: null,
        VaraintID: null,
        Year: null,
        RegNum: null,
        EngineNum: null,
        ChasisNum: null,
        Mileage: null,
        Color: null,
        IsInspected: false,
        HasArived: false,
        FinalPurchasePrice: 0,
        RetailPrice: 0,
        InspectionOneSummary: {
          InspectionDate: null,
          ExteriorScore: null,
          InteriorScore: null,
          EngineScore: null,
          FrameScore: null,
          TotalScore: null,
          QualityGrade: null,
          Remarks: null,
          VehicleImages: {
            HoodImageName: null,
            FrontImageName: null,
            BackImageName: null,
            InteriorImageName: null,
            LeftImageName: null,
            RightImageName: null,
            OtherImageName: null
          }
        },
        InspectionTwoSummary: {
          InspectionDate: null,
          ExteriorScore: null,
          InteriorScore: null,
          EngineScore: null,
          FrameScore: null,
          TotalScore: null,
          QualityGrade: null,
          Remarks: null,
          VehicleImages: {
            HoodImageName: null,
            FrontImageName: null,
            BackImageName: null,
            InteriorImageName: null,
            LeftImageName: null,
            RightImageName: null,
            OtherImageName: null
          }
        },

        ArivalOfVehicle: {
          DateOfArival: null,
          Remarks: null
        },
        Refurbishments: {
          ReFubDataSheet: null,
          ReFubDataSheetName: null
        }
      },

      NextUsedVehicle: {
       VehicleID: this.state.car.VehicleID,
        UsedCarModel: null,
        UsedCarVaraint: null,
        UsedCarRegCity: null,
        UsedCarColor: null,
        UsedCarMake: null,
        UsedCarCity: null,
      },

      UnavailableUsedVehicle: {
        Make: null,
        Model: null,
        Variant: null,
        Year: null,
        Color: null
      },
      NextNewVehicle: {
        MakeID: null,
        ModelID: null,
        VariantID: null,
        Year: null,
        Color: null,
        PBONum: null
      },

      EnquiryStatus: {
        Status: "W",
        DealFollowupData: {
          date: null,
          Reason: null
        },
        DealLostData: {
          Reason: null
        },
        DealDoneData: {
          NumberOfKeys: null,
          HasWarrentyBook: null,
          HasOwnerManual: null,
          HasSaleInvoice: null,
          HasRegistrationBook: null,
          HasRunningPage: null,
          IsCPLCClear: null,
          HasJackedNRod: null,
          HasToolkit: null,
          HasSpareWheel: null,
          HasNumPlate: null,
          HasChasisIdPlate: null
        }
      }
    };
    console.log(model)

    if (this.state.data.Name !== "" && this.state.data.Name !== undefined && 
    this.state.data.phoneNumber !== "" && this.state.data.phoneNumber !== undefined && this.state.data.phoneNumber.length == 11
    ) {
       

        if(!this.props.isTestdrive && this.state.TestDrive  == true){
            var _Offer = parseFloat(this.state.data.offer.replace(/,/g, ''))
            if( parseInt(_Offer)< 100000 || this.state.data.offer == "" || this.state.data.offer == undefined   || this.state.data.offer.charAt(0) == 0 ){
                toast.warn(`invalid offer`, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                return;
            }
        }
        

       
        await PostService('api/enquiry/new', model)
     .then( response => {
      console.log('response get---', response);
      debugger;
      if (response.status == 200) {
        
        this.setState({OpenOffer : false , makeanoffer : this.state.data.offer});
        // document.getElementById('Name').value = '';
        // document.getElementById('phoneNumber').value = '';
        // document.getElementById('offer').value = '';
        toast.success("Enquiry submit successfully", {
          position: toast.POSITION.TOP_RIGHT
        })

        this.setState({
            data : { Name : '', phoneNumber : '', offer : ''}
        })
        //will redirect to the thankyou Tab
        //this.props.onClickfunc('tysThankUPanel');
        //will redirect to the thankyou Tab

      }
       else {
        toast.warn(`${response.statusText} -- ${response.status}`, {
          position: toast.POSITION.TOP_RIGHT
        })

      }

     })
     .catch( err => console.log(err));

    }else{
        if( this.state.data.phoneNumber.length !== 11){
            toast.warn(`Incorrect phone number`, {
                position: toast.POSITION.TOP_RIGHT
              })
        }
        else {
            toast.warn(`Missing feilds...`, {
                position: toast.POSITION.TOP_RIGHT
              })
        }
     

    }

     
    
   


  }
OpenOffer(e){
     ;
    if(e.target.id=="TestDrive")
    {
    ReactPixel.track('User Clicked', "Schedule a Test Drive"); 
    }
    else
    {        
    ReactPixel.track('User Clicked', "Make an offer"); 
    }
    // ReactGA.event({
    //     category: 'Clicked',
    //     action: 'User Clicked On make an offer'
    //   });
    this.setState({ data : {
        Name : '',
        phoneNumber : '',
        offer : '',
    }
}, ()=>{
    this.focus();
})
    
     
     const id = e.target.id;

    if(this.myRef.current.style.display == "none"){
        this.myRef.current.style.display = "block";
    }
    else{
        this.myRef.current.style.display = "none";
    }
    if(this.myRef2.current.value !== id){
        this.myRef.current.style.display = "block";
     }
     if( id == 'MakeOffer'){
        this.setState({ TestDrive : true })
        this.myRef2.current.value = "MakeOffer";
     }
     if( id == 'TestDrive'){
        this.setState({ TestDrive : false })
        this.myRef2.current.value = "TestDrive";
    }
    
    //this.setState({ OpenOffer : true})
    //  if(e.target.id == 'MakeOffer'){
    //     this.setState({   TestDrive : true})
    //  }
    //  if( e.target.id == 'TestDrive'){
    //       this.setState({   TestDrive : false})
    //  }
    //   if( this.state.OpenOffer == false){
    //     this.setState({ OpenOffer : true})
    //   }
    //   else
    //   {
    //     this.setState({ OpenOffer : false})
    //   }
}



onclick() {

    this.setState({alpha : true})
}

onSliderClick (item) {
     ;
   console.log('hello')
   console.log(item)
   
   this.setState({ CarID : item.car.VehicleID , DealerID : item.car.DealershipStationID , car:item.car,Image: item.images.FrontImageName
    ,place1: item.images.FrontImageName == "" ? placeone :item.images.FrontImageName 
    ,place2: item.images.BackImageName == "" ? placeholdertwo :item.images.BackImageName 
    ,place3: item.images.RightImageName == "" ? placeholderthree :item.images.RightImageName 
    ,place4: item.images.LeftImageName == "" ? placeholder4 :item.images.LeftImageName 
} )
   
   this.setState({ redirect : true})

}


 RedirectToCarDetail(event) {
      ;
    if (this.state.redirect) {
        PostService('api/vehicle/AddPoint?id=' +  this.state.CarID, "");
   this.setState({ redirect : false})
   window.scroll(0,0);


   return this.props.history.push({ pathname: '/cardetails/' + this.state.CarID + '/' + this.state.DealerID , state: { data: this.state.Cardetails } })
   
}
}

    render() {
        
       // console.log('DealersCar--> ' , this.state.DealersCar)
        //console.log('car--> ' , this.state.car)
          var params2 = {
           // pagination: '.swiper-pagination',
            slidesPerView: 4,
          //  paginationClickable: true,
            spaceBetween: 15,
            loop: false,
            slideToClickedSlide: true,
            containerClass: "swiper-container wizard-slider addprt",
            // nextButton: '.swiper-button-next',
            // prevButton: '.swiper-button-prev',
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev'
            // },
          };
       

         
// console.log(this.state.data)
// console.log(this.state.DealersCar)
// console.log(this.state.images.FrontImageName)
// if(this.state.CurrentImage == "" )
// {
// this.state.Image = this.props.data != undefined && this.state.CurrentImage == "" ? this.props.data.images.FrontImageName :"1" ;
// }
if(this.state.car.length == 0 || this.state.DealersCar == undefined|| this.state.DealersCar == null || this.state.DealersCar.length==0)
{
    return (<React.Fragment>{ this.state.isloader ? <Loader /> : ''}</React.Fragment>)
}
if(this.state.car.AdditionalBenefits !=null && this.state.car.AdditionalBenefits !=""){
    var Result = JSON.stringify(JSON.parse(this.state.car.AdditionalBenefits))
    var result2 = JSON.parse(Result)
    debugger       
    if(result2 != null){
        console.log("Service-->"+result2[0].name)
    }
}

debugger;

debugger
    return(        
            <React.Fragment>
                  {this.RedirectToCarDetail()}
                <Breadcrum Name='Detail'/>
            <div className="tys-page tys-page-" >
          <div className="tys-cdetails">
            <div className="cdetails-car">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-6"> */}
                        <div className="col-lg-6">
                            <div className="c-images-alt">
                            {/* <img src={this.state.images.FrontImageName} alt="" className="w-100" id="cImgMain"/> */}
                                <div className="main_img_vehicle" style={{backgroundImage: `url(${this.state.Image})` }}>
                                    {/* <img src={this.state.Image} alt="" className="w-100" id="cImgMain"/> */}
                                    </div>
                            
                            <Swiper {...params2} >
                             {/* <div className="cImgListContainer"> */}
                             {/* <ul className="d-flex cImgList" id="imgListContainer"> */}
                                   
                                        {/* <li> */}
                                        {/* <div  > */}
                                    {/* <img src={this.state.images.FrontImageName} alt="" className="w-100"/> src={this.state.place1}*/}
                                        <div style={{backgroundImage: `url(${this.state.place1})` }} className={this.state.CurrentImage == "1"? 'w-100 active abc' : 'w-100 abc'} onClick={()=> this.setState({Image:this.state.place1, CurrentImage: '1'})} alt="" >
                                        {/* </li> */}
                                        </div>
                                        
                                        {/* <div> */}
                                    {/* <li> */}
                                    {/* <img src={this.state.images.HoodImageName} alt="" className="w-100"/> src={ placeholdertwo}*/}
                                        <div  style={{backgroundImage: `url(${this.state.place2})` }} className={this.state.CurrentImage == "2"? 'w-100 active' : 'w-100'} onClick={()=> this.setState({Image:this.state.place2, CurrentImage: '2'})} alt="" >
                                        {/* </li> */}
                                        </div>
                                       
                                        {/* <div> */}
                                    {/* <li> */}
                                    {/* <img src={this.state.images.BackImageName} alt="" className="w-100"/> */}
                                        <div style={{backgroundImage: `url(${this.state.place3})` }} className={this.state.CurrentImage == "3"? 'w-100 active' : 'w-100'} onClick={()=> this.setState({Image:this.state.place3, CurrentImage: '3'})} alt="" >
                                        {/* </li> */}
                                        </div>
                                       
                                        {/* <div> */}
                                    {/* <li> */}
                                    {/* <img src={this.state.images.InteriorImageName} alt="" className="w-100"/> */}
                                        <div style={{backgroundImage: `url(${this.state.place4})` }}className={this.state.CurrentImage == "4"? 'w-100 active' : 'w-100'}  onClick={()=> this.setState({Image:this.state.place4 , CurrentImage: '4'})} alt="" >
                                        {/* </li> */}
                                        </div>

                                    {/* -------------------------------- */}
                                      

                                    {/* ---------------------------------- */}
                                {/* </ul> */}
                                {/* --added div---- */}
                                {/* </div> */}
                                {/* -------- */}
                                
                                </Swiper>

                            
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h2 className="c-title featured">
                            {this.state.car.MakerName} {this.state.car.ModelName} {this.state.car.VariantName} {this.state.car.ModelYear}
                            <Popup trigger={<span className="tys-shareLink" style={{marginLeft:"10px", color: "#d21414"}}><span ><i className="fa fa-share-alt" aria-hidden="true"></i></span></span>} position="right center">
                                    <div>
                                        <WhatsappShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.state.car.VehicleID + '/' + this.state.car.DealershipStationID} >
                                            <WhatsappIcon size={32} round={true} />
                                        </WhatsappShareButton>

                                        <FacebookShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.state.car.VehicleID + '/' + this.state.car.DealershipStationID} >
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                    </div>
                                </Popup>
                            </h2>
                            <div className="tys-card-wrapper">
                                <div className="w-100" style={{display:this.state.car.DiscountedPrice == null || this.state.DiscountedPrice == "" || this.state.car.DiscountedPrice == undefined || this.state.car.DiscountedPrice == 0 ? "none" : "-webkit-inline-box" }}>
                                    <span className="tys--tc-price old mb-2" >
                                        <strong>
                                           {/* {this.state.car.RetailPrice}  */}
                                           Rs <NumberFormat value= {this.state.car.RetailPrice}  displayType={'text'} thousandSeparator={true}  />
                                        
                                         </strong>
                                    </span>
                                </div>
                                <div className="w-100 d-flex flex-md-row flex-column">
                                    <span className="tys--tc-price">
                                        <strong> RS <NumberFormat  displayType={'text'} value={this.state.car.DiscountedPrice == null || this.state.car.DiscountedPrice == "" || this.state.car.DiscountedPrice == undefined || this.state.car.DiscountedPrice == 0 ? this.state.car.RetailPrice : this.state.car.DiscountedPrice }  thousandSeparator={true}  />
                                        
                                        </strong>
                                    </span>
                                    <span className="c-id">Stock ID: {this.state.car.EnquirySno}</span>
                                </div>
                                
                                <span className="card-location"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>   {this.state.car.DealershipStationCityName} ,
                                {this.state.car.Name}</span>

                           {this.state.makeanoffer > 0 ? <span className="tys-offered-price mt-0 mb-3 d-flex align-items-center ">
                                <span>Offered:</span> 
                                <span className="tys-offer-price" >PKR <NumberFormat value={this.state.makeanoffer} displayType={'text'} thousandSeparator={true}  /></span>
                                <span className="tys-offer-icon fa fa-check-circle" aria-hidden="true"></span>
                            </span> : ''}

                            </div>
                            {/* <p>
                                Toyota Sure is the new standard in the used market in Pakistan, which offers buying, selling and
                                exchange services of used cars. Under the support and guidance of business
                            </p> */}
                            <p>This vehicle is <b>Certified</b> by Toyota through a comprehensive 210 check point where every aspect of vehicle’s Interior, Exterior, Engine and vehicle Frame is inspected on TVI Standards by our trained technicians.</p>
        
                            <div className="tys-cdc-info">
                                <div className="row mb-3">
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Mileage <strong>{this.state.car.Mileage}</strong></span>
                                    </div>
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Model <strong>{this.state.car.ModelYear}</strong></span>
                                    </div>
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Fuel Type <strong>{
                                        this.state.car.FuelType == "P" ? "Petrol" :
                                        this.state.car.FuelType == "C" ? "CND" :
                                        this.state.car.FuelType == "D" ? "Diesel" :
                                        this.state.car.FuelType == "H" ? "Hybrid" :
                                        this.state.car.FuelType == "E" ? "EV" : ""
                                        }</strong></span>
                                    </div>
                                   
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Color <strong>{this.state.car.Color}</strong></span>
                                    </div>
        
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Transmission <strong>{this.state.car.Transmission}</strong></span>
                                    </div>
                                    <div className="col-md-6 tys-cdc-info-col">
                                        <span>Engine <strong>{this.state.car.EngineNo}</strong></span>
                                    </div>
                                    <div style={{display:this.state.car.AdditionalBenefits == null ? "none" : "-webkit-inline-box" }}className="col-md-6 tys-cdc-info-col" >
                                        <span>Additional Benefits with vehicle</span>
                                    </div>
                                    <div className="col-md-6 tys-cdc-info-col">
                                        
                                    </div>
                                    <div className="card-body">
                                    
                                    <div className="col-4" style={{float: 'right', padding: '1.5rem'}}>                                        
                                        {this.state.car.AdditionalBenefits == null ?"": result2.map(x=>{                                        
                                        if(x.id === 'Service1'){
                                           return<img src={Service1} height="30px"style={{marginTop: '5px'}}/>                                                                                       
                                        }else if(x.id === 'Service2'){
                                         return <img src={Service2} height="30px" style={{marginTop: '7px'}}/>
                                        }else if(x.id === 'Service3'){
                                         return <img src={Service3} height="30px" style={{marginTop: '7px'}}/>
                                        }else if(x.id === 'Service4'){
                                         return <img src={Service4} height="30px" style={{marginTop: '7px'}}/>
                                        }else if(x.id === 'Service5'){
                                         return <img src={Service5} height="30px" style={{marginTop: '7px'}}/>
                                        }
                                        
                                     })}                               
                                    </div>                                          
                                    <div className="col-8 tys-cdc-info-col" style={{marginTop: '-8px', padding: '1.5rem'}}>
                                    {this.state.car.AdditionalBenefits == null ?"": result2.map(x=>{
                                            if(x.name != ''){
                                                return <span style={{marginLeft: '-20px', marginTop: '5px'}}>
                                                    Benefits Included:
                                                <strong>{x.name}</strong>
                                                </span>    
                                            }                                                                                  
                                        })}                                        
                                    </div>
                                    <div className="row">
                                                <span>
                                                    *Terms and Conditions apply
                                                </span>
                                    </div>
                                    </div>                           
                                    
                                </div>
        
                                <div className="row">
                                    <div className="col-sm-6" style={{marginTop:"10px"}}>
                                    <button style={{width:"100%"}} onClick={this.OpenOffer} className="btn btn-primary" id='MakeOffer'>Make An Offer</button>
                            {/* <Link className="btn btn-primary" to={{ pathname: '/form', state: { data: this.state.car , From : "M"} }} >Make Offer</Link> */}

                                    </div>
                                    <div className="col-sm-6" style={{marginTop:"10px"}}>
                                        <button style={{width:"100%"}} className="btn btn-primary" onClick={this.OpenOffer} id='TestDrive'>Schedule a Test Drive</button>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
                <div className='tys-offer-bar tys-form show' id="offerBar" ref={this.myRef} style={{ display : 'none' }}>
                    <input type="hidden" ref={this.myRef2}/>
            <div className="container">
                <h3>
                { this.state.TestDrive == true ?  'Make Offer' : 'Schedule a Test Drive'}
                   
                </h3>
                <div className="row">
                    <div className="col-md align-self-center">
                    <label htmlFor="userName">Name</label>
                       <div className="input-group mb-2">
                          <input type="text" className="form-control" ref={this.textInput} value={this.state.data.Name} id="Name" onChange={this.handleChange} placeholder="Enter Name"  name="Name" />
                               <div className="input-group-prepend">
                                    <div className="input-group-text">
                                         <i className="fa fa-user-o" aria-hidden="true"></i>
                                           </div>
                                            </div>
                                        </div>
                    </div>
                    <div className="col-md align-self-center">
                    <label htmlFor="userNum">Phone Number</label>
                      <div className="input-group mb-2">
                      <input type="text" className="form-control" value={this.state.data.phoneNumber} pattern="[0-9]{11}" id="phoneNumber" onChange={this.handleChange} placeholder="Enter Phone Number" name="phoneNumber" />
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                           <i className="fa fa-mobile" aria-hidden="true"></i>
                                  </div>
                            </div>
                       </div>
                    </div>
                  
                    
                    { this.state.TestDrive == true ?    <div className="col-md align-self-center">
                        <label htmlFor="">Offer</label>
                        <div className="input-group mb-2">
                        <input type="text" className="form-control" value={this.state.data.offer} id="offer" onChange={this.handleChange} placeholder="Your Offer" name="offer" required />
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="icon-cash" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div> : '' }
                    <div className="col-md align-self-center text-center">
                        <button className="btn btn-primary" onClick={this.onSubmit} id="cdetailOffer">Submit</button>
                    </div>
                </div> 
            </div>
        </div>
            </div>
            <div className="container">
                <div className="tys-dealer-location-section mb-5">
                    <h2>Dealer Location</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                <RenderMap Heigth={300} lat={this.state.car.DealerStatLAT} lon={this.state.car.DealerStatLong} data={this.state.car} />
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3>CAR DEALER INFORMATION</h3>
                            <div className="row  tys-dls-details-wrapper">
                                <div className="col-md-6  tys-dls-details">
                                    <span>
                                        Dealer Name
        
                                        <strong>
                                        {this.state.car.Name}
                                        
                                        </strong>
                                    </span>
        
                                    <span>
                                        Address
                                        <strong>
                                        {this.state.car.DealerAddress}
                                        </strong>
                                    </span>

                                    <span>
                                        Dealer Contact
                                        <strong>
                                        {this.state.car.PhoneNumber}
                                        </strong>
                                    </span>
        
                                </div>
                                 <div className="col-md-6">
                                    
                                    {/* <button className="btn btn-outlined dark">schedule test Drive</button> */}

                                 {/* <Link className="btn btn-primary" to={{ pathname: `/cardetails/${this.props.data.car.VehicleID}/${this.props.data.car.DealershipStationID}`, state: { data: this.props.data } }} >Car Detail</Link> */}
                                
                                </div>  
                                <div className="col-sm-6">
                                {/* <ul className="nav nav-pills nav-fill" id="cdTabs" role="tablist">
                    <li className="nav-item active" style={{textAlign: 'left'}}>
                    <a  onClick={(bind)=>this.CheckOpen()}  ><i className="icon-forms" ></i> Emi Calculator</a>
                                 </li>
                    <li className="nav-item" style={{display:"none"}}>
                    <a onClick={()=>this.setState({Check : false})}><i className="icon-forms"></i> Insurance Caculator</a>
                               
                    </li>
                </ul> */}
                                   </div>
                                {/* <div className="col-sm-6">
                                    </div> */}
                            </div>
        
                        </div>
                    </div>
                </div>
         {/* <!--EMI Start--> */}
{/*          
<div className="tys-wizard-content"  style={{display:this.state.check == true? "block":"none"}}>
	<h2>Calculate your loan</h2>
	<div className="tys-bank-slider">
		<label htmlFor="">Select Bank</label>
		{/* <!-- Swiper --> */}
        {/* <Swiper {...params1} > */}
		{/* <div className="swiper-container wizard-slider" id="emiSlider"> */}
			{/* <div className="swiper-wrapper"> */}
			  	{/* <div className="swiper-slide"><img src={Layer1} alt="" /></div>
			  	<div className="swiper-slide"><img src={Layer2} alt="" /></div>
			  	<div className="swiper-slide"><img src={Layer3} alt="" /></div>
			  	<div className="swiper-slide"><img src={Layer4} alt="" /></div>
				<div className="swiper-slide"><img src={Layer5} alt="" /></div>
				<div className="swiper-slide"><img src={Layer1} alt="" /></div>
			  	<div className="swiper-slide"><img src={Layer2} alt="" /></div> */}
			{/* </div> */}
			{/* <!-- Add Pagination --> */}
			{/* <!-- <div className="swiper-pagination"></div> --> */}
			{/* <!-- Add Arrows --> */}
			{/* <div className="swiper-button-next"></div>
			<div className="swiper-button-prev"></div> */}
		  {/* </div> */}
          {/* </Swiper>
		  <div className="tys-terms">
			<span>*Term & Conditions applys as per your selected bank</span>
		  </div>
	</div>
	<div className="wizard-form tys-select-wrapper">
							<hr />
							<div className="row">
								<div className="col-md-6">
									<label htmlFor="">Rate of Interest (%) :</label>
									<input  type ="text" placeholder="5% - 25%">
										{/* <label html="for"> 5%</label> */}
									{/* </input>
								</div>
								<div className="col-md-6">
									<label htmlFor="">Tenure:</label>
									<select className="custom-select">
										<option  value="1" >12 MONTHS</option>
										<option  value="1" >24 MONTHS</option>
										<option  value="1" >36 MONTHS</option>
										<option value="1">48 MONTHS</option>
										<option  value="1" >60 MONTHS</option>
									</select>
								</div>
								<div className="col-6">
									<label htmlFor="">Loan Amount</label>
									<input  type ="text" placeholder="5,111,089"name="title">
										{/* <option  value="1">PKR 10,0000 - 20,0000</option>
										<option value="1">PKR 10,0000 - 20,0000</option> */}
									{/* </input> */}
									{/* <hr /> */}
								{/* </div>  */}
								{/* <div className="col-md-6">
									<label htmlFor="">Rate of Interest (%) :</label>
									<select className="custom-select">
										<option  value="1">16% - 25% </option>
										<option value="1">16% - 25% </option>
									</select>
								</div> */}
								{/* <div className="col-md-6 align-self-center text-center">
									<button className="btn btn-primary w-75">Calculate</button>
								</div>
							</div>
						</div>
	<div className="tys-wizard-total">
		<div>
			<span>
				Your <strong>EMI amount</strong> for the tenure of <strong>12 months</strong>
			</span>
		</div>
		<div>
			<span className="wizard-price"><strong> Rs  1,417,014</strong> per month</span>
		</div>
	</div>
</div> */} 

{/* <!--EMI End--> */}
                <div className="tys-related mt-0" style={{display:  this.state.IsCardsAvailible ? "block" : "none"}}>
                    <span className="tys-related-title">
                        OTHER CARS ON SAME DEALER
                    </span>
                    <div className="related-slider">
                <div className="swiper-container" id="tysRelated">
                    <div className="swiper-wrapper tys-cars-list tys-card-wrapper" >
                

                    <CarSlider data={this.state.DealersCar} Details={this.onSliderClick}  />
                    {/* <Swiper {...params}>


                    {this.state.DealersCar.ListOfCarsWithImages.map((item, index) => {
                      return (



                        <div className="swiper-slide">
                            <div className="card-col">
                                <div className="card tys-card tys-clist-card">
                                    <a href="#" className="tys-card-link">
                                        <div className="tys-quickview">
                                            <span className="tysQuickViewLink"><i className="fas fa-eye"></i></span>
                                            <span className="tysCompareLink "><i className="fas fa-exchange-alt"
                                                aria-hidden="true"></i></span>
                                        </div>
                                        <img className="card-img-top"  src={item.images.FrontImageName}
                                            alt="Card image cap" />
                                        <span className="tys--tc-price tys-tile-hide">
                                            RS <strong>item.car.RetailPrice</strong>
                                        </span>
                                      
                                    </a>
                                    <div className="card-body">
                                        <span className="tys--tc-discount tys-tile-hide"><strong>10%off</strong></span>
                                        <h5 className="card-title">Toyota {item.car.ModelYear}</h5>
                                        <span className="card-location"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                        {item.car.DealershipStationCity} , Toyota
                                            Certified Dealer</span>
                                    </div>
                                    <div className="car-props tys-tile-hide">
                                        <p className="card-text">
                                        

                                            <span>{item.car.Mileage}</span>
                                <span>{item.car.FuleType}</span>
                                <span> {item.car.CC}  CC</span>
                                <span>   {item.car.Transmission}</span>
                                <span>7/10</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

);
})}
    
                     </Swiper> */}
                    </div>
                    
                </div>
            </div>
                    
                </div>
            </div>
        
        </div>
        
        </div>
    
        <ToastContainer/>
            </React.Fragment>





    );

    } 
}export default  Cardetails;