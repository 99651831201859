import React, { Component } from "react";
import Img1 from "../../assets/images/carpage/car-tile.png";
import Img from "../../assets/images/vehicle_not_available.jpg";
//  import BuyImg from "../../assets/images/For-Grid-View-270x386.jpg";
// import BuyImg from "../../assets/images/listView.jpg?0000000000010";
import BuyImg from "../../assets/images/255-x-438.png?0000000000010";
// import BuyImgList from "../../assets/images/For-List-View-25x258.jpg";
// import BuyImgList from "../../assets/images/gridView.jpg?00000000000010";
import BuyImgList from "../../assets/images/970-x-300.png";
// import CarCard from "../../../../91219 Cars/src/components/Cars/carCard";
// import CarDetails from '../car-details/car-details'
import { withRouter, Link, Redirect } from 'react-router-dom';
import Popup from "reactjs-popup";
import { PostService } from "../../services/GeneralServices";
import NumberFormat from 'react-number-format';
import Swiper from 'react-id-swiper';
import ReactPixel from 'react-facebook-pixel';
import Service1 from '../../assets/images/Rewards/Service1.png';
import Service2 from '../../assets/images/Rewards/Service2.png';
import Service3 from '../../assets/images/Rewards/Service3.png';
import Service4 from '../../assets/images/Rewards/Service4.png';
import Service5 from '../../assets/images/Rewards/Service5.png';



// import { GetService, PostService } from "../../services/GeneralServices";

import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from "react-share";
import { string } from "prop-types";


class CarCard extends Component {
    constructor(props) {
        super(props);
        global.TestResult = []
        
        this.state = {
            redirect: false,
            Cars: [],
            selectedImg: '',
            sliderbtn1: true,
            sliderbtn2: false,
            sliderbtn3: false,
            data: this.props.data,
            Benifites: []

        }
        this.handler = this.handler.bind(this);
        // if(props.data.car.AdditionalBenefits !=null && props.data.car.AdditionalBenefits !=""){
        //     var Result = JSON.stringify(JSON.parse(props.data.car.AdditionalBenefits))
        //     var result2 = JSON.parse(Result)        
        //     if(result2 != null){
        //         console.log("Service-->"+result2[0].name+' Discription-->'+result2[0].description)
        //     }
        // }
       
        
    }
    
    async handler(event) {
         ;
        if(event.target.id=="eye")
        {
          ReactPixel.track('User Clicked', ""); 
        }

        event.preventDefault();
        if (event.target.classList[2] === "tysQuickViewLink") {
            let id = this.props.data.car.VehicleID;
            await PostService('api/vehicle/AddPoint?id=' + id, "");


            console.log(this.props.name)
            this.props.Handler(this.props.name);
        }
        else if (event.target.classList[2] === "tysCompareLink") {
            this.props.compareHandler(this.props.data.car.VehicleID, this.props.name);

            // this.props.Handler(this.props.name);
        }


    }
    // getName = () => props.Handler(props.name)
    ChangeSlider(e) {

        console.log(e)
    }
    async RedirectToCarDetail1(event) {

        if (event != undefined) {
            if (event.target.classList[0] == "tys-card-link") {
                this.setState({ redirect: true })
                let id = this.props.data.car.VehicleID;
                await PostService('api/vehicle/AddPoint?id=' + id, "");

            }
        }


    }

    RedirectToCarDetail(event) {
        if (this.state.redirect) { 
            return this.props.history.push({ pathname: '/cardetails/' + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID, state: { data: this.props.data } })
        }
    }
    render() {
        //debugger;
        if(this.props.data.car.AdditionalBenefits !=null && this.props.data.car.AdditionalBenefits !=""){
            var Result = JSON.stringify(JSON.parse(this.props.data.car.AdditionalBenefits))
            var result2 = JSON.parse(Result)        
            if(result2 != null){
                console.log("Service-->"+result2[0].name+' Discription-->'+result2[0].id)
            }
        }
        

        return (
            <React.Fragment>
                <div className="col-lg-4 col-md-6 col-12 card-col">

                    <div className={this.props.data.car.IsCertified ? "card tys-card tys-clist-card tc-certified" : "card tys-card tys-clist-card"}>

                        <span className="tys-card-link " id={this.props.data.car.VehicleID} onClick={this.RedirectToCarDetail1.bind(this)
                        }
                            to={{}}//pathname: '/cardetails/' + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID, state: { data: this.props.data } }} 
                        >
                            <div className="tys-quickview" onClick={this.handler}>
                                <span className="tysQuickViewLink"><i className="fas fa-eye tysQuickViewLink" id='eye'></i></span>
                                <span className="tysCompareLink "><i className="fas fa-exchange-alt tysCompareLink"
                                    aria-hidden="true"></i></span>
                            </div>


                            <div className="tys-miniSlider">
                                <div id="tysMultipleImgOne" className="carousel slide" data-ride="carousel" data-interval="false">
                                    <ol className="carousel-indicators mb-0">
                                        <li data-target="#tysMultipleImgOne" id="tysMultipleImgOne" data-slide-to="0" className={this.state.sliderbtn1 == true ? 'active' : ''} onClick={() => {
                                            this.setState({ sliderbtn1: true, sliderbtn2: false, sliderbtn3: false })
                                        }
                                        }></li>
                                        <li data-target="#tysMultipleImgOne" id="tysMultipleImgOne" data-slide-to="1" className={this.state.sliderbtn2 == true ? 'active' : ''} onClick={() => this.setState({ sliderbtn1: false, sliderbtn2: true, sliderbtn3: false })}></li>
                                        <li data-target="#tysMultipleImgOne" id="tysMultipleImgOne" data-slide-to="2" className={this.state.sliderbtn3 == true ? 'active' : ''} onClick={() => this.setState({ sliderbtn1: false, sliderbtn2: false, sliderbtn3: true })}></li>
                                    </ol>
                                    <div className="carousel-inner">


                                        <div id="maindiv" className={this.state.sliderbtn1 == true ? 'carousel-item active' : 'carousel-item '} style={{ height: "200px"}}>


                                            <img className='d-block w-100' 
                                                src={this.props.data.images.FrontImageName == '' || this.props.data.images.FrontImageName == undefined || this.props.data.images.FrontImageName == null ? Img : this.props.data.images.FrontImageName}
                                                //src={Img}
                                                alt="Card image cap"
                                            />
                                        </div>
                                        <div id="maindiv" className={this.state.sliderbtn2 == true ? 'carousel-item active' : 'carousel-item '} style={{ height: "200px" }}>
                                            <img className='d-block w-100' 
                                                src={this.props.data.images.BackImageName == '' || this.props.data.images.BackImageName == undefined || this.props.data.images.BackImageName == null ? Img : this.props.data.images.BackImageName}
                                                //src={Img1}
                                                alt="Card image cap"
                                            />
                                        </div>
                                        <div id="maindiv" className={this.state.sliderbtn3 == true ? 'carousel-item active' : 'carousel-item '} style={{ height: "200px"}}>
                                            <img className='d-block w-100' 
                                                src={this.props.data.images.LeftImageName == '' || this.props.data.images.LeftImageName == undefined || this.props.data.images.LeftImageName == null ? Img : this.props.data.images.LeftImageName}
                                                //src={Img}
                                                alt="Card image cap"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>



                            {/* /////////////////////////////////// */}

                            <span className="tys--tc-price tys-tile-hide marg" style={{ backgroundColor: '#d21414' }}>
                                <strong>RS <NumberFormat value={this.props.data.car.DiscountedPrice == null || this.props.data.car.DiscountedPrice == "" || this.props.data.car.DiscountedPrice == undefined || this.props.data.car.DiscountedPrice == 0 ? this.props.data.car.RetailPrice : this.props.data.car.DiscountedPrice} displayType={'text'} thousandSeparator={true} />
                                </strong>
                                {/* <small className="tys--tc-price strikethrough strike" style={{ display: this.props.data.car.DiscountedPrice == null || this.props.data.car.DiscountedPrice == "" || this.props.data.car.DiscountedPrice == undefined || this.props.data.car.DiscountedPrice == 0 ? "none" : "block" }}>
                                    RS <strong><NumberFormat value={this.props.data.car.RetailPrice}
                                        displayType={'text'} thousandSeparator={true} />
                                    </strong>
                                </small> */}
                            </span>
                            {/* <span style={{ display: this.props.data.car.IsFeaturedMark == true && this.props.ChangeView == false ? "block" : "none" }} className="tys--tc-featured tys-tile-hide">Featured</span> */}

                        </span>
                        {this.RedirectToCarDetail()}

                        <div className="card-body">                     
                            <div className="" style={{float: 'right'}}>
                                {this.props.data.car.AdditionalBenefits == null ?"": result2.map(x=>{
                                           if(x.id === 'Service1'){
                                            return <img src={Service1} height="30px" style={{marginTop: '10px'}}/>                                            
                                           }else if(x.id === 'Service2'){
                                            return <img src={Service2} height="30px" style={{marginTop: '10px'}} />
                                           }else if(x.id === 'Service3'){
                                            return <img src={Service3} height="30px" style={{marginTop: '10px'}}/>
                                           }else if(x.id === 'Service4'){
                                            return <img src={Service4} height="30px" style={{marginTop: '10px'}}/>
                                           }else if(x.id === 'Service5'){
                                            return <img src={Service5} height="30px" style={{marginTop: '10px'}}/>
                                           }
                                           
                                        })}
                                </div>
                           
                        
                            {/* <span className="tys--tc-discount tys-tile-hide" style={{ display: this.props.data.car.IsSpecialOffer == false ? "none" : "block" }}><strong>Special Offer</strong></span> */}
                            {/* <div style={{ display: this.props.data.car.IsFeaturedMark == true && this.props.ChangeView == true ? "block" : "none" }} className="text-right tys-tile-show">
                                <span className="tys--tc-featured">Featured</span>
                            </div> */}
                           
                                <div className="tys-tile-show"  >
                                    <span className="tys--tc-price old" style={{ display: this.props.data.car.DiscountedPrice == null || this.props.data.car.DiscountedPrice == "" || this.props.data.car.DiscountedPrice == undefined || this.props.data.car.DiscountedPrice == 0 ? "none" : "-webkit-inline-box" }}>
                                        {/* <NumberFormat value={this.props.data.car.discountedprice}  thousandSeparator={true} /> */}
                                        <strong >RS <NumberFormat value={this.props.data.car.RetailPrice} displayType={'text'} thousandSeparator={true} />  </strong>
                                    </span>
                                </div>
                                {/* <div className="tys-tile-show">
                                    <span className="tys--tc-price tys-tc-price"> */}
                                        {/* <strong><NumberFormat value={this.props.data.car.DiscountedPrice} displayType={'text'} thousandSeparator={true} /></strong> */}
                                        {/* <strong ><NumberFormat value={this.props.data.car.DiscountedPrice == null || this.props.data.car.DiscountedPrice == "" || this.props.data.car.DiscountedPrice == undefined || this.props.data.car.DiscountedPrice == 0 ? this.props.data.car.RetailPrice: (this.props.data.car.RetailPrice-this.props.data.car.DiscountedPrice) } displayType={'text'} thousandSeparator={true} /></strong>
                                    </span>
                                </div> */}
                                
                                {/* <div className="col-md-3">
                                {this.props.data.car.AdditionalBenefits == null ?"": result2.map(x=>{
                                           if(x.id === 'Service1'){
                                            return <img src={Service1} height="30px" />                                            
                                           }else if(x.id === 'Service2'){
                                            return <img src={Service2} height="30px" />
                                           }else if(x.id === 'Service3'){
                                            return <img src={Service3} height="30px" />
                                           }else if(x.id === 'Service4'){
                                            return <img src={Service4} height="30px" />
                                           }else if(x.id === 'Service5'){
                                            return <img src={Service5} height="30px" />
                                           }else if(x.id === 'Service6'){
                                            return <img src={Service6} height="30px" />
                                           }
                                           
                                        })}
                                </div> */}
                            
                            
                                


                            <h5 className="card-title">{this.props.data.car.MakerName} {this.props.data.car.ModelName}
                            
                            </h5>
                            <span className="card-location">
                                <h5 className="card-title form-inline">
                                    {this.props.data.car.VariantName}
                                    <Popup trigger={<span className="tys-shareLink" style={{marginLeft:"10px", color: "#d21414"}}><span ><i className="fa fa-share-alt" aria-hidden="true"></i></span></span>} position="right center">
                                    <div>
                                        <WhatsappShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID} >
                                            <WhatsappIcon size={32} round={true} />
                                        </WhatsappShareButton>

                                        <FacebookShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID} >
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                    </div>
                                </Popup>
                                </h5>
                                
                                <span className="cd-detail-add">
                                    <i className="" aria-hidden="true"></i>{this.props.data.car.Name}

                                </span>
                                <br></br>
                                <span className="cd-detail-add">
                                    Stock ID: {this.props.data.car.EnquirySno}

                                </span>
                                {/* <span className="" style={{ display: this.props.data.car.AdditionalBenefits == null ? "none" : "block" }}>
                                    {global.bnane[0]}</span> */}
                                                                
                               
                            </span>

                            {/* ------------adding card button----------------- */}

                            <div className="tys-car-badge row">
                                {/* <span className="tys--tc-featured outline" style={{ display: this.props.data.car.IsCertified == false ? "none" : "block" }} >Certified
     <i className="fa fa-check-circle" aria-hidden="true">
                                    </i></span> */}
                                {/* <span className="tys--tc-featured green" style={{display: this.props.data.car.IsSpecialOffer == false ? "none" : "block" }}>Special Offer</span> */}
                                <span className="tys--tc-featured" style={{ display: this.props.data.car.IsFeaturedMark == false ? "none" : "block" }}>Featured</span>
                                <span className="tys--tc-featured green" style={{ display: this.props.data.car.IsSpecialOffer == false ? "none" : "block" }}>Special Offer</span>
                                {/* {this.props.data.car.AdditionalBenefits == null ?"": result2.map(x=>{
                                           if(x.id === 'Service1'){
                                            return <img src={Service1} height="30px" />                                            
                                           }else if(x.id === 'Service2'){
                                            return <img src={Service2} height="30px" />
                                           }else if(x.id === 'Service3'){
                                            return <img src={Service3} height="30px" />
                                           }else if(x.id === 'Service4'){
                                            return <img src={Service4} height="30px" />
                                           }else if(x.id === 'Service5'){
                                            return <img src={Service5} height="30px" />
                                           }else if(x.id === 'Service6'){
                                            return <img src={Service6} height="30px" />
                                           }
                                           
                                        })} */}
                            </div>

                            {/* ---------------------------------------------- */}

                            {/* <div className="car-props tys-tile-show">
                                <p className="card-text">

                                    <span>
                                        <span className="props-title">Year</span>
                                        {this.props.data.car.ModelYear}
                                    </span>

                                    <span>
                                        <span className="props-title">Transmission</span>
                                        {this.props.data.car.Transmission}
                                    </span>
                                    <span>
                                        <span className="props-title">City</span>
                                        {this.props.data.car.DealershipStationCityName}
                                    </span>
                                    <span>
                                        <span className="props-title">Mileage</span>
                                        {this.props.data.car.Mileage}
                                    </span>
                                    <span>

                                    </span>
                                </p>
                            </div> */}
                        </div>
                        <div className="car-props tys-tile-hide">
                            <p className="card-text">
                                <span style={{ display: this.props.data.car.ModelYear !== "" || this.props.data.car.ModelYear !== 0 || this.props.data.car.ModelYear !== null ? "block" : "none" }}> {this.props.data.car.ModelYear}</span>
                                <span>{this.props.data.car.Transmission}</span>
                                <span> {this.props.data.car.DealershipStationCityName} </span>
                                <span style={{ display: this.props.data.car.Mileage !== "" || this.props.data.car.Mileage !== 0 || this.props.data.car.Mileage !== null ? "block" : "none" }}> <NumberFormat value={this.props.data.car.Mileage}
                                    displayType={'text'} thousandSeparator={true} /> KM</span>
                            </p>
                        </div>
                        <span className="tys--tc-discount tys-tile-show" style={{ display: this.props.data.car.IsSpecialOffer == true && this.props.ChangeView == true ? "block" : "none" }}>Special Offer available
    </span>
                    </div>
                </div>


                <div className="col-lg-4 col-md-6 col-12 card-col" style={{ display: this.props.data.Add == "Add" ? "block" : "none" }}>
                <Link to="/exchange-a-car" onClick={() => {window.scrollTo(0,0)}}><img src={this.props.ChangeView == true  ? BuyImgList: BuyImg } alt="" className="h-100 w-100 tys-promotion" /></Link>
                </div>

            </React.Fragment >
        );
    }

}
export default withRouter(CarCard)