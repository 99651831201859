import React, { Component } from "react";

import BGimage4 from '../../assets/images/about/comparision_bg.jpg'
import Comparision from '../../assets/images/about/comparision.png'


class SureDifference extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (

            <div className="tys-section" style={{ backgroundImage: "url(" + BGimage4 + ")" }}>
                <div className="container text-center">
                    <h1 className="tys-title">
                        The <strong>Toyota SURE</strong> Difference
    </h1>
                    <p className="w-75 mt-5 ml-auto mr-auto">
                        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry's standard dummy text ever since the 1500s,
                 */}
                    </p>

                    {/* <img src={Comparision} alt="" className="w-100 mt-5" /> */}

                    <div className="diff-title">
                        <span className="ml-0">Toyota Sure
                    Dealership</span>
                        <span className="dt-title">Toyata Sure Promise</span>
                        <span className="mr-0">
                            Private
                            Seller
                </span>
                    </div>

                    <ul className="tys-diff-list">
                        <li><span className="pt-3">Only the best vehicles are eligible
                    for certification</span></li>
                        <li><span>
                            Most of our certified vehicles will have
                            a full service history
                </span></li>
                        <li><span>
                            It includes a 1 year or 15,000 km
                            extended warranty with nationwide
                            coverage
                </span></li>
                        <li><span>
                            It will be reconditioned with
                            Toyota’s global standards
                </span></li>
                        <li><span>
                            It will be confirmed to have no major
                            accidents, engine or transmission failure
                </span></li>
                        <li><span>
                            It will carry all authenticated, verified
                            documents with seller credentials
                </span></li>
                        <li><span>
                            Get the best price for your used car
                </span></li>

                    </ul>
                </div>
            </div>


        );
    }
}


export default SureDifference;