import React, { Component } from 'react';
import logo from '../../assets/images/logo.png?000000000010';
import logo_dark from '../../assets/images/logo_dark.png';
import {  Link } from "react-router-dom";


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkOpen:false,
			check: 0,
			checkOnBtnHamberger : false
		}
		
		this.headerStyle = this.headerStyle.bind(this);
		this.node = React.createRef();

		// this.forResponsive = this.forResponsive.bind(this);
	}



    handleMoreMenu = (e) => {
        if (this.node.contains(e.target)) {
            return;
		}
		if(e.target.className != "dropdown-item" && e.target.className != "pricedropdown")
		{
			this.setState({ checkOpen: false })
		}
        
	}
	

	//----Add Event Listner To Get The Value Of ScrollTop of WIndow------//  
	componentDidMount() {
	
		window.addEventListener('scroll', this.headerStyle);
	    document.addEventListener('mousedown', this.handleMoreMenu, false);
	}
	//----------// 



	//----Remove Event Listener------//  
	componentWillUnmount() {
		
		window.removeEventListener('scroll', this.headerStyle);
		document.removeEventListener('mousedown', this.handleMoreMenu, false);
	}
	//----------//  
// forResponsive()
// {
// 	if(this.state.checkOnBtnHamberger == "false"){
// 		this.setState({checkOnBtnHamberger : "true"});

// 		document.getElementById("btnHamBurger").classList.remove("collapsed");
// 		document.getElementById("navbarText").className += " show";
// 		document.getElementById("btnHamBurger").setAttribute("aria-expanded", "true");
		
// 	}else{

// 		this.setState({checkOnBtnHamberger : "false"});

// 		document.getElementById("btnHamBurger").className +=" collapsed";
// 		document.getElementById("navbarText").classList.remove("show") ;
// 		document.getElementById("btnHamBurger").setAttribute("aria-expanded", "false");
// 	}
	

// }

// forResponsive(e)
//       {
// 		  
		
// 		this.setState({checkOnBtnHamberger: true})
// 	}	
		



	//----This is the Function to Stick Header On Scroll Down------//  
	headerStyle() {
		
		var windowpos = document.body.scrollTop || document.documentElement.scrollTop

		var siteHeader = document.getElementById('navbody');
		if (windowpos >= 100 & this.state.check == 0) {
			// 
			this.setState({ check: 1 })
			var a = document.getElementById('navbody');
			// 
			a.className += " fixed-header";
		}
		if (windowpos < 100 & this.state.check == 1) {
			this.setState({ check: 0 })
			siteHeader.classList.remove("fixed-header");
		}
	}
	//----------//  
	
		
render() {
		return (
		
			<header className={this.props.Class} id="navbody">
				<div className="tys-nav">
					<div className="container tys-nav-container">

						<nav className="navbar navbar-expand-lg navbar-dark">
							
							<Link  className="navbar-brand " to="/home" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>
								<img src={logo} alt="Toyota Sure" className="tys-static-logo" /> 
								{/* logo_dark */}
								<img src={logo} alt="Toyota Sure" className="tys-fixed-logo" />
								</Link>
							{/* --------------------- */}
							{/* <button className="navbar-toggler collapsed" id="btnHamBurger" type="button" onClick={this.forResponsive} aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" data-toggle="collapse" data-target="#navbarText"> */}
							<button className={this.state.checkOnBtnHamberger == true ? "navbar-toggler":"navbar-toggler collapsed"} id="btnHamBurger" type="button" onClick={() => { this.setState({checkOnBtnHamberger:this.state.checkOnBtnHamberger== true?false:true})} } aria-controls="navbarText" aria-expanded={this.state.checkOnBtnHamberger == true ? 'true' : 'false'} aria-label="Toggle navigation" data-toggle="collapse" data-target="#navbarText">
								
								<span className="navbar-toggler-icon" ></span>
							</button>
							{/* -------------- */}
							{/* <div className="navbar-collapse collapse" id="navbarText"  > */}
							<div className={this.state.checkOnBtnHamberger == true ? "navbar-collapse collapse show":"navbar-collapse collapse"} id="navbarText"  >
								<div className="tys-top-nav">
									<Link  to="/faqs" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>FAQs</Link>
									<Link  to="/dealer" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>Dealer Locator</Link>
									<div className="social-links">
								<ul className="list-inline">
									<li className="list-inline-item"><a href="https://www.facebook.com/ToyotaSurePk/?modal=admin_todo_tour"><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
									{/* <li className="list-inline-item"><a href="https://www.twitter.com"><i className="fab fa-twitter" aria-hidden="true"></i></a></li> */}
									<li className="list-inline-item"><a href="https://www.instagram.com/toyotasurepk/?hl=en"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
									<li className="list-inline-item"><a href="https://www.youtube.com/channel/UC5YRUqR4n953JeHscbqCqqg/featured?view_as=subscriber"><i className="fab fa-youtube" aria-hidden="true"></i></a></li>
									<li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=923001869682"><i className="fab fa-whatsapp" aria-hidden="true"></i></a></li>

									<li className="list-inline-item"><a href="https://www.toyota-indus.com/" className="t-icon"><i className="icon-toyota-mini"></i></a></li>
								</ul>
						</div>
								
								</div>
								<ul className="navbar-nav ml-auto">
								<li className="nav-item" >
									<Link  className="nav-link nav-link-featured" to="/about" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span>About</span></Link>
									</li>
									
									{/* <li className="nav-item">
									<Link  className="nav-link " to="/media"><span>Media</span></Link>
										
									</li> */}

									<li className="nav-item" >
										<Link  className="nav-link  nav-link-featured " to="/car" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span>Buy a Car</span></Link>
									</li>
									
									{/* <li className="nav-item" >
									<Link  className="nav-link nav-link-featured"  to="/usedcargala" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span >Used Car Gala 2.0</span></Link></li> */}
								
									<li className="nav-item" >
									     <Link className="nav-link nav-link-featured"  to="/sell-a-car" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span>Sell a car</span></Link></li>
									<li className="nav-item" >
									<Link  className="nav-link nav-link-featured" to="/exchange-a-car" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span>Exchange car</span></Link></li>
									
									{/* <li className="nav-item">
									<Link  className="nav-link  nav-hide-sticky" to="/comingSoon" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}><span>The certified</span></Link></li>
								 */}
									<li className="nav-item dropdown nav-show-sticky">
								<div className="nav-link dropdown-toggle" href='#' id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" onClick={()=>this.setState({checkOpen: !this.state.checkOpen })} aria-expanded={this.state.checkOpen == false ? true:false} ref={node => this.node = node}>
								  More
								</div>
								<div className={ this.state.checkOpen ? "dropdown-menu show " : "dropdown-menu" } aria-labelledby="navbarDropdown">
								  
								  {/* <Link className="dropdown-item" to ="/certified" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>The certified</Link> */}
								  <Link className="dropdown-item" to="/faqs" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>Faqs</Link>
								  <Link className="dropdown-item" to="/dealer" onClick={()=>{this.setState({checkOnBtnHamberger : false}); window.scroll(0,0)}}>Dealer Locator</Link>
								</div>
							  </li>
								</ul>
								{/* <span className="navbar-text tys-nav-panel-btn" onClick = {()=>(this.props.Handler(this))}>
									<span className="icon-hamburger"  id="hamburgerLink"></span>
							</span> */}
								{/* <span className="navbar-text tys-nav-panel-btn" onClick = {()=>(this.props.Handler(this))}>
						<span id="hamburgerLink">
						<span className="icon-hamburger d-lg-block d-none"></span>
						<span className="pl-3 d-lg-none d-block">MENU PANEL</span>
						</span>
					</span> */}
							</div>
						</nav>
					</div>
				</div>
				
			</header>


		)
	}
}

export default Header;


