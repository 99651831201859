import React, { Component } from 'react';

import { PostService, GetService  } from '../../services/GeneralServices';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter, Link, Redirect } from 'react-router-dom';


class Demoform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            phoneNumber: '',
            offer:'',
            isredirect: false,
        }   
}


renderRedirect = () => {
  
  if (this.state.isredirect) {

    return <Redirect to={{ pathname: '/car' }} />

  }
  else {

  }
}

handleChange = ({ currentTarget: input }) => {
  // 
  // const errors = { ...this.state.errors };
  // const errorMessage = this.validateProperty(input);
  // if (errorMessage) errors[input.name] = errorMessage;
  // else delete errors[input.name];
  const data = { ...this.state.data };
  data[input.name] = input.value;

  this.setState({ data });
};
      onSubmit = async (e) => {
        // 
       
    
        var model = {
          Sno: null,
          Source: "Website",
          Remarks: "Tsure Enquiry" ,
          CustomerPurposeOfVisit: 'P',
          CustomerInterestLevel: 'o',
          CustomerName: this.state.data.Name,
          CustomerMobile: this.state.data.phoneNumber,
          CustomerMobile2: null,
          CurrentVehExpectedPrice: null,
          NextVehExpectedPrice: this.state.data.offer,
          NextVehSellingPrice: null,
          IsNextUsedVehicleUnavailable: null,
          City: null,
          Mileage:null,
          Fuel_type: null,
          customerAddress:null,
          Dealer_Locator : null,
          DealershipStationID: this.props.location.state.data.DealershipStationID ,

          CurrentVehicle: {
            MakeID: null,
            ModelID: null,
            VaraintID: null,
            Year: null,
            RegNum: null,
            EngineNum: null,
            ChasisNum: null,
            Mileage: null,
            Color: null,
            IsInspected: false,
            HasArived: false,
            FinalPurchasePrice: 0,
            RetailPrice: 0,
            InspectionOneSummary: {
              InspectionDate: null,
              ExteriorScore: null,
              InteriorScore: null,
              EngineScore: null,
              FrameScore: null,
              TotalScore: null,
              QualityGrade: null,
              Remarks: null,
              VehicleImages: {
                HoodImageName: null,
                FrontImageName: null,
                BackImageName: null,
                InteriorImageName: null,
                LeftImageName: null,
                RightImageName: null,
                OtherImageName: null
              }
            },
            InspectionTwoSummary: {
              InspectionDate: null,
              ExteriorScore: null,
              InteriorScore: null,
              EngineScore: null,
              FrameScore: null,
              TotalScore: null,
              QualityGrade: null,
              Remarks: null,
              VehicleImages: {
                HoodImageName: null,
                FrontImageName: null,
                BackImageName: null,
                InteriorImageName: null,
                LeftImageName: null,
                RightImageName: null,
                OtherImageName: null
              }
            },
    
            ArivalOfVehicle: {
              DateOfArival: null,
              Remarks: null
            },
            Refurbishments: {
              ReFubDataSheet: null,
              ReFubDataSheetName: null
            }
          },
    
          NextUsedVehicle: {
            VehicleID: this.props.location.state.data.VehicleID,
            UsedCarModel: null,
            UsedCarVaraint: null,
            UsedCarRegCity: null,
            UsedCarColor: null,
            UsedCarMake: null,
            UsedCarCity: null,
          },
    
          UnavailableUsedVehicle: {
            Make: null,
            Model: null,
            Variant: null,
            Year: null,
            Color: null
          },
          NextNewVehicle: {
            MakeID: null,
            ModelID: null,
            VariantID: null,
            Year: null,
            Color: null,
            PBONum: null
          },
    
          EnquiryStatus: {
            Status: "W",
            DealFollowupData: {
              date: null,
              Reason: null
            },
            DealLostData: {
              Reason: null
            },
            DealDoneData: {
              NumberOfKeys: null,
              HasWarrentyBook: null,
              HasOwnerManual: null,
              HasSaleInvoice: null,
              HasRegistrationBook: null,
              HasRunningPage: null,
              IsCPLCClear: null,
              HasJackedNRod: null,
              HasToolkit: null,
              HasSpareWheel: null,
              HasNumPlate: null,
              HasChasisIdPlate: null
            }
          }
        };
        console.log(model)
         await PostService('api/enquiry/new', model)
         .then( response => {
          console.log('response get---', response);

          if (response.status == 200) {
            toast.success("Enquiry submit successfully", {
              position: toast.POSITION.TOP_RIGHT
            })
            
            
            setTimeout(() => { this.setState({
              isredirect : true
            }) }, 3000);
    
            
    
          } else {
            toast.warn(`${response.statusText} -- ${response.status}`, {
              position: toast.POSITION.TOP_RIGHT
            })
    
          }

         })
         .catch( err => console.log(err));
        
       
    
    
      }
    componentWillMount(){
        // 
        document.getElementById('root').classList.remove("tys-draw-body")
    }
	render() {
    
    console.log(this.props.location.state.data.VehicleID)
    console.log(this.props.location.state.data.DealershipStationID)
		return(
            <form className="was-validated" style={{width: "300px" , paddingLeft:"37px" , marginLeft: "486px" }}>
            <div className="form-group">
              <label htmlFor="uname">Full Name:</label>
              <input type="text" className="form-control" id="Name"  onChange={this.handleChange} placeholder="Enter Full Name" name="Name" required/>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>
            
            <div className="form-group">
              <label htmlFor="pwd">Phone No:</label>
              <input type="text" className="form-control" id="phoneNumber"  onChange={this.handleChange} placeholder="Enter Phone No" name="phoneNumber" required/>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>
        
            <div className="form-group" hidden={this.props.location.state.From=="M"? false : true}>
              <label htmlFor="uname">Offer:</label>
              <input type="text" className="form-control" id="offer"  onChange={this.handleChange} placeholder="Your Offer" name="offer" required/>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>
        
            <button type='button'  onClick={this.onSubmit}   style={{ backgroundColor: "red" , color: "white" }}>SUBMIT </button>
            {this.renderRedirect()}
            <ToastContainer/>
            </form>

           
		);
	}
}

export default withRouter(Demoform);