import logo from '../../assets/images/logo.png';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
const image123 = require("../../assets/images/hamburger_bg.png");

class navHamBurger extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Check: false,
		}
	}



	render() {
		return (
			<div className={this.props.Check === true ? 'tys-hamburger show' : "tys-hamburger"} id="hamburgerPanel" style={{ backgroundImage: `url(${image123})` }}>
				<div className="tys-hb-inner">
					<span className="close-drawer" onClick={this.props.Handler.bind(this)} id="hamburgerClose"><i className="fas fa-times"></i></span>
					{/* <a href="" className="close-drawer" onClick= {this.props.Handler.bind(this)} id="hamburgerClose"><i className="fas fa-times"></i></a> */}

					<div className="d-block">
						<div className="input-group mb-3">
							<input type="text" className="form-control" placeholder="Recipient's username"
								aria-label="Recipient's username" aria-describedby="basic-addon2" />
							<div className="input-group-append">
								<button className="btn btn-outline-secondary" type="button"><span
									className="icon-searchBar"></span></button>
							</div>
						</div>
					</div>
					<div className="d-block mt-5 mb-5 text-center">
						<img src={logo} alt="" className="ml-auto mr-auto" />
					</div>
					<p className="text-center">
						{/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repellendus aut
			            officia aspernatur velit quis nulla adipisci officiis! Iure, deleniti. */}
						</p>

					<div className="row mt-5 mb-5">
						<div className="col"><img src={"//placehold.it/200x200"} alt="" /></div>
						<div className="col"><img src={"//placehold.it/200x200"} alt="" /></div>
						<div className="col"><img src={"//placehold.it/200x200"} alt="" /></div>
					</div>
					<div className="row mt-5 mb-5">
						<div className="col" onClick={this.props.Handler.bind(this)}>
							{/* <a href="" className="btn">Buy a Car</a> */}
							<Link className="btn" to="/car">BUY A CAR</Link>
						</div>
						<div className="col" onClick={this.props.Handler.bind(this)}>
							{/* <a href="" className="btn">Buy a Car</a> */}
							<Link className="btn" to="/sell-a-car" >SELL YOUR CAR</Link>
						</div>
					</div>
					<p>
						Get help <strong>EMI Caculator</strong> for estimate! <Link to="/emi">
							{/* {/* <span className="icon-forms ml-4"> </span> */}
								</Link>
					</p>

					<div className="tys-h-social mt-auto text-center text-uppercase">
						<p className="mb-2"><strong>Social Links</strong></p>
						<ul className="list-inline social-list">
							<li className="list-inline-item"><a href="https://www.facebook.com/ToyotaSurePk/?modal=admin_todo_tour"><i className="icon-Sfb"></i></a></li>
							{/* <li className="list-inline-item"><a href="#"><i className="icon-Stwitter"></i></a></li> */}
							<li className="list-inline-item"><a href="https://www.instagram.com/toyotasurepk/?hl=en"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
							<li className="list-inline-item"><a href="https://www.youtube.com/channel/UC5YRUqR4n953JeHscbqCqqg/featured?view_as=subscriber"><i className="fab fa-youtube" aria-hidden="true"></i></a></li>
							<li className="list-inline-item"><a href="https://www.toyota-indus.com/"><i className="icon-toyota-mini"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default navHamBurger;





