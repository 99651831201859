import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter , HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import HttpsRedirect from 'react-https-redirect';
// import { Provider } from 'react-redux'
// import {createStore} from 'redux';
import Home from './components/Home/home'
// import store from './store'    store={store}
 //const store = createStore()
ReactDOM.render(
  <HashRouter>
  {/* <Provider >
  </Provider > */}
    <App />
   
  </HashRouter>,
  document.getElementById("root")
)
registerServiceWorker();
