import React, { Component } from 'react';
import SoonImage from '../../assets/images/soon/cs-bg.jpg';
import ToyotaSoon from '../../assets/images/soon/ts-logo-md.png';

class ComingSoon extends Component {
    render() {
        return (
    
            <div className="tys-full-width" aria-label="Content">
        <div className="tys-soon" style={{backgroundImage : `url(${SoonImage})`}}>
    <div className="container">
        <div className="tys-soon-inner">
            <div className="tys-time" style={{ margintop: 13 ,marginLeft : 12}}>
                <div>
                    <h2 style={{"marginBottom": 30}}>365<span className="bg-txt txt-day">Days</span></h2>
                </div>
                <div className="tys-timer">
                    <span className="bg-txt" style={{"fontSize": 38}}>
                        15HR : 26Mins : 30SEC
                    </span>
                </div>
                <div className="text-center" 
                // style={{textAlign: "center" }}
                ref={element => { 
                    if (element) element.style.setProperty('text-align', 'center', 'important'); 
                  }}
                >
                    <span className="desc">
                        Before we launch <strong>our new website</strong>.
                    </span>
                </div>
            </div>
            <div className="tys-launch-content text-center">
                <img src={ToyotaSoon} alt="" className="mb-4 ml-auto mr-auto" />
                <h1>
                    Coming Soon with whole new
                    <br/>
                    <strong>look and features!</strong>
                </h1>
            </div>
        </div>
    </div>
</div>
    </div>


        );
    }
}

export default ComingSoon;