import React, { Component } from "react";
import Slider from './slider.jsx'
import Testimonial from './testimonial.jsx'
import PopularCar from './popularcar.jsx'
import Servies from './services.jsx'
import ToyotaSure from './toyotaSure.jsx'
import SpecialOffer from './specialOffer.jsx'
import Search from './search.jsx'
import DealerShip from './dealership.jsx'
import Loader from '../General/loader';
import { GetService } from "../../services/GeneralServices.js";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data : {

			},
			loader:false,
			 
		}
		//-----Add Class On Header When Home is Render-----//  
		this.props.Handler();
		//----------//  
	}
	componentWillUnmount() {
	
		this.props.Handler();
	}
	async componentWillMount() {
	
		this.setState({ loader:true})
		try {
			const { data } = await GetService('api/Vehicle/GetDropDown');
			this.setState({ data : data.Data , loader : false})
		
		} catch (error) {
			this.setState({ loader : false})
		}
        
	}
	
	render() {
	
		return (
			<React.Fragment>
				{this.state.loader ? <Loader/> : ''}
				<Slider />
				{
					this.state.data.maker === undefined ? <div></div> : <Search Data={this.state.data}/>

				}				
				<SpecialOffer />
				<ToyotaSure />
				<Servies />
				{
					this.state.data.maker === undefined ? <div></div> : <PopularCar Data = {this.state.data.cities.filter(x=> x.priority == 1)}/>

				}	
				
				<Testimonial />
				<DealerShip />

			</React.Fragment>

		);
	}
}

export default Home;
