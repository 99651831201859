import React, { Component } from "react";
// import React, { Component } from "./node_modules/react";
import FaqPara from './faqPara';

import Breadcrum from "../breadcrum";

import FaqQuery from './faqQuery';


const faqImage = require("../../assets/images/faq_bg.jpg");


class Faqs extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="tys-main" aria-label="Content" >
          <div className="tys-page" style={{ backgroundImage: `url(${faqImage})` }}>


            <Breadcrum Name="FAQ" />

            <FaqPara />
            <div className="tys-faq">
              <div className="container">
                <FaqQuery />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default Faqs;
