import React, { Component } from "react";
import { withRouter, Redirect } from 'react-router-dom';
// import { withRouter, Link, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from 'react-number-format';

class CompareLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
      
    }
   
    this.exist = this.exist.bind(this);
  }

  exist() {
    
    document.getElementById("stsCBar").classList.remove("active");
  }

  CompareBtn = () =>
  {
    
    if(this.props.data.length === 0)

  {
    toast.warn("Nothing to compare", {
      position:  toast.POSITION.TOP_RIGHT
    });
  }
   
    else if(this.props.data.length === 1)
    {
      toast.warn("Please Select More Than one", {
        position:  toast.POSITION.TOP_RIGHT
      });
  }
    else
    {
    this.setState({
      redirect: true
    })
  }
  
  }
  ToastFunc = () =>
  {
   
  }
  renderRedirect = () => {
    
    if (this.state.redirect) {
   
       return  <Redirect  to={{ pathname: '/compare', state: { data: this.props.data } }} />
    
    }
    else
    {

    }
  }
  render() { 
    
    
 
    return (
      <React.Fragment>
          
        <div className="sts-c-bar-wrap">
          <div className="sts-c-bar-heading">
            <i
              className="fa fa-times-circle-o closecomparepanel"
              onClick={this.exist}
              aria-hidden="true"
            ></i>
            <h5 className="rehub-main-color">
              <i
                className="fa fa-balance-scale color-primary"
                aria-hidden="true"
              ></i>
              Compare Vehicle
                </h5>
            <span>{this.props.data.length}</span>
          </div>
          <div className="sts-c-bar-tabs">
            {this.props.data.map(data => {
              return (<div>
                <div className="sts-c-wrap sts-c-wrap-54">
                  <div className="sts-c-item">
                    {/* <!-- <i
                                 className="fas fa-times-circle sts-c-new-close"></i> --> */}
                    <div className="sts-c-img">
                      <span>
                        {/* -----------added height and width in image----------- */}
                        <img src={data.images.FrontImageName} alt=""  style={{height:200,width:400}}/>
                      </span>
                    </div>
                    <div className="d-flex tys-card-wrapper">
                      <div className="w-100 d-flex flex-column justify-content-center">
                        <div className="sts-c-title mb-1">
                                <span>{data.car.MakerName}  {data.car.VariantName}</span>
                        </div>
                        {/* <div className="greencolor font80">Ad ID: {data.car.VehicleID}</div> */}
                        </div>
                      <div className="w-100 text-center">
                        <span className="tys--tc-price old mb-2" style={{display :data.car.DiscountedPrice == null || data.car.DiscountedPrice == "" || data.car.DiscountedPrice == undefined || data.car.DiscountedPrice == 0 ? "none" : "block" }} >
                          <strong><NumberFormat value= {data.car.DiscountedPrice == null || data.car.DiscountedPrice == "" || data.car.DiscountedPrice == undefined || data.car.DiscountedPrice == 0 ? data.car.RetailPrice : data.car.DiscountedPrice }  displayType={'text'} thousandSeparator={true}  /></strong>
                        </span>
                        <span className="tys--tc-price">
                          <strong><NumberFormat value= {data.car.DiscountedPrice == null || data.car.DiscountedPrice == "" || data.car.DiscountedPrice == undefined || data.car.DiscountedPrice == 0 ? data.car.RetailPrice : data.car.DiscountedPrice}  displayType={'text'} thousandSeparator={true}  /></strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              )
            })}
            
            {/* < Link className="sts-c-destin wpsm-button rehub_main_btn btn btn-primary" to={{ pathname: '/compare', state: { data: this.props.data } }} >compare</Link> */}
            < button className="sts-c-destin wpsm-button rehub_main_btn btn btn-primary" onClick={this.CompareBtn.bind(this)} >COMPARE</button>
            {this.renderRedirect()}


          </div>
        </div>


        <ToastContainer />
      </React.Fragment >
    );
  }
}

export default withRouter(CompareLink);