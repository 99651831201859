import React, { Component } from "react";

import Tyi from '../../assets/images/about/tyi_bg.png'
import Vigo from '../../assets/images/about/vigo.png'
import TyiLogo from '../../assets/images/about/tyi.png'


class DownBanner extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (

<span className="backGroundRedColor">
<div className="tys-section"  style={{backgroundImage: `url(${Tyi})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md">
                        <div className="md">
                            <img src={TyiLogo} alt="TYI" />
                            <h1 className="tys-title">
                                ONLY THE BEST
                                CARS ARE <strong>CERTIFIED</strong>
                            </h1>
                        </div>
                        <p className="mt-4">
                            Toyota SURE uses knowledge and techniques in evaluating used car conditions based on the Toyota
                            Vehicle Inspection System (TVI) which was developed in Japan.  The intensive 203-point
                            inspection is conducted to ensure minimum Quality Assurance because we are committed to putting
                            the highest quality certified vehicles on the road. The Inspection requires that certified
                            Toyota experts complete any necessary repairs and refurbishment before we call the vehicle
                            certified. Our goal is to ensure that every Certified vehicle has that like-new look and feel
                            that you would only expect in a Toyota certified used vehicle.
    
                        </p>
                    </div>
                    <div className="col-md align-self-end">
                        <img src={Vigo} alt="Vigo" className="w-100" />
                    </div>
                </div>
            </div>
        </div>
    
</span>

);
}
}


export default DownBanner;