import React, { Component } from "react";
import Swiper from 'react-id-swiper';
import Modal from 'react-modal';
import Cardetails  from '../../components/car-details/car-details';
import { PostService } from "../../services/GeneralServices";
import Loader from '../General/cardLoader';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

class CardCar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modalIsOpen: false,
            VehicleId:null,
            DealerShipId:null
        }
        //this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selecttoexchangecar = this.selecttoexchangecar.bind(this);
        // this.SelectCarHandler = this.SelectCarHandler.bind(this);
    }
    openModal(item) {
         ;
        this.setState({modalIsOpen: true, VehicleId : item.car.VehicleID,DealerShipId : item.car.DealershipStationID});
      }
     
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
      }
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }
    componentDidMount(){
        this.setState({ data : this.props.Data })
    }
    SelectCarHandler(item,index)
    {
         ;
        this.props.Handler(index) 
        //alert(this.state.VehicleId);
       
      //  this.setState({modalIsOpen: false});



    //   this.setState({ VehicleId : item.car.VehicleID,DealerShipId : item.car.DealershipStationID},()=>{
    //     toast.success("Car is selected", {
    //         position: toast.POSITION.TOP_RIGHT
    //       });
    //         this.props.TabFunc("v-pills-inspect-tab");
    //         this.props.NewCarEnquiryHandler(this.state.VehicleId, "false",this.state.DealerShipId,item.car.ModelID,item.car.VariantID);
    //     })
        
       
    
        
      

    }
    selecttoexchangecar(data)
    {
         ;
           this.setState({ VehicleId : data.VehicleID,DealerShipId : data.DealershipStationID},()=>{
        toast.success("Car is selected", {
            position: toast.POSITION.TOP_RIGHT
          });
            this.props.TabFunc("v-pills-inspect-tab");
            this.props.NewCarEnquiryHandler(this.state.VehicleId, "false",this.state.DealerShipId,data.ModelID,data.VariantID);
        })
        
    }
    render() {
         ;        
        //console.log("cardcar Props",this.props.Data)
        if( this.props.Data == undefined || this.props.Data == null || this.props.Data == ''){
            return <Loader timer={true} CarCard={this.props.Data}/>
        }
        const params = {

            pagination: '.swiper-pagination',
            slidesPerView:this.props.CheckLocation == "exchange"? 2: 4,
            spaceBetween: this.props.CheckLocation == "exchange"? 15: 5,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },    breakpoints: {
				360: {
				slidesPerView: 1,
				spaceBetween: 20
				},
				1024:{
                    slidesPerView:this.props.CheckLocation == "exchange"? 2: 4,
                    spaceBetween: this.props.CheckLocation == "exchange"? 15: 5,
				}
			},
            

        };
        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)'
            }
          }; 
        return (
       <React.Fragment>
            { this.props.Data.length < 3 ?     <div className="tys-related" >
            {/* <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   style={customStyles}
                   contentLabel="Example Modal"
                 > */}
         <div>
             {/* <button onClick={this.closeModal}>close</button> */}
             {/* <button onClick={this.SelectCarHandler.bind(this)} >Select this Car</button> */}
             </div>      
                 {/* </Modal> */}
          
           <div className="related-slider" >
               <div className="swiper-container" id="tysRelated">
                   <div className="swiper-wrapper tys-cars-list tys-card-wrapper">
                   
                   <div  className="form-group">
                       {this.props.Data.map((item, index) => {
                            ;
               return (
                
                  
                       
                       <div key={index} className="" style={{marginLeft:"10px"}}>
                           <div className="card tys-card tys-clist-card" onClick={this.props.CheckLocation == "exchange"? this.SelectCarHandler.bind(this,item,index) : ()=>{ return false;}}>
                               <a  className="tys-card-link">
                                  {this.props.CheckLocation ? '' : <div className="tys-quickview">
                                       <span style={{cursor:'pointer'}} className="tysCompareLink "><i className="fas fa-exchange-alt"
                                           aria-hidden="true" onClick={()=>this.props.CompareHandle(item)}></i></span>
                                   </div>}
                                   {/* <div className="tys-quickview">
                                       <span className="tysQuickViewLink"><i className="fas fa-eye"></i></span>
                                       <span className="tysCompareLink "><i className="fas fa-exchange-alt"
                                           aria-hidden="true" onClick={()=>this.props.CompareHandle(item)}></i></span>
                                   </div> */}


                                   <img className="card-img-top" 
                                   src={item.images.FrontImageName}
                                   style={{ height: "200px", width: "250px" }}
                                       alt="Card image cap" />
                                   <span className="tys--tc-price tys-tile-hide">
                                       RS <strong><NumberFormat value={item.car.RetailPrice}  displayType={'text'} thousandSeparator={true}/></strong>
                                   </span>

                               </a>
                               <div className="card-body">
                                   {/* <span className="tys--tc-discount tys-tile-hide"><strong>10%off</strong></span> */}
                                   <h5 className="card-title">{item.car.ModelName} {item.car.VariantName}</h5>
                                   <span className="card-location"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                       {item.car.DealershipStationCityName} , Toyota
                                       Certified Dealer</span>
                               </div>
                               <div className="car-props tys-tile-hide">
                                   <p className="card-text">


                                       <span>{item.car.ModelYear}</span>
                                       <span>{item.car.Transmission}</span>
                                       <span> {item.car.DealershipStationCityName}</span>
                                       <span>   {item.car.Mileage} KM</span>
                                       {/* <span>7/10</span> */}
                                   </p>
                               </div>
                           </div>
                       </div>
                 

               );
           })}  </div>
                   </div>

               </div>
           </div>
       </div>

     :   <div className="tys-related" >
                 {/* <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      > */}
              <div>
                  {/* <button onClick={this.closeModal}>close</button> */}
                  {/* <button onClick={this.SelectCarHandler.bind(this)} >Select this Car</button> */}
                  </div>      
                      {/* </Modal> */}
               
                <div className="related-slider" >
                    <div className="swiper-container" id="tysRelated">
                        <div className="swiper-wrapper tys-cars-list tys-card-wrapper">
                        
                            <Swiper {...params}>
                            {this.props.Data.map((item, index) => {
                    return (
                     
                        <div key={index} className="swiper-slide">
                            
                            <div className="card-col">
                                <div className="card tys-card tys-clist-card" onClick={this.props.CheckLocation == "exchange"? this.SelectCarHandler.bind(this,item,index) : ()=>{ return false;}}>
                                    <a  className="tys-card-link">
                                       {this.props.CheckLocation ? '' : <div className="tys-quickview">
                                            <span style={{cursor:'pointer'}} className="tysCompareLink "><i className="fas fa-exchange-alt"
                                                aria-hidden="true" onClick={()=>this.props.CompareHandle(item)}></i></span>
                                        </div>}
                                        {/* <div className="tys-quickview">
                                            <span className="tysQuickViewLink"><i className="fas fa-eye"></i></span>
                                            <span className="tysCompareLink "><i className="fas fa-exchange-alt"
                                                aria-hidden="true" onClick={()=>this.props.CompareHandle(item)}></i></span>
                                        </div> */}


                                        <img className="card-img-top" 
                                        src={item.images.FrontImageName}
                                        style={{ height: "200px", width: "250px" }}
                                            alt="Card image cap" />
                                        <span className="tys--tc-price tys-tile-hide">
                                            RS <strong><NumberFormat value={item.car.RetailPrice}  displayType={'text'} thousandSeparator={true}/></strong>
                                        </span>

                                    </a>
                                    <div className="card-body">
                                        {/* <span className="tys--tc-discount tys-tile-hide"><strong>10%off</strong></span> */}
                                        <h5 className="card-title">{item.car.ModelName} {item.car.VariantName}</h5>
                                        <span className="card-location"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                            {item.car.DealershipStationCityName} , Toyota
                                            Certified Dealer</span>
                                    </div>
                                    <div className="car-props tys-tile-hide">
                                        <p className="card-text">


                                        <span>{item.car.ModelYear}</span>
                                       <span>{item.car.Transmission}</span>
                                       <span> {item.car.DealershipStationCityName}</span>
                                       <span>   {item.car.Mileage} KM</span>
                                       {/* <span>7/10</span> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    );
                })}
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>

}
          
</React.Fragment>
        


        );
    }
}
export default CardCar;
