import React, { Component } from 'react'

export default class FaqSubject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab : "",
            arr_Subject : [],
            subid : [],
            
        }


    }



    renderQuestions(){

        let {arr_Subject , subid} = this.state;
        this.props.Subject.map(item =>{
            if(!subid.includes(item.SubjectID)){
                arr_Subject.push(item);
                subid.push(item.SubjectID);
            }
        })


        return arr_Subject ? arr_Subject.map( (Items , index) => (
            
            <div key={index} className={this.state.selectedTab == Items.FAQSubjectId ? 'tab-pane fade active show' : 'tab-pane fade'} id={Items.FAQSubjectId} role="tabpanel" aria-labelledby="v-pills-buy-tab">

            
            
             </div>

          )) : ''

        
    }

    renderSubject(e){

        let {arr_Subject , subid} = this.state;
        this.props.Subject.map(item =>{
            if(!subid.includes(item.SubjectID)){
                arr_Subject.push(item);
                subid.push(item.SubjectID);
            }
        })
       

       return arr_Subject ? arr_Subject.map( (Items , index) => (
                 
            <a key={index} className={this.props.selectedTab == Items.FAQSubjectId ? 'nav-link active' : 'nav-link'} 
              id={Items.FAQSubjectId}
              onClick={this.props.Handler} 
              data-toggle="pill" 
              role="tab" 
              aria-controls="v-pills-general" 
              aria-selected="true">{Items.FAQsSubject}</a>

          )) : ''

    }


    render() {
        
        

        
        
        if (this.props == "" || this.props == undefined || this.props == null) {
            return <div></div>;
        }
        return (
            <div className="nav flex-column nav-pills onnav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            
            {this.renderSubject()}
            </div>




        );
    }
}



