import React, { Component } from "react";

import { PostService, GetService  } from '../../services/GeneralServices';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Car1 from '../../assets/images/carpage/quick_car.png';
import placeholder2 from '../../assets/images/placeholder123.png';
import placeholder3 from '../../assets/images/placeholder3.png';
import img1 from '../../assets/images/cars.svg'
import placeholder4q from '../../assets/images/placeholder4q.png';
import placeholder5q from '../../assets/images/placeholder5q.png';



class quickviewoffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
           data : {
            Name: '',
            phoneNumber: '',
            offer: '',
           },
            isredirect: false,
            slide_id : "0"
        }

        //this.bind = this.slideChange.bind(this);
    }
    

    slideChange(e){
        //
        //console.log('slider id',e.target.id);
        this.setState({
            slide_id : e.target.id
        })
    }
handleChange = ({ currentTarget: input }) => {
    // 
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    console.log(input)
    const data = { ...this.state.data };
    data[input.name] = input.value;
  
    this.setState({ data });
  };

  async onSubmit(e) {
    
    ;

   
    var model = {
      Sno: null,
      Source: "Website",
      Remarks: "Tsure Enquiry" ,
      CustomerPurposeOfVisit: 'P',
      CustomerInterestLevel: 'o',
      CustomerName: this.state.data.Name,
      CustomerMobile: this.state.data.phoneNumber,
      CustomerMobile2: null,
      CurrentVehExpectedPrice: null,
      NextVehExpectedPrice: this.state.data.offer,
      NextVehSellingPrice: null,
      IsNextUsedVehicleUnavailable: null,
      City: null,
      Mileage:null,
      Fuel_type: null,
      customerAddress:null,
      Dealer_Locator : null,
     DealershipStationID: this.props.DealershipStationID ,

      CurrentVehicle: {
        MakeID: null,
        ModelID: null,
        VaraintID: null,
        Year: null,
        RegNum: null,
        EngineNum: null,
        ChasisNum: null,
        Mileage: null,
        Color: null,
        IsInspected: false,
        HasArived: false,
        FinalPurchasePrice: 0,
        RetailPrice: 0,
        InspectionOneSummary: {
          InspectionDate: null,
          ExteriorScore: null,
          InteriorScore: null,
          EngineScore: null,
          FrameScore: null,
          TotalScore: null,
          QualityGrade: null,
          Remarks: null,
          VehicleImages: {
            HoodImageName: null,
            FrontImageName: null,
            BackImageName: null,
            InteriorImageName: null,
            LeftImageName: null,
            RightImageName: null,
            OtherImageName: null
          }
        },
        InspectionTwoSummary: {
          InspectionDate: null,
          ExteriorScore: null,
          InteriorScore: null,
          EngineScore: null,
          FrameScore: null,
          TotalScore: null,
          QualityGrade: null,
          Remarks: null,
          VehicleImages: {
            HoodImageName: null,
            FrontImageName: null,
            BackImageName: null,
            InteriorImageName: null,
            LeftImageName: null,
            RightImageName: null,
            OtherImageName: null
          }
        },

        ArivalOfVehicle: {
          DateOfArival: null,
          Remarks: null
        },
        Refurbishments: {
          ReFubDataSheet: null,
          ReFubDataSheetName: null
        }
      },

      NextUsedVehicle: {
       VehicleID: this.props.VehicleID,
        UsedCarModel: null,
        UsedCarVaraint: null,
        UsedCarRegCity: null,
        UsedCarColor: null,
        UsedCarMake: null,
        UsedCarCity: null,
      },

      UnavailableUsedVehicle: {
        Make: null,
        Model: null,
        Variant: null,
        Year: null,
        Color: null
      },
      NextNewVehicle: {
        MakeID: null,
        ModelID: null,
        VariantID: null,
        Year: null,
        Color: null,
        PBONum: null
      },

      EnquiryStatus: {
        Status: "W",
        DealFollowupData: {
          date: null,
          Reason: null
        },
        DealLostData: {
          Reason: null
        },
        DealDoneData: {
          NumberOfKeys: null,
          HasWarrentyBook: null,
          HasOwnerManual: null,
          HasSaleInvoice: null,
          HasRegistrationBook: null,
          HasRunningPage: null,
          IsCPLCClear: null,
          HasJackedNRod: null,
          HasToolkit: null,
          HasSpareWheel: null,
          HasNumPlate: null,
          HasChasisIdPlate: null
        }
      }
    };
    console.log(model)

    

   
    if (this.state.data.Name !== "" && this.state.data.Name !== undefined && 
    this.state.data.phoneNumber !== "" && this.state.data.phoneNumber !== undefined && this.state.data.phoneNumber.length == 11
    ) {
       

        if(!this.props.isTestdrive ){
            var _Offer = parseFloat(this.state.data.offer.replace(/,/g, ''))
            if( parseInt(_Offer)< 100000 || this.state.data.offer == "" || this.state.data.offer == undefined   || this.state.data.offer.charAt(0) == 0 ){
                toast.warn(`invalid offer`, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                return;
            }
        }
        

        
        await PostService('api/enquiry/new', model)
     .then( response => {
      console.log('response get---', response);

      if (response.status == 200) {
        toast.success("Enquiry submit successfully", {
          position: toast.POSITION.TOP_RIGHT
        })

         //will redirect to the thankyou Tab
         this.props.onClickfunc('tysThankUPanel', this.state.data.offer);
         //will redirect to the thankyou Tab

        
        
        

      } else {
        toast.warn(`${response.statusText} -- ${response.status}`, {
          position: toast.POSITION.TOP_RIGHT
        })

      }

     })
     .catch( err => console.log(err));

    }else{
        if( this.state.data.phoneNumber.length !== 11){
            toast.warn(`Incorrect phone number`, {
                position: toast.POSITION.TOP_RIGHT
              })
        }
        else {
            toast.warn(`Missing feilds...`, {
                position: toast.POSITION.TOP_RIGHT
              })
        }
     

    }

     
    
   


  }
    render() {
        
    //console.log('this.state=====',this.state)
    console.log('this.props=====',this.props);
        return (
            <React.Fragment>


                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <span className="tys-offer-back fa fa-angle-left" onClick={this.props.onClickfunc} id="tysQuickViewPanel" ></span>
                            <h2>{this.props.cardata.car.ModelName}   {this.props.cardata.car.VariantName}</h2>
                            <span className="cd-detail-price">Demand: <NumberFormat value={this.props.cardata.car.RetailPrice}  displayType={'text'} thousandSeparator={true}  /></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column h-100 justify-content-lg-around text-right">
                            <span className="cd-detail-add">
                                {/* Ad ID:{this.props.cardata.car.VehicleID} */}
                        </span>
                            <span className="cd-detail-address"><i className="icon-location"></i> Karachi, Toyota Certified Dealer</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="tys-miniSlider">
                            {/* <!-- Mini Image Slider --> */}
                            <div id="offerSlider" className="carousel slide" data-ride="carousel" data-interval="false">
                                <ol className="carousel-indicators mb-0">
                                    <li data-target="#offerSlider" data-slide-to="0" className={this.state.slide_id == "0" ? 'active' : ''} id="0" onClick={this.slideChange.bind(this)}></li>
                                    <li data-target="#offerSlider" data-slide-to="1" className={this.state.slide_id == "1" ? 'active' : ''} id="1" onClick={this.slideChange.bind(this)}></li>
                                    <li data-target="#offerSlider" data-slide-to="2" className={this.state.slide_id == "2" ? 'active' : ''} id="2" onClick={this.slideChange.bind(this)}></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className={this.state.slide_id == "0" ? 'carousel-item active' : 'carousel-item'}>
                                        <img className="d-block w-100" src={this.props.cardata.images.FrontImageName} alt="First slide" />
                                    </div>
                                    <div className={this.state.slide_id == "1" ? 'carousel-item active' : 'carousel-item'}>
                                        <img className="d-block w-100" src={this.props.cardata.images.BackImageName} alt="Second slide"/>
                                    </div>
                                    <div className={this.state.slide_id == "2" ? 'carousel-item active' : 'carousel-item'}>
                                        <img className="d-block w-100" src={this.props.cardata.images.HoodImageName} alt="Third slide"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong>Specification</strong>
                        <div className="d-flex tys-spec-tbl">
                            <div className="tys-spec-row">
                                <div>
                                    <span>Year</span>
                                     <strong>{this.props.cardata.car.ModelYear}</strong>
                                </div>
                                <div>
                                    <span>Mileage</span>
                                    <strong>{this.props.cardata.car.Mileage}</strong>
                                </div>
                            </div>
                            <div className="tys-spec-row">
                                <div>
                                    <span>Color</span>
                                    <strong>{this.props.cardata.car.Color}</strong>
                                </div>
                                <div>
                                    <span>Transmission</span>
                                    <strong>{this.props.cardata.car.Transmission}</strong>
                                </div>
                            </div>
                            <div className="tys-spec-row">
                                <div>
                                    <span>RegNum</span>
                                    <strong>{this.props.cardata.car.RegNum}</strong>
                                </div>
                                <div>
                                    <span>Model</span>
                                    <strong>{this.props.cardata.car.ModelName}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="tys-wizard-content">
                         {!this.props.isTestdrive ?  <h2 className="wizard-title text-left pb-3 mb-0">Make Offer</h2> :
                          <h2 className="wizard-title text-left pb-3 mb-0">Schedule a Test Drive</h2>}
                           

                            <div className="wizard-form dd-border tys-select-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="userName">Name</label>
                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control" id="Name" onChange={this.handleChange} placeholder="Enter Name" pattern="[a-zA-Z\s]" name="Name" />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fa fa-user-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="userNum">Phone Number</label>
                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control" id="phoneNumber" onChange={this.handleChange} placeholder="Enter Phone Number" pattern="[0-9]{11}"  name="phoneNumber" required/>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fa fa-mobile" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">Please fill out proper number.</div>
                                        </div>
                                    </div>

                                    {!this.props.isTestdrive? <div className="col-md-12">
                                    <label htmlFor="">Offer</label>
                                    <div className="input-group mb-2">
                                    <NumberFormat className="form-control" displayType={'input'} thousandSeparator={true}  placeholder="Your Offer"  onKeyUp={this.handleChange}  name="offer" required />
                                        {/* <input type="text"  /> */}
                                        <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                <i className="dd-icon icon-cash" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                                    
                                    {/* <div className="col-md-6">
                                        <label htmlFor="">Offer</label>
                                        <div className="align-items-center d-flex">
                                            <select className="custom-select">
                                                <option selected value="">PKR 18,000,000</option>
                                            </select>
                                            <i className="dd-icon icon-cash"></i>
                                        </div>
                                    </div> */}

                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="col-md-6 align-self-center">
                                        <button className="btn btn-primary w-75"  onClick={()=>this.onSubmit()} id="tysThankUPanel">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


           



            </React.Fragment>
        );
    }


}
export default quickviewoffer;