import React, { Component } from 'react';
import { GetService } from "../../services/GeneralServices";
import { withRouter, Link , Redirect , } from 'react-router-dom';
import Img from "../../assets/images/vehicle_not_available.jpg";
import Swiper from 'react-id-swiper';
import NumberFormat from 'react-number-format';


class SpecialCar extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			Cars: [] ,
			Redirect: false,
		}
		this.Link = this.Link.bind(this);
	}
	async componentWillMount() {
		const { data } = await GetService('api/vehicle/GetDiscountedCars');
		// ;
		if ( data.Data.ListOfCarsWithImages.length == 0 || data.Data.ListOfCarsWithImages === "" || data.Data.ListOfCarsWithImages === undefined || data.Data.ListOfCarsWithImages === null) {
			this.props.handler();
			//return <div></div>
	   }
		this.setState({ Cars: data.Data.ListOfCarsWithImages })
	}


	Link(index){
		// 
		//console.log('-->' , index);
		return this.props.history.push( { pathname : '/cardetails/'+this.state.Cars[index].car.VehicleID+'/'+this.state.Cars[index].car.DealershipStationID ,
		                               state: { data: this.state.Cars[index] }});
		// return <Redirect to={{ pathname: '/cardetails', state: { data: this.state.Cars[index] } }} />
	}

	renderRedirect = () => {

        if (this.state.Redirect) {

            return this.props.history.push({ pathname: '/car', state: { data: this.state.Cars }})

		}
	}
	render() {
		
		var params1 = {
			slidesPerView: 3,
			spaceBetween: 0,
			showarrows : true ,
			slideToClickedSlide: true,
			allowSlidePrev: true,
			centeredSlides: true,
			loop:true,
			//containerClass: 'tys-specialOffer-slider tys-arrow-minimal hoverArrow ',
			containerClass : 'swiper-container tys-specialOffer-slider tys-arrow-minimal hoverArrow swiper-container-initialized swiper-container-horizontal', 
			nextButton: '.swiper-button-next',
			 prevButton: '.swiper-button-prev',
			 pagination: {
				el: '.swiper-pagination',
		 
				 clickable: false,
				 bullets: false	
			  },
			     navigation: {
					nextEl: '.swiper-button-next.showArrow',
					prevEl: '.swiper-button-prev.showArrow'
			},
			
		};
		
		
			return (
				<React.Fragment>
					
					<div className="tys-so-card tys-card-wrapper mt-5 ">
				
						<div className="row">
							{
							this.state.Cars.slice(0,3).map((car, index) => (
								
								<div className="col-md-4 mb-4 mb-sm-0 pr-md-4 pl-md-4"  key={index} onClick={ () => this.Link(index)}    >
	
									<div className="card tys-card">
										<span className="tys-card-link">
											<img className="card-img-top" src={car.images.FrontImageName} style={{ height: 286, width: 349 }} alt="Card cap" />
										</span>
										<div className="card-body text-center" >
											<span className="tys--tc-price old" style={{display:car.car.DiscountedPrice == null || car.car.DiscountedPrice == "" || car.car.DiscountedPrice == undefined || car.car.DiscountedPrice == 0 ? "none" : "-webkit-inline-box" }}>
											RS <strong className ="black"><NumberFormat value={car.car.RetailPrice} displayType={'text'} thousandSeparator={true}  /></strong>
											</span>
											<div className="d-block">
												<span className="tys--tc-price prsty">
												RS  
												<strong>
												<NumberFormat value={car.car.DiscountedPrice == null || car.car.DiscountedPrice == "" || car.car.DiscountedPrice == undefined || car.car.DiscountedPrice == 0 ? car.car.RetailPrice : car.car.DiscountedPrice } displayType={'text'} thousandSeparator={true}  />
													</strong>
												</span>
											</div>
											<h5 className="card-title black">Toyota 2019</h5>
											<p className="card-text">{car.car.Mileage}|  {car.car.ModelYear}| Automatical| Celestite</p>
										</div> 
								
								 	</div>
							</div> ))}
	                		</div>
					
					</div>
	
	
					<div className="text-center d-inline-block w-100 mt-5" onClick={ () => this.setState({ Redirect : true})}>
						<span className="btn btn-red">
							Load More
				  </span>
					
					</div>
					{this.renderRedirect()}
	
				</React.Fragment>
			)
		

	}
}

export default withRouter(SpecialCar);



