import React, { Component } from 'react';
import Map from './map';

class RenderMap extends Component {
	constructor(props) {
        super(props);
        this.state = {
        
        }
       
    }

	render() {


		  ;
		 	
		var lat = parseFloat(this.props.lat== null ? 24.8169289 : this.props.lat);
		var long = parseFloat(this.props.lon== null ? 67.0776444 : this.props.lon);

		if(this.props.isRefresh){
		 lat = parseFloat(24.9368046);
		 long = parseFloat(67.0406664);
		}

		//console.log(this.props);
		//console.log(long);
		return (

			<React.Fragment>


				{/* <div style={{ position: "absolute", left: "0px", top: 0 }}></div> */}
				<div style={{ backgroundColor: "white", margin: "0px", padding: "0px", borderRadius: "2px" }}>
				{/* <div jstcache="0" style={{}}>
						<div jstcache="117" className="default-card"> <a target="_blank" jstcache="2" href="https://maps.google.com/maps?ll=24.728008,67.124508&amp;z=13&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=embed&amp;q=toyota" jsaction="mouseup:defaultCard.largerMap" className="google-maps-link">View larger map</a> </div>
					</div> */}


					<Map
					
						google={this.props.google}
						//24.8169289,67.0776444
						 center={{ lat: +lat, lng: +long }}
						 data= {{address : this.props.data == undefined ? "" :this.props.data.DealerAddress, name:this.props.data == undefined ? "":  this.props.data.Name, phone : this.props.data == undefined ? "" : this.props.data.PhoneNumber}}
						// center={lat == NaN? lat=24.8169289 : +lat, long == NaN ? long=67.0776444 : +long }
						// center={{lat: 24.8169289, lng: 67.0776444}}
						// center={{lat: 24.8169289, lng: 67.0776444}}
						height={this.props.Heigth}
						zoom={15}
					/>

				</div>

			</React.Fragment>

		);
	}
}

export default RenderMap;