import React, { Component } from "react";
// import AccordianInput from "./AccordianInput";
class LeftbarAccordian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Checked: false,
      checkedin: false
    };
    this.OpenMenu = this.OpenMenu.bind(this);
    // this.changeMaker = this.changeMaker.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  OpenMenu(e) {

     
    this.setState({
      currentTab: e.target.id,
      checkOpen:
        this.state.currentTab === e.target.id && this.state.checkOpen === false
          ? true
          : false
    });
  }

  onchange(event) {

    console.log(event);
    //  this.setState({ checkedin: !this.state.checked });

    this.props.changeHandler({ Name: event.target.id, Value: event.target.value, Checked: event.target.checked, Label: this.props.children });
    if (this.props.children === "Make") {
      this.props.changeMaker(event.target.checked, event.target.value);
    }
    if (this.props.children === "Model") {
      this.props.changeModel(event.target.checked, event.target.value);
    }

  }
  checkfunc() {

    this.setState({ Checked: !this.state.Checked })
  }
  render() {





    return (
      <React.Fragment>
        <div
          className={
            this.state.checkOpen === false
              ? "cl-f-box tys-collapse"
              : "cl-f-box tys-collapse collapsed"
          }
          id={this.props.id}
          onClick={this.OpenMenu}
          data-toggle="collapse"
          data-target="group-model"
        >
          <span className={this.props.icon} />
          <h2>{this.props.children}</h2>
        </div>
        <div
          id="group-model"
          className={
            this.state.checkOpen === false
              ? "list-group collapse in show"
              : "list-group collapse in"
          }
        >
          <span className="list-group-item" href="#">
            {this.props.data !== undefined &&
              this.props.data.map((val, index) => {
                

                return (
                  <div
                    className={this.props.children == "Mileage" || this.props.children == "Price" ? "custom-control custom-radio " : "custom-control"}
                    key={val.Id}>

                    <input
                      style={{ display: "block" }}
                      type={this.props.children == "Mileage" || this.props.children == "Price" ? "radio" : "checkbox"}
                      checked={this.props.SearchFilter.findIndex(

                    element => parseInt(element.Id) === val.Id && this.props.children== "Year" ? parseInt(element.Name) : element.Name  === val.Name
                  ) > -1}
                      onChange={this.onchange}
                    name={
                      this.props.children
                    }
                    className="custom-control-input"
                      id={val.Name}

                    value={val.Id}
                    />
                    <label htmlFor={val.Name} className="custom-control-label">

                      {val.Name}
                    </label>
                    <span className="badge badge-light" style={{ float: "right", display: this.props.children == "Make" || this.props.children == "Model" || this.props.children == "Variant" ? "block" : "none" }}>{val.Count}</span>
                    {/* <span className="badge badge-danger" style={{ float: "right", display: this.props.children == "Mileage" ||this.props.children == "Price"  ? "none" : "block" }}>x</span> */}
                  </div>


                );
              }, this)}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default LeftbarAccordian;
