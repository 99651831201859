import React, { Component } from "react";
import LeftbarAccordian from "./LeftbarAccordion";
import AccoridanforSearch from "./AccoridanforSearch";
import { GetService, PostService } from "../../services/GeneralServices";
import { ToastContainer, toast } from "react-toastify";
import NumberFormat from "react-number-format";
import Loader from "../General/loader";

class Leftbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxPrice: [],
      loader: false,

      TransmissionData: [
        {
          Id: 1,
          Name: "Manual",
        },
        {
          Id: 2,
          Name: "Auto",
        },
      ],
      KM: [
        {
          Id: 1,
          Name: "10,000-25,000",
        },

        {
          Id: 2,
          Name: "25,000-50,000",
        },
        {
          Id: 3,
          Name: "50,000-75,000",
        },
        {
          Id: 4,
          Name: "75,000-100,000",
        },
        {
          Id: 5,
          Name: "100,000-200,000",
        },
      ],
      Price: [
        {
          Id: 1,
          Name: "500,000 - 1000,000",
        },
        {
          Id: 2,
          Name: "1,000,000 - 2,000,000",
        },
        {
          Id: 3,
          Name: "2,000,000-3,000,000",
        },
      ],
      currentTab: "",
      checkOpen: false,
      showDiv: false,
      data: {},
      filterModel: [],
      filterVariant: [],
      SearchFilter: [],
      FilterValues: {
        Make: [],
        Model: [],
        Variant: [],
        Year: [],
        City: [],
        Price: "",
        Registration_City: [],
        Mileage: "",
        Transmission: [],
        SortBy: "",
        FilterFrom: "",
      },

      showpricerange: true,
      showpricedropdown: false,
      pricetext_min: "Select Range",
      pricetext_max: "",
      Range_min: "",
      Range_max: "",
      // priceRange: [{ Id: "1", Name: "500,000 - 1,000,000" }, { Id: "2", Name: "1,000,000 - 1,500,000" }, { Id: "3", Name: "1,500,000 - 2,000,000" }, { Id: "4", Name: "2,000,000 - 2,500,000" }, { Id: "5", Name: "2,500,000 - 3,000,000" }, { Id: "6", Name: "3,500,000 - 4,000,000" } , { Id: "7", Name: "4,500,000 - 4 Millions Plus" }],
      priceRange: [
        { Id: "1", Name: "500,000" },
        { Id: "2", Name: "1,000,000" },
        { Id: "3", Name: "1,500,000" },
        { Id: "4", Name: "2,000,000" },
        { Id: "5", Name: "2,500,000" },
        { Id: "6", Name: "3,500,000" },
        { Id: "7", Name: "4,500,000" },
      ],
    };
    this.changeMaker = this.changeMaker.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.ClearSingleData = this.ClearSingleData.bind(this);
    this.ClearAll = this.ClearAll.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);

    this.selectPriceRange = this.selectPriceRange.bind(this);
    this.selectMAXPriceRange = this.selectMAXPriceRange.bind(this);
    this.showpriceLeft = this.showpriceLeft.bind(this);
    this.showpriceRight = this.showpriceRight.bind(this);
    this.onChangeMin = this.onChangeMin.bind(this);
    this.onChangeMax = this.onChangeMax.bind(this);
  }
  async componentWillMount() {
     ;
    const { data } = await GetService("api/Vehicle/GetDropDown");

    //console.log("Data", data);
    this.setState({ data: data.Data });

    document.addEventListener("mousedown", this.handleclick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleclick, false);
  }
  handleclick = (e) => {
    // if (this.node.contains(e.target)) {
    //     return;
    // }
    //  this.setState({ showpricedropdown: false })
  };

  changeMaker(event, id) {
     ;
    if (event == true) {
      let values = this.state.data.model.filter((val) => {
        return val.MakerID == id;
      });
      this.setState({ filterModel: [...this.state.filterModel, ...values] });
    } else {
      let values = this.state.filterModel.filter((val) => {
        return val.MakerID != id;
      });
      this.setState({ filterModel: values });
    }
  }
  changeModel(event, id) {
     ;
    if (event == true) {
      let values = this.state.data.variant.filter((val) => {
        return val.ModelID == id;
      });
      this.setState({
        filterVariant: [...this.state.filterVariant, ...values],
      });
    } else {
      let values = this.state.filterVariant.filter((val) => {
        return val.ModelID != id;
      });
      this.setState({ filterVariant: values });
    }
  }
  onChangeHandler(value) {
     ;
    const { Name, Value, Checked, Label } = value;
    const values = { ...this.state.FilterValues };

    // if (Label == "") {
    if (Checked == true) {
      if (Label == "Mileage" || Label == "Price") {
        this.state.SearchFilter = this.state.SearchFilter.filter((val) => {
          return Label == "Mileage"
            ? val.Label != "Mileage"
            : val.Label != "Price";
        });
        values[Label] = Name;
      } else {
        values[Label].push({ Id: Value, Name: Name });
      }

      this.state.SearchFilter.push({
        Id: Value,
        Name: Name,
        type: "search",
        Label: Label,
      });
    } else {
      if (Label == "Mileage" || Label == "Price") {
        values[Label] = "";

        this.state.FilterValues[Label] = "";
        this.state.SearchFilter = this.state.SearchFilter.filter((mileage) => {
          return mileage.Label != Label;
        }, this);
        //console.log("mileage", this.state.SearchFilter)
      } else {
        values[Label] = this.state.FilterValues[Label].filter((val) => {
          return val.Id != Value;
        });

        this.state.SearchFilter = this.state.SearchFilter.filter((val) => {
          return val.Name != Name;
        });
      }
    }

    this.setState({ FilterValues: values }, () => {
      this.onSearchHandler();
    });
  }
  async onSearchHandler() {
     ;
    this.setState({
      loader: true,
    });

     ;
    this.state.FilterValues["SortBy"] =
      this.props.Sortby == null || this.props.Sortby == ""
        ? ""
        : this.props.Sortby;
if((window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == "featuredCars")||((window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == "usedcargala"))){
  const { data } = await PostService(
    "api/vehicle/GetFDCars",
    this.state.FilterValues
  );
   ;
  if (data.Data.ListOfCarsWithImages.length > 0) {
    this.setState({ Cars: data.Data.ListOfCarsWithImages }, () => {});

    this.props.FilteredData(data.Data);
  } else {
    this.props.showDivHandler(true)
    this.setState({ Cars: data.Data.ListOfCarsWithImages }, () => {
      toast.warn("No result found!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      this.props.FilteredData(data.Data);
    });
  }
  //console.log("URL**"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
}else{
  const { data } = await PostService(
    "api/vehicle/GetCars",
    this.state.FilterValues
  );
   ;
  if (data.Data.ListOfCarsWithImages.length > 0) {
    this.setState({ Cars: data.Data.ListOfCarsWithImages }, () => {});

    this.props.FilteredData(data.Data);
  } else {
    this.props.showDivHandler(true)
    this.setState({ Cars: data.Data.ListOfCarsWithImages }, () => {
      toast.warn("No result found!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      this.props.FilteredData(data.Data);
    });
  }
}
    

    this.setState({
      loader: false,
    });
  }

  ClearAll() {
    const values = { ...this.state.FilterValues };
    values["Make"] = [];
    values["Model"] = [];
    values["Variant"] = [];
    values["Year"] = [];
    values["City"] = [];
    values["Price"] = "";
    values["Registration_City"] = [];
    values["Mileage"] = "";
    values["Transmission"] = [];
    this.state.SearchFilter = [];
    this.state.filterModel = [];
    this.state.filterVariant = [];
    this.setState({ FilterValues: values }, () => {
      this.onSearchHandler();
    });
  }
  ClearSingleData(id, Label, Name) {
    const values = { ...this.state.FilterValues };
    if (Label == "Mileage" || Label == "Price") {
      values[Label] = "";
    } else {
      values[Label] = this.state.FilterValues[Label].filter((val) => {
        return val.Id != id;
      });
    }

    this.state.SearchFilter = this.state.SearchFilter.filter((val) => {
      return val.Name != Name;
    });
    if (Label == "Make") {
      this.changeMaker(false, id);
    } else if (Label == "Model") {
      this.changeModel(false, id);
    }

    this.setState({ FilterValues: values }, () => {
      this.onSearchHandler();
    });
  }

  selectPriceRange(e) {
    var data = [];
    if (e.target.value == "4,500,000") {
      data.push({ Id: 8, Name: "4 Millions Plus" });
    } else {
      data = this.state.priceRange.filter((x) => {
        return (
          parseInt(x.Name.trim().replace(/,/g, "")) >
          parseInt(e.target.value.replace(/,/g, ""))
        );
      });
      data.push({ Id: 8, Name: "4 Millions Plus" });
      let mix = data.filter((x) => {
        return x.Name !== "4,500,000";
      });
      data = mix;
    }
    const price = e.target.value;
    //console.log(price);

    this.setState(
      {
        Range_min: price,
        showpricerange: false,
        pricetext_min: price,
        maxPrice: data,
      },
      () => {
        // const price = e.target.value;
        // //console.log(price);
        // this.setState({ Range_min: price, showpricerange: false, pricetext_min: price },()=>{
        if (this.state.pricetext_max.length > 0) {
          this.setPriceValue();
        }
      }
    );
  }

  selectMAXPriceRange(e) {
    const price = e.target.value;
    //console.log(price);
    this.setState(
      {
        Range_max: price,
        showpricedropdown: false,
        pricetext_max: ` - ${price}`,
      },
      () => {
        this.setPriceValue();
      }
    );
  }

  showpriceLeft() {
    this.setState({ showpricerange: true });
  }

  showpriceRight() {
    this.setState({ showpricerange: false });
  }

  onChangeMin(value) {
    this.setState(
      {
        Range_min: value,
        pricetext_min: value,
      },
      () => {
        if (this.state.pricetext_max.length > 0) {
          this.setPriceValue();
        }
      }
    );
  }
  onChangeMax(value) {
     ;
    this.setState(
      {
        Range_max: value,
        pricetext_max: ` - ${value}`,
      },
      () => {
        this.setPriceValue();
      }
    );
  }

  setPriceValue() {
     ;
    const values = { ...this.state.FilterValues };

    // if (Label == "") {
    if (
      parseInt(this.state.Range_min.replace(/,/g, "")) <
      parseInt(this.state.Range_max.replace(/,/g, ""))
    ) {
      this.state.SearchFilter = this.state.SearchFilter.filter((val) => {
        return val.Label != "Price";
      });
      values["Price"] = this.state.pricetext_min + this.state.pricetext_max;

      this.state.SearchFilter.push({
        Id: this.state.pricetext_min + this.state.pricetext_max,
        Name: this.state.pricetext_min + this.state.pricetext_max,
        type: "search",
        Label: "Price",
      });
      this.setState({ FilterValues: values }, () => {
        this.onSearchHandler();
      });
    } else {
      toast.warn("Min Price must be less than Max Price", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  renderPrice() {
     ;
    return (
      <div ref={(node) => (this.node = node)}>
        <div
          className={
            this.state.showpricedropdown
              ? "cl-f-box tys-collapse"
              : "cl-f-box tys-collapse collapsed"
          }
          data-toggle="collapse"
          data-target="group-model"
          onClick={() => {
             ;
            this.setState({ showpricedropdown: !this.state.showpricedropdown, showpricerange: true });
          }}
        >
          <span
            className="fas fa-stopwatch cl-f-icon"
            aria-hidden="true"
          ></span>
          <h2>Price</h2>
        </div>
        <div
          id="group-model"
          className={
            this.state.showpricedropdown
              ? "list-group collapse in show"
              : "list-group collapse in"
          }
        >
          {/* <span className="custom-select align-self-center" style={{ cursor: 'pointer', width: '237px' }} onClick={() => { this.setState({ showpricedropdown: true, showpricerange: true }) }} > {`${this.state.pricetext_min}  ${this.state.pricetext_max}`}  </span> */}

          <span className="list-group-item" href="#">
            <div className="custom-control  col-md-12 row">
              <div className="pb-1 pt-1 row padi" style={{ zIndex: "9" }}>
                <div className="col-md-6 p-0 pl-1">
                  <div className="bs-example bs-example-form">
                    {" "}
                    <div className="input-group example">
                      {/* <input type="text" className="form-control form-control-sm m-md-0 p-lg-1 fc" placeholder="Min" value={this.state.Range_min} onFocus={this.showpriceLeft} onChange={e => this.onChangeMin(e.target.value)} /> */}
                      <NumberFormat
                        displayType={"input"}
                        thousandSeparator={true}
                        className="form-control form-control-sm m-md-0 p-lg-1 fc"
                        placeholder="Min"
                        value={this.state.Range_min}
                        onFocus={this.showpriceLeft}
                        onChange={(e) => this.onChangeMin(e.target.value)}
                      />
                      {/* <span className="input-group-addon mt-2 text-body iput" >lacs</span> */}
                    </div>
                  </div>
                </div>
                <div className="border-left col-md-6 p-0">
                  <div className="bs-example bs-example-form">
                    {" "}
                    <div className="input-group m-auto">
                      {/* <input type="text" className="form-control form-control-sm ml-2 mx-lg-1 price" placeholder="Max" value={this.state.Range_max} onFocus={this.showpriceRight} onChange={e => this.onChangeMax(e.target.value)}/> */}
                      <NumberFormat
                        displayType={"input"}
                        thousandSeparator={true}
                        className="form-control form-control-sm ml-2 mx-lg-1 price"
                        placeholder="Max"
                        value={this.state.Range_max}
                        onFocus={this.showpriceRight}
                        onChange={(e) => this.onChangeMax(e.target.value)}
                      />
                    </div>{" "}
                  </div>
                </div>

                <div
                  className={
                    this.state.showpricerange
                      ? "col-md-12 mt-2 pl-1 pt-1 text-dark"
                      : "col-md-12 mt-2 pl-1 pt-1 text-dark box"
                  }
                >
                  {this.state.priceRange.length > 0
                    ? this.state.priceRange.map((item , key) => {
                        return (
                          <option
                          key={key}
                            className="pricedropdown"
                            value={item.Name.trim()}
                            onClick={this.selectPriceRange}
                          >
                            {item.Name.trim()}
                          </option>
                        );
                      })
                    : ""}
                </div>
                <div
                  className={
                    this.state.showpricerange
                      ? "col-md-12 mt-2 pl-1 pt-1 text-dark box"
                      : "col-md-12 mt-2 pl-1 pt-1 text-dark"
                  }
                  style={{ textAlign: "right" }}
                >
                  {this.state.maxPrice.length > 0
                    ? this.state.maxPrice.map((item , key) => {
                        return (
                          <option
                             key={key}
                            className="pricedropdown"
                            value={item.Name.trim()}
                            onClick={this.selectMAXPriceRange}
                          >
                            {item.Name.trim()}
                          </option>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div></div>
            </div>
          </span>
        </div>
      </div>
    );
  }

  showlaoder() {
    return this.state.loader ? <Loader /> : "";
  }
  render() {
     ;
    //console.log(this.state.FilterValues);

    return (
      // <React.Fragment>

      <div className="col-lg-3 col-md-4">
        {/* show loader on sortby */}
        {this.showlaoder()}
        {/* show loader on sortby */}

        <div className="car-list-filter">
          {/* onClick={this.onSearchHandler.bind(this)} */}
          <div className="cl-f-title">
            <h3>Refine Search</h3>
            {/* <div className="cl-count">
                            <span className="icon-searchBar"></span> Result Found
    <span className="badge badge-danger">{this.state.Cars == undefined ? this.props.data.length : this.state.Cars.length}</span>
                        </div> */}
          </div>
          <div className="cl-filters tys-select-wrapper">
            <AccoridanforSearch
              icon={"fas fa-car cl-f-icon"}
              data={this.state.SearchFilter}
              id={"Search"}
              ClearSingleData={this.ClearSingleData}
              ClearAll={this.ClearAll}
              //ClearAll={}
            >
              Search Filter
            </AccoridanforSearch>

            <LeftbarAccordian
              icon={"fas fa-car cl-f-icon"}
              data={this.state.data.maker}
              changeHandler={this.onChangeHandler}
              changeMaker={this.changeMaker}
              id={"dd2"}
              SearchFilter={this.state.SearchFilter}
            >
              Make
            </LeftbarAccordian>
            <LeftbarAccordian
              icon={"fas fa-building cl-f-icon"}
              data={this.state.filterModel}
              changeHandler={this.onChangeHandler}
              changeModel={this.changeModel}
              id={"dd1"}
              SearchFilter={this.state.SearchFilter}
            >
              Model
            </LeftbarAccordian>
            {/* Starting Price tab */}
            <LeftbarAccordian
              icon={"fas fa-building cl-f-icon"}
              data={this.state.filterVariant}
              changeHandler={this.onChangeHandler}
              id={"dd8"}
              SearchFilter={this.state.SearchFilter}
            >
              Variant
            </LeftbarAccordian>

            {this.renderPrice()}
            {/* <LeftbarAccordian id={"dd9"}
                            icon={"fas fa-stopwatch cl-f-icon"}
                            data={this.state.Price}
                            changeHandler={this.onChangeHandler}
                            SearchFilter={this.state.SearchFilter}

                        >
                            Price
            </LeftbarAccordian> */}
            <LeftbarAccordian
              icon={"fas fa-calendar cl-f-icon"}
              data={this.state.data.Year}
              changeHandler={this.onChangeHandler}
              id={"dd3"}
              SearchFilter={this.state.SearchFilter}
            >
              Year
            </LeftbarAccordian>
            <LeftbarAccordian
              icon={"fas fa-city  cl-f-icon"}
              data={this.state.data.Tsurecities}
              changeHandler={this.onChangeHandler}
              id={"dd4"}
              SearchFilter={this.state.SearchFilter}
            >
              City
            </LeftbarAccordian>
            {/* <LeftbarAccordian
                            icon={"fas fa-archway   cl-f-icon"}
                            id={"dd5"}
                            data={this.state.data.cities}
                            changeHandler={this.onChangeHandler}
                            SearchFilter={this.state.SearchFilter}

                        >
                            Registration_City
            </LeftbarAccordian> */}
            <LeftbarAccordian
              id={"dd6"}
              icon={"fas fa-stopwatch cl-f-icon"}
              data={this.state.KM}
              changeHandler={this.onChangeHandler}
              SearchFilter={this.state.SearchFilter}
            >
              Mileage
            </LeftbarAccordian>
            <LeftbarAccordian
              icon={"fas fa-cogs cl-f-icon"}
              id={"dd7"}
              data={this.state.TransmissionData}
              changeHandler={this.onChangeHandler}
              SearchFilter={this.state.SearchFilter}
            >
              Transmission
            </LeftbarAccordian>
          </div>
        </div>
      </div>
    );
  }
}
export default Leftbar;
