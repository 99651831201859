import React from 'react';
import { PostService, GetService  } from '../../services/GeneralServices';
import Form from '../common/form'
import Loader from './loader';

class Sellform extends Form {
    constructor(props) {
        super(props);
        this.state = {
          Fueltype: 'a',
          data: {
            maker: [],
            model: [],
            variant: [],
            cities: [],
            dealershipStation: [],
            Name: '',
            phoneNumber: '',
            Contact_time: '',
            City: '',
            Model: '',
            Make: '',
            Expected_Price: '',
            Year: '',
            Color: '',
            Mileage: '',
            Fuel_type: '',
            InspectionType: "",
            Owner_No: '',
            Dealer_Location:'',
            Customer_Address:'',   
          },
          Model:[]
    
    
        }
        this.Location = this.Location.bind(this)
      }



      async componentDidMount() {
        document.getElementById("newLoader").style.display = 'none';
        
        const { data } = await GetService('api/vehicle/GetDropDown');
        
        console.log("Data", data);
        let data1 = { ...this.state.data };
        data1["maker"] = data.Data.maker;
        data1["model"] = data.Data.model;
        data1["variant"] = data.Data.variant;
        data1["cities"] = data.Data.cities;
        data1["dealershipStation"] = data.Data.dealershipStation;
        this.setState({ data: data1 })
        
      }




Location = (e) => {
  // 
  const value = e.target.value;
  if (value == 'At Home') {
    document.getElementById("AddressDiv").style.display = "block";
    document.getElementById("Dealer_LocationDiv").style.display = "none";
  } else {
    document.getElementById("AddressDiv").style.display = "none";
    document.getElementById("Dealer_LocationDiv").style.display = "block";
  }
  

}
handleChange = ({ currentTarget: input }) => {
  // 
  // const errors = { ...this.state.errors };
  // const errorMessage = this.validateProperty(input);
  // if (errorMessage) errors[input.name] = errorMessage;
  // else delete errors[input.name];
  const data = { ...this.state.data };
  data[input.name] = input.value;

  this.setState({ data });
};
      onSubmit = async (e) => {
        // 
        document.getElementById("newLoader").style.display = "block";
    
        var model = {
          Sno: null,
          Source: "Web",
          Remarks: "Tsure Enquiry" ,
          CustomerPurposeOfVisit: 'S',
          CustomerInterestLevel: 'O',
          CustomerName: this.state.data.Name,
          CustomerMobile: this.state.data.phoneNumber,
          CustomerMobile2: null,
          CurrentVehExpectedPrice: this.state.data.Expected_Price,
          NextVehExpectedPrice: null,
          NextVehSellingPrice: null,
          IsNextUsedVehicleUnavailable: false,
          City: this.state.data.City,
          Mileage: this.state.data.Mileage,
          Fuel_type: this.state.Fueltype,
          customerAddress:this.state.data.Customer_Address,
          Dealer_Locator : this.state.data.Dealer_Location,   
          DealershipStationID : this.state.data.DealerShip,
    
          CurrentVehicle: {
            MakeID: this.state.data.Make,
            ModelID: this.state.data.Model,
            VaraintID: null,
            Year: this.state.data.Year,
            RegNum: "xx",
            EngineNum: "xx",
            ChasisNum: "xx",
            Mileage: this.state.data.Mileage,
            Color: this.state.data.Color,
            IsInspected: false,
            HasArived: false,
            FinalPurchasePrice: 0,
            RetailPrice: 0,
            InspectionOneSummary: {
              InspectionDate: null,
              ExteriorScore: null,
              InteriorScore: null,
              EngineScore: null,
              FrameScore: null,
              TotalScore: null,
              QualityGrade: null,
              Remarks: null,
              VehicleImages: {
                HoodImageName: null,
                FrontImageName: null,
                BackImageName: null,
                InteriorImageName: null,
                LeftImageName: null,
                RightImageName: null,
                OtherImageName: null
              }
            },
            InspectionTwoSummary: {
              InspectionDate: null,
              ExteriorScore: null,
              InteriorScore: null,
              EngineScore: null,
              FrameScore: null,
              TotalScore: null,
              QualityGrade: null,
              Remarks: null,
              VehicleImages: {
                HoodImageName: null,
                FrontImageName: null,
                BackImageName: null,
                InteriorImageName: null,
                LeftImageName: null,
                RightImageName: null,
                OtherImageName: null
              }
            },
    
            ArivalOfVehicle: {
              DateOfArival: null,
              Remarks: null
            },
            Refurbishments: {
              ReFubDataSheet: null,
              ReFubDataSheetName: null
            }
          },
    
          NextUsedVehicle: {
            VehicleID: null,
            UsedCarModel: null,
            UsedCarVaraint: null,
            UsedCarRegCity: null,
            UsedCarColor: null,
            UsedCarMake: null,
            UsedCarCity: null,
          },
    
          UnavailableUsedVehicle: {
            Make: null,
            Model: null,
            Variant: null,
            Year: null,
            Color: null
          },
          NextNewVehicle: {
            MakeID: null,
            ModelID: null,
            VariantID: null,
            Year: null,
            Color: null,
            PBONum: null
          },
    
          EnquiryStatus: {
            Status: "W",
            DealFollowupData: {
              date: null,
              Reason: null
            },
            DealLostData: {
              Reason: null
            },
            DealDoneData: {
              NumberOfKeys: null,
              HasWarrentyBook: null,
              HasOwnerManual: null,
              HasSaleInvoice: null,
              HasRegistrationBook: null,
              HasRunningPage: null,
              IsCPLCClear: null,
              HasJackedNRod: null,
              HasToolkit: null,
              HasSpareWheel: null,
              HasNumPlate: null,
              HasChasisIdPlate: null
            }
          }
        };
        console.log(model)
        await PostService('api/enquiry/new', model).then( data => {
             
        alert(data);
          document.getElementById('Name').value = '';
          document.getElementById('form1').value = '';
          document.getElementsByTagName('form').value = '';
          document.getElementById('phoneNumber').value = '';
          document.getElementById('Contact_time').value = '';
          document.getElementById('Make').value = '';
          document.getElementById('Model').value = '';
          document.getElementById('City').value = '';
          document.getElementById('Expected_Price').value = '';
          document.getElementById('Year').value = '';
          document.getElementById('Color').value = '';
          document.getElementById('Mileage').value = '';
          document.getElementById('Fuel_type').value = '';
          document.getElementById('Owner_No').value = '';
          document.getElementById('InspectionType').value = '';
        })
        .catch( data => {
          
          alert(data);
          document.getElementById("newLoader").style.display = 'none';
        })
        // 
      
    
    
      }

      
  changeMaker = ({ currentTarget: input }) => {

    
    // this.setState({ ...this.state.data  , NewCarModel: input.value  })
    let values = this.state.data.model.filter(val => {

      return val.MakerID == input.value;
    });
    this.setState({ Model: values })
    console.table(values)

  }
  Fuel_type(e){
    // console.log('Tttt',e.target.value)
    // console.log(this.state.Fueltype)
    let fuel = e.target.value;
       this.setState( {  Fueltype : fuel })
  }
	render() {
    

    console.log(this.state.data.cities)
		return(
      <React.Fragment>
      <Loader />
      <form className="was-validated" id="form1" style={{width: "300px" , paddingLeft:"37px" , marginLeft: "486px" }}>
     
     <h3>Personal Info</h3>
      <div className="form-group">
        <label htmlFor="Name"> Name:</label>
        <input type="text" className="form-control" id="Name" pattern="[A-Za-z]{4,}" onChange={this.handleChange} placeholder="Enter Full Name" name="Name" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>
      
      <div className="form-group">
        <label htmlFor="phoneNo">Phone No:</label>
        <input type="text" className="form-control" id="phoneNumber" pattern="[0-9]{11}" onChange={this.handleChange} placeholder="Enter Phone No" name="phoneNumber" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>

      
      {this.renderSelect("City", "City", this.state.data.cities, 'City_Id', 'City_Names')}

     
      <div className="form-group"  style={{  marginTop: 20 }}>
        <label htmlFor="Contact_time">Contact time :</label>
        <input  type="time" className="form-control" id="Contact_time"  onChange={this.handleChange} placeholder="Contact time" name="Contact_time" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>
  
     
     <h3>Car Details</h3>  

     <div className="form-group">
     {this.renderSelect("Make", "Make", this.state.data.maker, 'MakerID', 'Name', this.changeMaker.bind(this))}
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>


      <div className="form-group">
      {this.renderSelect("Model", "Model", this.state.Model, 'ModelID', 'Name')}
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>

     
      <div className="form-group">
        <label htmlFor="Expected_Price">Expected Price:</label>
        <input type="text" className="form-control" id="Expected_Price"  onChange={this.handleChange} placeholder="Expected Price" name="Expected_Price" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>


      <div className="form-group">
        <label htmlFor="Year">Year</label>
        <input type="text" className="form-control" id="Year"  onChange={this.handleChange} placeholder="Year" name="Year" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>


      <div className="form-group">
        <label htmlFor="Color">Color</label>
        <input type="text" className="form-control" id="Color"  onChange={this.handleChange} placeholder="Color" name="Color" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>

      <div className="form-group">
        <label htmlFor="Mileage">Mileage</label>
        <input type="text" className="form-control" id="Mileage"  onChange={this.handleChange} placeholder="Mileage" name="Mileage" required/>
        <div className="valid-feedback">Valid.</div>
        <div className="invalid-feedback">Please fill out this field.</div>
      </div>

      <label htmlFor="FuelType">Fuel Type</label>
          <select className="form-control" onChange={this.Fuel_type.bind(this)} id="Fuel_type" name="Fuel_type">
            <option value="" selected disabled>Please select</option>
            <option value='P' >Petrol</option>
            <option value='D' >Diesel</option>
            <option value='G' >Gas</option>
            <option value='L' >LPG</option>
          </select>
     

      <div className="custom-control custom-checkbox mb-3">
      <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" checked/>
      <label className="custom-control-label" htmlFor="customCheck">Vehicle Inspection</label>
    </div>

     
     
    <select className="form-control" onChange={this.Location} id="InspectionType" name="InspectionType">
            <option value="" selected disabled>Please select</option>
            <option >At Home</option>
            <option>At Dealership</option>
          </select>

          <div className="form-group" id="AddressDiv" style={{ display: 'none' , marginTop: 20 }}>
            <label htmlFor="Address">Address</label>
            <input type="text" className="form-control" id="Address" onChange={this.handleChange} placeholder="Address" name="Address" required />
            <div className="valid-feedback">Valid.</div>
            <div className="invalid-feedback">Please fill out this field.</div>
          </div>

          <div className="form-group" id='Dealer_LocationDiv' style={{ display: 'none', marginTop: 20 }}>
          {this.renderSelect("DealerShip", "DealerShip", this.state.data.dealershipStation, 'DealershipStationID', 'Name' )}

          </div>


      <button type='button'  onClick={this.onSubmit}   style={{ backgroundColor: "#dc3545" , color: "white" , margin : 20 }}>SUBMIT </button>
      </form>
      </React.Fragment>
  
  	);
	}
}

export default Sellform;











































