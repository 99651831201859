import React, { Component } from "react";





class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
  
  
                        // {/* <!-- Place Holder--> */}
                        <div className="col-md-2">
                        <img src="http://placehold.it/160x1200" alt="" className="w-100"/>
                      </div>
                    //   {/* <!-- Place Holder--> */}

);
}
}

export default SideBar;