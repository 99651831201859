import React, { Component } from "react";
import SpecificationCar from "./specificationCar.jsx";
import Image from "../../assets/images/popular_banner.png";
import { GetService } from "../../services/GeneralServices.js";
import Swiper from 'react-id-swiper';

class PopularCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Cars: [],
      SelectedTab: "",
      data: [],
      CitiesArray: [],
      CitiesArray1: []
    };
    this.OpenInfo = this.OpenInfo.bind(this);
  }

  async OpenInfo(event) {
     ;
    this.setState({
      SelectedTab: event === "" ? "City" : event.target.id
    });
    let val = event === "" ? "City" : event.target.id;
    const { data } = await GetService(
      "api/vehicle/GetPopularCars?Filter=" + val
    );
    
    //console.log(data);
    this.setState({
      Cars: data.Data.ListOfCarsWithImages,
      CitiesArray: data.Data.ListOfCarsWithImages
    });
  }
  OnIconActive = e => {
  ;
    let elem = document.getElementById(e.target.id);
    if(elem){
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
      } else {
        elem.classList.add("active");
      }
    }
    var activeYes = "";
    var checkActive = document.getElementsByClassName("tys-icon-city");
    if(checkActive.length>0)
    {
      for(let i = 0 ; i< checkActive.length ;i++)
      {
        if(checkActive[i].classList.contains("active") == true)
        {
          activeYes = true;
        }
      }
    }
    this.state.CitiesArray = this.state.Cars.filter(val => {
      return val.car.DealershipStationCity == e.target.id;
    });

    let Value = this.state.CitiesArray1.filter(val => {
      return val.car.DealershipStationCity == e.target.id;
    });

    if (Value.length == 0) {
      for (let i = 0; i < this.state.CitiesArray.length; i++) {
        
        this.state.CitiesArray1.push({ ...this.state.CitiesArray[i] });
      }
    }
    else {
    this.state.CitiesArray1 = this.state.CitiesArray1.filter(val => {
        return val.car.DealershipStationCity != e.target.id;
      });
    }

    this.setState({ CitiesArray: this.state.CitiesArray1.length == 0 && activeYes != true ? this.state.Cars : this.state.CitiesArray1});
  };
  async componentWillMount() {
    
    this.OpenInfo("");
  }

  filter(e) {
    //console.log(e.target.id);
  }
  render() {
    // 
    const params = {

      pagination: '.swiper-pagination',
      slidesPerView: 6,
      spaceBetween: 0,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
     
      breakpoints: {
				360: {
				slidesPerView: 1,
				spaceBetween: 20
				},
				1024:{
				slidesPerView: 3,
				spaceBetween: 20
				}
			},

  };
    return (
      <div
        className="tys-section tys-popularcar"
        style={{ backgroundImage: `url(${Image})` }}
      >
        <div className="container">
          <div className="text-center">
            <h1 className="tys-title">
              POPULAR <strong>USED CAR</strong>
            </h1>
            <p>
              {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. d,
              similique. */}
            </p>
          </div>
          <div className="tys--pc-action" >
           
            <div className="tab-content" id="nav-tabContent">
              <div
                className={
                  this.state.SelectedTab == "City"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="nav-city"
                role="tabpanel"
                aria-labelledby="nav-city-tab"
              >
                <div className="row mt-4 mb-5">
                  <div className="col-lg-3 align-self-center">
                    <p>
                      <strong>Select the city you searching from?</strong>
                    </p>
                    <span className="mb-3 d-block">*You can select multiple city</span>
                  </div>
                  <div className="col-lg-9">
                  <Swiper {...params}>

                  { 
                  this.props.Data.map((val , id ) => {
                    
                    return (
                      <div className=" custom-Slider-Class"  key={id} >  
                        <a
                          className="toggle-city tys-icon-city"
                          id={val.Id}
                          onClick={this.OnIconActive.bind(this)}
                        >
                          <span
                            className={val.CityImageIcon ? val.CityImageIcon : 'icon-lhr'}
                            id={val.Id}
                          ></span>
                          <span className="city-txt " id={val.City_Names}>
                            {val.Name}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                    </Swiper>
                    </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-price"
                role="tabpanel"
                aria-labelledby="nav-price-tab"
              >
                <p>Price</p>
              </div>
              <div
                className="tab-pane fade"
                id="nav-btype"
                role="tabpanel"
                aria-labelledby="nav-btype-tab"
              >
                <p>Body Type</p>
              </div>
              <div
                className="tab-pane fade"
                id="nav-cmodel"
                role="tabpanel"
                aria-labelledby="nav-cmodel-tab"
              >
                <p>Car Modal</p>
              </div>
              <div
                className="tab-pane fade"
                id="nav-variant"
                role="tabpanel"
                aria-labelledby="nav-variant-tab"
              >
                <p>variant</p>
              </div>
            </div>
          </div>
          <SpecificationCar Data={this.state.CitiesArray} />
        </div>
      </div>
    );
  }
}

export default PopularCar;
