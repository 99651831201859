import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import mapImg from "../../assets/images/dealers/map-view.png";
import Logo from '../../assets/images/dealers/pin2.png';
import Autocomplete from 'react-google-autocomplete';
Geocode.setApiKey("AIzaSyBDZ75fyPMu_S1ywuI0x-kuekSLSS2kCoM");
Geocode.enableDebug();

class Map extends Component {

	constructor(props) {
		super(props);
		this.state = {
			address: '',
			city: '',
			area: '',
			state: '',
			mapPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			markerPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			}
		}
		

	}

	componentDidMount() {
		 ;

		Geocode.fromLatLng(this.props.center.lat, this.props.center.lng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);

				//console.log( 'city', city, area, state );

				this.setState({
					address: (address) ? address : '',
					area: (area) ? area : '',
					city: (city) ? city : '',
					state: (state) ? state : '',
					mapPosition:{
						lat: this.props.center.lat,
						lng: this.props.center.lng,
					}
				})
			},
			error => {
				console.error(error);
			}
		);
	};
	/**
	 * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
	 *
	 * @param nextProps
	 * @param nextState
	 * @return {boolean}
	 */
	shouldComponentUpdate(nextProps, nextState) {
		 ;
		if (
			this.props.center.lat !== this.state.mapPosition.lat ||
			this.state.address !== nextState.address ||
			this.state.city !== nextState.city ||
			this.state.area !== nextState.area ||
			this.state.state !== nextState.state
		) {
			
			return true
		}
		else if (this.props.center.lat === nextProps.center.lat) {
			return false
		}
	}
async	componentWillReceiveProps()
	{
		 ;
	await	Geocode.fromLatLng(this.props.center.lat, this.props.center.lng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);

				//console.log( 'city', city, area, state );

				this.setState({
					address: (address) ? address : '',
					area: (area) ? area : '',
					city: (city) ? city : '',
					state: (state) ? state : '',
					mapPosition:{
						lat: this.props.center.lat,
						lng: this.props.center.lng,
					}
				})
			},
			error => {
				console.error(error);
			}
		);
	}
	/**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getCity = (addressArray) => {

		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	/**
	 * And function for city,state and address input
	 * @param event
	 */
	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	/**
	 * This Event triggers when the marker window is closed
	 *
	 * @param event
	 */
	onInfoWindowClose = (event) => {

	};

	/**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	onMarkerDragEnd = (event) => {

		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				 ;
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: (address) ? address : '',
					area: (area) ? area : '',
					city: (city) ? city : '',
					state: (state) ? state : '',
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
					mapPosition: {
						lat: newLat,
						lng: newLng
					},
				})
			},
			error => {
				console.error(error);
			}
		);
	};

	/**
	 * When the user types an address in the search box
	 * @param place
	 */
	onPlaceSelected = (place) => {
		 ;
		//console.log( 'plc', place );
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		// Set these values in the state.
		this.setState({
			address: (address) ? address : '',
			area: (area) ? area : '',
			city: (city) ? city : '',
			state: (state) ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue
			},
		})
	};


	render() {
		 ;
		const AsyncMap = withScriptjs(
			withGoogleMap(
				props => (
					<GoogleMap width={"100%"}
						defaultZoom={this.props.zoom}
						defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
					>


						<InfoWindow
							onClose={this.onInfoWindowClose}
							position={{ lat: (this.state.mapPosition.lat + 0.0018), lng: this.state.mapPosition.lng }}
						>


							<div className="tys-map-info">
								<img src={mapImg} alt="" />
								<div className="tys-mi-content">
									<span className="tys-mi-title">"{this.props.data.name}"</span>
									<span><i className="fa fa-building-o"></i> {this.props.data.address}</span>
									<span className="align-items-center"><i className="fa fa-phone"></i> {this.props.data.phone}</span>
				<span className="align-items-center"><i className="fa fa-envelope"></i> customer.relations@toyota-indus.com </span>
								</div>

							</div>
						</InfoWindow>

						<Marker google={this.props.google}
							name={'Dolores park'}
							//defaultIcon={Logo}
							icon={Logo}
							draggable={true}
							onDragEnd={this.onMarkerDragEnd}
							
							position={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
						/>
						<Marker />
					</GoogleMap>

				)
			)
		);
		let map;
		if (this.props.center.lat !== undefined) {
			map = <div>

				<div>


				</div>

				<AsyncMap


					googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBDZ75fyPMu_S1ywuI0x-kuekSLSS2kCoM&libraries=places"
					loadingElement={
						<div style={{ height: `100%` }} />
					}
					containerElement={
						<div style={{ height: this.props.height }} />
					}
					mapElement={
						<div style={{ height: `100%` }} />
					}
				/>
			</div>
		} else {
			map = <div style={{ height: this.props.height }} />
		}
		return (map)
	}
}

export default Map;
