import React, { Component } from "react";
import Breadcrum from "../breadcrum";
import EmiCalculator from '../emi/emiCalculator';
// import BGimage1 from '../../assets/images/about/abt-banner_bg.jpg'
// import BGimage2 from '../../assets/images/about/abt-detail_bg.jpg'
// import BGimage3 from '../../assets/images/about/process_bg.jpg'
import Banner from "./banner";
import CertifiedProcess from "./certifiedProcess";
import Improvement from "./improvement";
import DownBanner from "./downBanner";
import SureDifference from "./sureDifference";

class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
     
        <div className="tys-page tys-page- pb-0">
          <Breadcrum Name="ABOUT" />
          <Banner />
          <CertifiedProcess />
          <Improvement />
          <DownBanner />
          <SureDifference />
        </div>
      </React.Fragment>
    );
  }
}


export default About;