import React, { Component } from "react";





class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 


<div className="col-12 tys-more-wrapper pt-5 pb-5">
<div className="row">
    <div className="col-md-2 align-self-center">
        <strong>Checkout these post</strong>
    </div>
    <div className="col-md align-self-center">
        <div className="tys-more d-flex">
            <img src="http://placehold.it/100x80" alt="" />
            <div className="pl-3 d-flex flex-column">
                <small className="text-muted">TITLE NAME PLACEHOLDER</small>
                <strong>IMC’s accelerates the Million Tree Drive </strong>
                <small>These are the limited time offers*</small>
            </div>
        </div>
    </div>

    <div className="col-md align-self-center">
        <div className="tys-more d-flex">
            <img src="http://placehold.it/100x80" alt="" />
            <div className="pl-3 d-flex flex-column">
                <small className="text-muted">TITLE NAME PLACEHOLDER</small>
                <strong>IMC’s accelerates the Million Tree Drive </strong>
                <small>These are the limited time offers*</small>
            </div>
        </div>
    </div>
</div>

</div>


		
);
}
}

export default CheckOut;