import React, { Component } from "react";

import IconEmail from "../../assets/images/icon-email.png";
import IconLocation from "../../assets/images/icon-location.png";
import IconPhone from "../../assets/images/icon-phone.png";
import RenderMap from "../Map/renderMap";
import backgroundImage from "../../assets/images/dealerpage_bg.jpg";
// import DealersList from '../Dealer/DealersList';
import { GetService } from "../../services/GeneralServices.js";
import DealersList from "../Dealer/DealersList";

class Dealer extends Component {
  constructor(props) {
    super(props);
   
    
  }

  state = {
    isredirect : false,
  }


  ispageRedirect(){

    
      console.log(this.state.isredirect);
    if(this.state.isredirect)
    {
        this.setState({
          isredirect : false
        })
    }else{
      this.setState({
        isredirect : true
      })
    }
  }

  //  refreshPage() {
  //   window.location.reload(false);
  // }

  // componentDidMount() {
  //   
  //   setTimeout(() => { window.scrollTo(0, 0); }, 3000);
    
  // }

  render() {
    return (
    <React.Fragment>

        {/* <div className="tys-main" aria-label="Content"> */}
          <div className="tys-dealers" style={{ backgroundImage: `url(${backgroundImage})` }} >

           
           
             <DealersList isRefresh={this.state.isredirect}/>
            
            <div className="container"  >
              <div className="row tys-internal-details">
                <div className="col-8 ml-auto mr-auto">
                  <h1>Need <strong> TSure </strong> Assistance </h1>
                  <p>
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                    has been the industry's standard dummy text ever since the 1500s, */}
      </p>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <div className="dealers-card">
                    <img src={IconEmail} alt="" />
                    <h2>EMAIL US</h2>
                    <p>
                      {/* Lorem Ipsum is simply dummy
           text of the printing. */}
           </p>
                    <a href="mailto:customer.relations@toyota-indus.com" className="btn btn-primary">Email</a>
                  </div>
                </div>


                <div className="col-4">
                  <div className="dealers-card">
                    <img src={IconLocation} alt="" />
                    <h2>Locate US</h2>
                    <p>
                      {/* Lorem Ipsum is simply dummy
           text of the printing. */}
           </p>
                    <a style={{cursor : 'pointer', color: 'white'}} onClick={this.ispageRedirect.bind(this)} className="btn btn-primary">Address</a>
                  </div>
                </div>


                <div className="col-4">
                  <div className="dealers-card">
                    <img src={IconPhone} alt="" />
                    <h2>Need Help</h2>
                    <p>
                      {/* Lorem Ipsum is simply dummy
           text of the printing. */}
           </p>
                    <a href="tel:0800-11123" style={{cursor : 'pointer', color: 'white'}} className="btn btn-primary">Help</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        {/* </div> */}

      </React.Fragment>



    );
  }
}

export default Dealer;





