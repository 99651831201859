import React, { Component } from 'react'
import Breadcrum from "../breadcrum";
import emiImage from '../../assets/images/emi_bg.jpg'
import EmiCalculator from '../emi/emiCalculator';
import img1 from '../../assets/images/cars.svg';

export default class exchangeCar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			check: false,
			selectedTab: "v-pills-personal-tab",
			checkOpen: "v-pills-personal"
		}


	}

	OnOpenTab = (e) => {
		
		console.log('this question', e.target.id)

		const el = e.target;

		this.setState({
			selectedTab: el.id,
			checkOpen: el.id
		})
	}

	// OnOpenTab(e){
	// 	//    
	// 	const id = e.target.id;
	// 	if(id.length > 0) {
	// 	 let a = document.getElementsByClassName("nav-link active");
	// 	 for (let index = 0; index < a.length; index++) {
	// 		if(a[index].classList.toString().indexOf('active') > 0){
	// 			a[index].setAttribute("aria-selected", "false");
	// 			a[index].classList.remove('active');

	// 		 }
	// 	 }

	// 	 let elem =  document.getElementById(id);
	// 	 if(elem){
	// 		elem.classList.add('active');
	// 		elem.setAttribute("aria-selected", "true")
	// 	 }
	// 	 var b = document.getElementsByClassName('tab-pane fade');
	// 	 for (let index = 0; index < b.length; index++) {
	// 		if(b[index].classList.toString().indexOf('active show') > 0){
	// 			b[index].classList.remove('active');
	// 			b[index].classList.remove('show');
	// 		 }
	// 	 }
	// 	 const tabid = id.replace("-tab", "");
	// 	 let tabelem =  document.getElementById(tabid);
	// 	 if(tabelem){
	// 		tabelem.classList.add('active');
	// 		tabelem.classList.add('show');
	// 	 }

	// 	}
	// }


	render() {
		return (
			<React.Fragment>

				<EmiCalculator />
				{/* ------------------------------ */}

				<div className="tys-main" aria-label="Content" >
					<div className="tys-page tys-page-" style={{ backgroundImage: `url(${emiImage})` }}>
						{/* <!--BreadCrumb Start--> */}
						{/* <div className="tys-breadcrumb-bar">
	<div className="container">
		<nav aria-label="breadcrumb">
			<ol className="breadcrumb">
			  <li className="breadcrumb-item"><a href="#">Home</a></li>
			  <li className="breadcrumb-item active text-uppercase" aria-current="page">Exchange a Car</li>
			</ol>
		  </nav>
	</div>
</div> */}
						<Breadcrum Name="EXCHANGE A CAR" />
						{/* <!--BreadCrumb End--> */}
						<div className="container">
							<div className="row">
								<div className="col-lg-8 ml-auto mr-auto">
									<div className="tys-internal-details">
										<h1 className="titleDark">Exchange your car with <strong>tsure</strong></h1>

										<p>
											{/* Calculate the lowest Equated Monthly Installment (EMI) that you require to pay for your favourite Car. Please enter the loan amount that you are applying for. The EMI (Equated Monthly Installment) DOES NOT include any other processing fee or charges that may be applicable as per the rules of financing institutions and banks. This calculation is for illustrative purposes. Check with the dealer for exact monthly payments. Please also see our LEGAL NOTICE */}
											</p>

									</div>
								</div>
							</div>
						</div>


						<div className="tys-emi">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 mb-3">
										{/* <!--EMI Start--> */}
										<div className="tys-wizard-content wizard-steps">
											<div className="row">
												<div className="col-md-2">
													<div className="nav flex-md-column nav-pills wizard-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
														<a className={this.state.selectedTab === "v-pills-personal-tab" ? "nav-link active" : "nav-link"} id="v-pills-personal-tab" data-toggle="pill" href="#v-pills-personal" role="tab" aria-controls="v-pills-personal" aria-selected={this.state.selectedTab === "v-pills-personal-tab" ? 'true' : 'false'} onClick={this.OnOpenTab}>
															<i className="icon-detail-user" onClick={this.OnOpenTab.bind(this)}></i>
															<span className="d-none d-md-block">Personal Details</span>
														</a>
														<a className={this.state.selectedTab === "v-pills-vdetails-tab" ? "nav-link active" : "nav-link"} id="v-pills-vdetails-tab" data-toggle="pill" href="#v-pills-vdetails" role="tab" aria-controls="v-pills-vdetails" aria-selected={this.state.selectedTab === "v-pills-personal-tab" ? 'true' : 'false'} onClick={this.OnOpenTab}>
															<i className="icon-car-o" onClick={this.OnOpenTab.bind(this)}></i>
															<span className="d-none d-md-block">Vehicle Details</span>
														</a>

														<a className={this.state.selectedTab === "v-pills-exchange-tab" ? "nav-link active" : "nav-link"} id="v-pills-exchange-tab" data-toggle="pill" href="#v-pills-exchange" role="tab" aria-controls="v-pills-exchange" aria-selected={this.state.selectedTab === "v-pills-personal-tab" ? 'true' : 'false'} onClick={this.OnOpenTab}>
															<i className="fa fa-exchange" aria-hidden="true" onClick={this.OnOpenTab.bind(this)}></i>

															<span className="d-none d-md-block">Exchange Vehicle</span>
														</a>

														<a className={this.state.selectedTab === "v-pills-inspect-tab" ? "nav-link active" : "nav-link"} id="v-pills-inspect-tab" data-toggle="pill" href="#v-pills-inspect" role="tab" aria-controls="v-pills-inspect" aria-selected={this.state.selectedTab === "v-pills-personal-tab" ? 'true' : 'false'} onClick={this.OnOpenTab}>
															{/* <i className="fa fa-car-battery" aria-hidden="true"></i> */}
															<i className="fas fa-car-battery" aria-hidden="true" onClick={this.OnOpenTab.bind(this)}></i>

															<span className="d-none d-md-block">	Vehicle Inspection </span>
														</a>
													</div>
												</div>
												<div className="col-md-10 pl-xl-0">
													<div className="tab-content wizard-tab-content" id="v-pills-tabContent">
														{/* <div className="tab-pane fade show active" id="v-pills-personal" role="tabpanel" aria-labelledby="v-pills-personal-tab"> */}
														<div className={this.state.selectedTab === "v-pills-personal-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-personal" role="tabpanel" aria-labelledby="v-pills-personal-tab">
															<h2 className="wizard-title text-left">Personal Details</h2>

															<div className="wizard-form dd-border tys-select-wrapper">
																<div className="row">
																	<div className="col-md-12">
																		<label htmlFor="userName">Name</label>
																		<div className="input-group mb-2">
																			<input type="text" className="form-control" id="userName" placeholder="Enter Name" />
																			<div className="input-group-prepend">
																				<div className="input-group-text">
																					<i className="fa fa-user-o" aria-hidden="true"></i>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-12">
																		<label htmlFor="userNum">Phone Number</label>
																		<div className="input-group mb-2">
																			<input type="text" className="form-control" id="userNum" placeholder="Enter Phone Number" />
																			<div className="input-group-prepend">
																				<div className="input-group-text">
																					<i className="fa fa-mobile" aria-hidden="true"></i>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<label htmlFor="">State:</label>
																		<select className="custom-select">
																			<option selected value="">Sindh</option>
																			<option value="1">Balochistan</option>
																		</select>
																	</div>
																	<div className="col-md-6">
																		<label htmlFor="">City:</label>
																		<select className="custom-select">
																			<option selected value="">Karachi</option>
																			<option value="1">Quetta</option>
																		</select>
																	</div>
																	<div className="col-12">
																		<label htmlFor="">Preferred Dealership:</label>
																		<select className="custom-select">
																			<option selected value="">Select your Dealer</option>
																			<option value="1">Dealer 1</option>
																		</select>
																		<hr className="border-0" />
																	</div>
																	<div className="col-md-6">
																		<label htmlFor="userTime">Contact Time</label>
																		<div className="input-group mb-2">
																			<input type="text" className="form-control" id="userTime" placeholder="" value="12PM - 6PM" />
																			<div className="input-group-prepend">
																				<div className="input-group-text">
																					<i className="fa fa-clock-o" aria-hidden="true"></i>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="col-md-6">
																		<label htmlFor="userTime">Day</label>
																		<div className="input-group mb-2">
																			<input type="text" className="form-control" id="userTime" placeholder="" value="Mon- Tue" />
																			<div className="input-group-prepend">
																				<div className="input-group-text">
																					<i className="fa fa-calendar" aria-hidden="true"></i>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row mt-5">
																	<div className="col-md-6 align-self-center">
																		{/* Lorem ipsum dolor sit amet consectetur. */}
							</div>
																	<div className="col-md-6 align-self-center text-right">
																		<button className="btn btn-primary w-75">Next</button>
																	</div>
																</div>
															</div>
														</div>
														{/* <div className="tab-pane fade" id="v-pills-vdetails" role="tabpanel" aria-labelledby="v-pills-vdetails-tab"> */}
														<div className={this.state.selectedTab === "v-pills-vdetails-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-vdetails" role="tabpanel" aria-labelledby="v-pills-vdetails-tab">

															<h2 className="wizard-title text-left">Vehicle Details</h2>

															<div className="wizard-form dd-border tys-select-wrapper">
																<div className="row">
																	<div className="col-md-6">
																		<label htmlFor="">Make:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Toyota</option>
																				<option value="1">Toyota</option>
																			</select>
																			<i className="dd-icon icon-car-mini"></i>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<label htmlFor="">Model:</label>
																		<select className="custom-select">
																			<option selected value="">Karachi</option>
																			<option value="1">Quetta</option>
																		</select>
																	</div>
																	<div className="col-6">
																		<label htmlFor="">Year:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">2018</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon icon-date"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Color:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Black</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon icon-car-color"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Transmission:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Auto</option>
																				<option value="1">Manual</option>
																			</select>
																			<i className="dd-icon fa fa-gears" aria-hidden="true"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Engine:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">1300CC</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon fa fa-car-alt" aria-hidden="true"></i>
																		</div>
																	</div>

																	<div className="col-md-12 mt-3">
																		<label htmlFor="">Expected Price:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">PKR 1,000,000 - 1,200,000</option>
																				<option value="1">PKR 1,000,000 - 1,200,000</option>
																			</select>
																			<i className="dd-icon icon-cash"></i>
																		</div>

																	</div>

																	<div className="col-md-12">
																		<label htmlFor="">Mileage:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">50,000KM - 100,000KM</option>
																				<option value="1">50,000KM - 100,000KM</option>
																			</select>
																			<i className="dd-icon fa fa-road" aria-hidden="true"></i>
																		</div>

																	</div>

																</div>
																<div className="row mt-5">
																	<div className="col-md-6 align-self-center">
																		{/* Lorem ipsum dolor sit amet consectetur. */}
							</div>
																	<div className="col-md-6 align-self-center text-right">
																		<button className="btn btn-primary w-75">Next</button>
																	</div>
																</div>
															</div>
														</div>

														<div className={this.state.selectedTab === "v-pills-exchange-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-exchange" role="tabpanel" aria-labelledby="v-pills-exchange-tab">

															<div className="row">
																<div className="col-md-6">
																	<h2 className="wizard-title text-left">Exchange Vehicle Details</h2>
																</div>
																<div className="col-md-6 pt-4">
																	<div className="d-flex justify-content-end pr-4">
																		<span className="pr-2">NEW</span>
																		<div className="custom-control custom-switch">
																			<input type="checkbox" className="custom-control-input" id="customSwitch1" />
																			<label className="custom-control-label" for="customSwitch1">USED</label>
																		</div>
																	</div>
																</div>
															</div>


															<div className="wizard-form dd-border tys-select-wrapper">
																<div className="row">
																	<div className="col-md-6">
																		<label htmlFor="">Make:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Toyota</option>
																				<option value="1">Toyota</option>
																			</select>
																			<i className="dd-icon icon-car-mini"></i>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<label htmlFor="">Model:</label>
																		<select className="custom-select">
																			<option selected value="">Karachi</option>
																			<option value="1">Quetta</option>
																		</select>
																	</div>
																	<div className="col-6">
																		<label htmlFor="">Year:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">2018</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon icon-date"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Color:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Black</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon icon-car-color"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Transmission:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">Auto</option>
																				<option value="1">Manual</option>
																			</select>
																			<i className="dd-icon fa fa-gears" aria-hidden="true"></i>
																		</div>
																	</div>

																	<div className="col-6">
																		<label htmlFor="">Engine:</label>
																		<div className="align-items-center d-flex">
																			<select className="custom-select">
																				<option selected value="">1300CC</option>
																				<option value="1">Dealer 1</option>
																			</select>
																			<i className="dd-icon fa fa-car-alt" aria-hidden="true"></i>
																		</div>
																	</div>


																</div>
																<div className="row mt-5">
																	<div className="col-md-6 align-self-center">
																		{/* Lorem ipsum dolor sit amet consectetur. */}
							</div>
																	<div className="col-md-6 align-self-center text-right">
																		<button className="btn btn-primary w-75">Next</button>
																	</div>
																</div>
															</div>
														</div>

														<div className={this.state.selectedTab === "v-pills-inspect-tab" ? "tab-pane fade active show" : "tab-pane fade"} id="v-pills-inspect" role="tabpanel" aria-labelledby="v-pills-inspect-tab">
															<h2 className="wizard-title text-left">Vehicle Inspection</h2>

															<div className="wizard-form dd-border tys-select-wrapper">
																<div className="row">
																	<div className="col-md-6">
																		<label htmlFor="">Inspection At Dealership:</label>
																		<select className="custom-select">
																			<option selected value="">Toyota Motor</option>
																			<option value="1">Toyota</option>
																		</select>
																	</div>
																	<div className="col-md-6">
																		<div className="d-flex justify-content-end">
																			<span className="pr-2">HOME</span>
																			<div className="custom-control custom-switch">
																				<input type="checkbox" className="custom-control-input" id="customSwitch1" />
																				<label className="custom-control-label" for="customSwitch1">Dealer</label>
																			</div>
																		</div>
																	</div>

																	<div className="col-12 mt-3 mb-3">
																		<div className="row">
																			<div className="col-md-6">
																				<label htmlFor="userTime">Inspection at home</label>
																				<div className="input-group mb-2">
																					<input type="text" className="form-control sm-txt" id="userTime" placeholder="Enter your address here" value="" />
																					<div className="input-group-prepend">
																						<div className="input-group-text">
																							<i className="fa fa-location-arrow" aria-hidden="true"></i>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 mb-3">
																		<div className="row">
																			<div className="col-md-6">
																				<label htmlFor="userTime" className="mb-2">Upload Images <i className="icon-image"></i>
																					(OPtional)</label>
																				<button type="button" className="btn btn-primary btn-upload"><i className="fa fa-plus" aria-hidden="true"></i></button>
																			</div>
																		</div>
																	</div>

																	<div className="col-12 mb-3">
																		<div className="row">
																			<div className="col-md-6">
																				<label htmlFor="userTime" className="mb-2">Upload Video <i className="icon-film"></i>
																					(OPtional)</label>
																				<button type="button" className="btn btn-primary btn-upload"><i className="fa fa-plus" aria-hidden="true"></i></button>
																			</div>
																		</div>
																	</div>



																</div>
																<div className="row mt-5">
																	<div className="col-md-6 align-self-center">
																		{/* Lorem ipsum dolor sit amet consectetur. */}
							</div>
																	<div className="col-md-6 align-self-center text-right">
																		<button className="btn btn-primary w-75">Finish</button>
																	</div>
																</div>
																<div className="tab-pane fade" id="v-pills-thanks" role="tabpanel" aria-labelledby="v-pills-thanks-tab">
																	<div className="tys-thankyou-panel">
																		<img src={img1} alt="" />
																		<h1>THANK YOU!</h1>
																		<p>
																			Your request have been submitted
																						</p>
																		<div className="tys-tp-btn">
																			<span className="btn btn-primary">View more car</span>
																		</div>
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>


										</div>
										{/* <!--EMI End--> */}
									</div>
									<div className="col-lg-4">
										<img src="http://placehold.it/265x250" alt="" className="w-100 mb-4" />

										<div className="seller-guide mb-4">

											{/* <!-- Swiper --> */}
											<div className="swiper-container swiper-container-initialized swiper-container-horizontal" id="tysSeller">
												<div className="swiper-wrapper">
													<div className="swiper-slide swiper-slide-active" style={{ width: 328 }}>
														<span className="title">
															SELLER GUIDE
                </span>
														<p>
															First of all establish a proper value based on your local automotive market that you are expecting from your
															used car. Used cars classified in local newspapers and the used cars dealers estimates and expert judgments
															can help in determining the true value for your used cars.
                </p>
													</div>
													<div className="swiper-slide swiper-slide-next" style={{ width: 328 }}>
														<span className="title">
															SELLER GUIDE
                </span>
														<p>
															First of all establish a proper value based on your local automotive market that you are expecting from your
															used car. Used cars classified in local newspapers and the used cars dealers estimates and expert judgments
															can help in determining the true value for your used cars.
                </p>
													</div>
												</div>

												<span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>


											{/* <!-- <span className="title">
            SELLER GUIDE
          </span>
          <p>
            First of all establish a proper value based on your local automotive market that you are expecting from your
            used car. Used cars classified in local newspapers and the used cars dealers estimates and expert judgments
            can help in determining the true value for your used cars.
          </p> --> */}
											<div className="seller-arrows">
												{/* <!--  */}
												{/* <i className="fa fa-caret-left"></i> */}
												{/* <i className="fa fa-caret-right"></i> */}
												{/* --> */}
												{/* <!-- Add Arrows --> */}
												<div className="swiper-button-prev swiper-button-disabled" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="true"></div>
												<div className="swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false"></div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</React.Fragment>
		)
	}
}