import React, { Component } from 'react';
import Swiper from 'react-id-swiper';


import Layer1 from '../../assets/images/bank/Layer-1.png';
import Layer2 from '../../assets/images/bank/Layer-2.png';
import Layer3 from '../../assets/images/bank/Layer-3.png';
import Layer4 from '../../assets/images/bank/Layer-4.png';
import Layer5 from '../../assets/images/bank/Layer-5.png';
import Layer6 from '../../assets/images/bank/Layer 6.png';
import Layerbtn from '../../assets/images/emi-panel.png';
import emi from '../../assets/images/SideBar.png';



export default class EmiCalculator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Check: false,
			
		}
	}	
	render() {
        var params = {
            pagination: '.swiper-pagination',
            slidesPerView: 6,
            paginationClickable: true,
            spaceBetween: 15,
			paginationClickable: true,
			loop:true,
			containerClass:"swiper-container wizard-slider swiper-container-initialized swiper-container-horizontal" ,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
          };
		return (
			<React.Fragment>
				<div id="emiPanelBtn" className="sts-emi-icon" >
					{/* <img src={emi} alt="" onClick={() => {
						
						this.setState({ Check: true })
					}} className="d-none d-lg-block" /> */}
					{/* <span className="icon-forms icon-mob d-lg-none d-block"></span> */}
				{/*---------------top-------------------- */}
				 {/* <div id="topBtn">
				 	<a href="#"><button className="topbtn">TOP</button></a>
				 </div>  */}
				  {/* --------------------- */}
				     {/* <TopButton /> */}
                  {/* --------------------- */}

				{/* ----------------end------------------- */}

				
				</div>
				
				<div className={this.state.Check === true ? "tys-emi-panel show" : "tys-emi-panel"} id="emiPanel">
					<span id="emiPanelClose"
					onClick={() => {
						
						this.setState({ Check: false })
					}} className="tys-emiPanelClose">
						
						<i className="fa fa-caret-right" aria-hidden="true"></i>
					</span>
					{/* <!--EMI Start--> */}
					<div className="tys-wizard-content">
						<h2>Calculate your loan</h2>
						<div className="tys-bank-slider" id ="emislider">
							<label htmlFor="">Select Bank</label>
							{/* <!-- Swiper --> */}



							<Swiper {...params} >
                           <div className="swiper-slide" >
                               <img src={Layer1} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Layer2} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Layer3} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Layer4} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Layer5} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Layer6} alt=""/> 
                              </div>
                             
                                     
                  </Swiper>






											<div className="tys-terms">
								<span>*Term &amp; Conditions applys as per your selected bank</span>
							</div>
						</div>
						<div className="wizard-form tys-select-wrapper">
							<hr />
							<div className="row">
								<div className="col-md-6">
									<label htmlFor="">Rate of Interest (%) :</label>
									<input  type ="text" placeholder="5% - 25%">
										{/* <label html="for"> 5%</label> */}
									</input>
								</div>
								<div className="col-md-6">
									<label htmlFor="">Tenure:</label>
									<select className="custom-select">
										<option  value="1" >12 MONTHS</option>
										<option  value="1" >24 MONTHS</option>
										<option  value="1" >36 MONTHS</option>
										<option value="1">48 MONTHS</option>
										<option  value="1" >60 MONTHS</option>
									</select>
								</div>
								<div className="col-6">
									<label htmlFor="">Loan Amount</label>
									<input  type ="text" placeholder="5,111,089"name="title">
										{/* <option  value="1">PKR 10,0000 - 20,0000</option>
										<option value="1">PKR 10,0000 - 20,0000</option> */}
									</input>
									{/* <hr /> */}
								</div>
								{/* <div className="col-md-6">
									<label htmlFor="">Rate of Interest (%) :</label>
									<select className="custom-select">
										<option  value="1">16% - 25% </option>
										<option value="1">16% - 25% </option>
									</select>
								</div> */}
								<div className="col-md-6 align-self-center text-center">
									<button className="btn btn-primary w-75">Calculate</button>
								</div>
							</div>
						</div>
						<div className="tys-wizard-total">
							<div>
								<span>
									Your <strong>EMI amount</strong> for the tenure of <strong>12 months</strong>
								</span>
							</div>
							<div>
								<span className="wizard-price"><strong> Rs  1,417,014</strong> per month</span>
							</div>
						</div>
					</div>
					{/* <!--EMI End--> */}
				</div>
			</React.Fragment>

		)
	}
}


































