import React, { Component } from "react";
import RenderMap from '../Map/renderMap'
import { withRouter, Link } from 'react-router-dom';
import Layer1 from '../../assets/images/bank/Layer-1.png';
import Layer2 from '../../assets/images/bank/Layer-2.png';
import Layer3 from '../../assets/images/bank/Layer-3.png';
import Layer4 from '../../assets/images/bank/Layer-4.png';
import Layer5 from '../../assets/images/bank/Layer-5.png';
import Layer6 from '../../assets/images/bank/Layer 6.png';
import Layerbtn from '../../assets/images/emi-panel.png';
import Quickviewoffer from '../cars/quickviewoffer';
import Swiper from 'react-id-swiper';
import Popup from "reactjs-popup";
import Car1 from '../../assets/images/carpage/quick_car.png';
import placeholder2 from '../../assets/images/placeholder123.png';
import placeholder1 from '../../assets/images/placeone.png';
import placeholder3 from '../../assets/images/placeholder3.png';
import img1 from '../../assets/images/cars.svg'
import placeholder4q from '../../assets/images/placeholder4q.png';
import placeholder5q from '../../assets/images/placeholder5q.png';
import NumberFormat from 'react-number-format';
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from "react-share";

class QucikView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Check: false,
            alpha: false,
            visible: false,
            TabCheck: true,
            // ---------for thumbnail----------------
            DealersCar: [],
            check: true,
            car: [],
            Image: "",
            // ------------------------------------------
            quickViewTab_id: 'tysQuickViewPanel',
            isTestdrive: false,
            makeOffer: "0",
            CurrentImage: ""
        }

        this.onNext = this.onNext.bind(this)
        this.onPrevious1 = this.onPrevious1.bind(this)


    }



    componentDidUpdate(prevProps, prevState) {

        //console.log(this.props.Check)
        //       if (this.props.Check == true) {
        if (prevState && prevState.Check !== this.props.Check) {
            document.getElementById('tysQuickView').className += " show";

            document.getElementById('root').className += " tys-draw-body";
            document.children[0].style.overflow = "hidden";
            // this.props.handler1();
        }
        // }
    }
    closeQucikView(e) {
        e.preventDefault();
        var a = document.getElementById('tysQuickView').classList.remove("show");
        document.children[0].style.overflow = "";
        document.getElementById('root').classList.remove("tys-draw-body");
        this.props.handler1();

        this.setState({
            quickViewTab_id: 'tysQuickViewPanel'
        })
    }
    onNext(e) {

        e.preventDefault();
        //console.log(this.props.name);
        var c = this.props.name;
        c = c + 1;
        this.props.handler2(c)
    }
    onPrevious1(e) {

        e.preventDefault();
        //console.log(this.props.name);
        var c = this.props.name;
        c = c - 1;
        this.props.handler2(c)
    }
    openModal() {
        this.setState({
            visible: true
        });
    }

    closeModal() {
        this.setState({
            visible: false
        });

    }
    setvalues() {

        this.context.router.push(
            "/form", { data: this.props.data.car }
        );

    }

    quickViewTabchange(e, offer = "") {

        if (e !== "tysThankUPanel") {
            this.setState({
                quickViewTab_id: e.target.id,
                isTestdrive: false,
                makeOffer: offer
            })

        } else {
            this.setState({
                quickViewTab_id: e,
                //isTestdrive : false,
                makeOffer: offer
            })
        }

    }

    selectCar()
    {
         ;
        this.props.selectCarFunc(this.props.data.car);

        var a = document.getElementById('tysQuickView').classList.remove("show");
        document.children[0].style.overflow = "";
        document.getElementById('root').classList.remove("tys-draw-body");
        this.props.handler1();

        this.setState({
            quickViewTab_id: 'tysQuickViewPanel'
        })
    }
    quickViewTabchange_drive(e) {


        this.setState({
            quickViewTab_id: e.target.id,
            isTestdrive: true
        })

    }
    onclick() {

        this.setState({ alpha: true })
    }


    render() {
         ;
        //console.log('-->Qiiiii' , this.props);
        // -------------for slider-----------------------------
        var params = {
            pagination: '.swiper-pagination',
            slidesPerView: 6,
            paginationClickable: true,
            spaceBetween: 15,
            paginationClickable: true,
            loop: true,
            containerClass: "swiper-container wizard-slider",
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        };

        var params1 = {
           // pagination: '.swiper-pagination',
            slidesPerView: 4,
            spaceBetween: 15,
           // loop: true,
            containerClass: "swiper-container wizard-slider",
            // nextButton: '.swiper-button-next',
            // prevButton: '.swiper-button-prev',
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev'
            // },
        };

        if (this.state.CurrentImage == "") {
            this.state.Image = this.props.data != undefined && this.state.CurrentImage == "" ? this.props.data.images.FrontImageName : "1";
        }

         
        
        //   ------------slider end----------------------------
        // console.log(this.props.Check)
        // console.log('quickview --- data ', this.props.data)
        // console.log(this.props.name)


        // console.log(this.props.data.car.DealershipStationLatitude)
        if (this.props.Check === false) { return <div  className="tys-car-details "></div> }
        return (

            <div className="tys-car-details " id="tysQuickView">

                <div className="tys-cd-scroll">
                    <a href="#" className="close-drawer" onClick={this.closeQucikView.bind(this)} id="tysQuickViewClose"><i className="fas fa-times" aria-hidden="true"></i></a>
<div className="tys--cd-inner">
                    <div id="tysQuickViewPanel" className={this.state.quickViewTab_id == "tysQuickViewPanel" ? 'quickpanelarea' : 'quickpanelarea d-none'}>
                        <div className="tys--cd-inner">
                            <div className="tys-cd-img">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        {/* <div className="c-images-alt"> */}

                                        {/* <img src={this.state.Image} alt="" /> */}
                                        {/* <img src={ Car1 } alt="" className="w-100%" /> */}
                                        <img src={this.state.Image} alt="" className="w-100" />

                                    </div>
                                    {/* <ul className="d-flex cImgList" id=""> */}

                                    <Swiper {...params1} >

                                        {/* <div className="col-4"> */}
                                        <div className="make">
                                            {/* <img src={this.props.data.images.HoodImageName} alt="" className="w-100%" /> */}
                                            {/* <img src={ Car1 } alt="" className="w-100%" /> */}
                                            <img src={this.props.data.images.FrontImageName == "" ? placeholder1 : this.props.data.images.FrontImageName} alt="" className={this.state.CurrentImage == "1" ? 'w-100 active' : 'w-100'} onClick={() => this.setState({ Image: this.props.data.images.FrontImageName == "" ? placeholder1 : this.props.data.images.FrontImageName , CurrentImage: '1' })} style={{ height: 133 }} />
                                        </div>

                                        {/* <div className="col-4"> */}
                                        <div className="make">
                                            {/* <img src={this.props.data.images.BackImageName} alt="" className="w-100%" /> */}
                                            {/* <img src={placeholder2} alt="" className="w-100%" /> */}
                                            <img src={this.props.data.images.BackImageName == "" ? placeholder2 : this.props.data.images.BackImageName } alt="" className={this.state.CurrentImage == "2" ? 'w-100 active' : 'w-100'} onClick={() => this.setState({ Image: this.props.data.images.BackImageName == "" ? placeholder2 : this.props.data.images.BackImageName, CurrentImage: '2' })} style={{ height: 133 }} />
                                        </div>

                                        {/* <div className="col-4"> */}
                                        <div className="make">
                                            {/* <img src={this.props.data.images.InteriorImageName} alt="" className="w-100%" /> */}
                                            {/* <img src={placeholder3} alt="" className="w-100%" /> */}
                                            <img src={this.props.data.images.LeftImageName == "" ? placeholder3 : this.props.data.images.LeftImageName} alt="" className={this.state.CurrentImage == "3" ? 'w-100 active' : 'w-100'} onClick={() => this.setState({ Image: this.props.data.images.LeftImageName == "" ? placeholder3 : this.props.data.images.LeftImageName, CurrentImage: '3' })} style={{ height: 133 }} />
                                        </div>

                                        {/* <div className="col-4"> */}
                                        <div className="make">
                                            <img src={this.props.data.images.RightImageName == "" ? placeholder4q : this.props.data.images.RightImageName} alt="" className={this.state.CurrentImage == "4" ? 'w-100 active' : 'w-100'} onClick={() => this.setState({ Image: this.props.data.images.RightImageName == "" ? placeholder4q : this.props.data.images.RightImageName, CurrentImage: '4' })} style={{ height: 133 }} />
                                        </div>

                                        {/* <div className="col-4"> */}
                                        <div className="make">
                                            <img src={this.props.data.images.RightImageName == "" ? placeholder5q : this.props.data.images.RightImageName} alt="" className={this.state.CurrentImage == "5" ? 'w-100 active' : 'w-100'} onClick={() => this.setState({ Image: this.props.data.images.RightImageName == "" ? placeholder5q : this.props.data.images.RightImageName, CurrentImage: '5' })} style={{ height: 133 }} />
                                        </div>

                                    </Swiper>
                                    {/* </ul> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="tys-cd-detail">
                                <Link style={{ textDecoration: "unset" }} to={{ pathname: `cardetails/${this.props.data.car.VehicleID}/${this.props.data.car.DealershipStationID}`, state: { data: this.props.data.car, From: "M" } }} ></Link>
                                <h2>Toyota {this.props.data.car.VariantName}

                                    <Popup trigger={<span className="tys-shareLink" style={{marginLeft:"10px", color: "#d21414"}}><a style={{ color: '#6b6b6b' }}><i className="fa fa-share-alt" aria-hidden="true"></i></a></span>} position="right center">
                                        <div>
                                            <WhatsappShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID} >
                                                <WhatsappIcon size={32} round={true} />
                                            </WhatsappShareButton>

                                            <FacebookShareButton url={"http://toyotasure.com.pk/#/cardetails/" + this.props.data.car.VehicleID + '/' + this.props.data.car.DealershipStationID} >
                                                <FacebookIcon size={32} round={true} />
                                            </FacebookShareButton>
                                        </div>
                                    </Popup>

                                </h2>



                                <span className="cd-detail-price">
                                    PKR <NumberFormat value={this.props.data.car.RetailPrice} displayType={'text'} thousandSeparator={true} />
                                </span>
                                <span className="cd-detail-add">
                                    Stock ID: {this.props.data.car.EnquirySno}
                         <Link to={{ pathname: `cardetails/${this.props.data.car.VehicleID}/${this.props.data.car.DealershipStationID}`, state: { data: this.props.data.car, From: "M" } }} >Details</Link>
                                </span>
                                <p>Mileage: <strong><NumberFormat value={this.props.data.car.Mileage} displayType={'text'} thousandSeparator={true} /> KM</strong></p>
                            </div>

                            <div className="tys-cd-dealer-btn">
                                <div className="row mt-1 mb-3">
                                    <div className="col-md mb-2">
                                        {/* <a href="" className="btn btn-primary" onClick={() => this.openModal()}>Make Offer</a> */}
                                        {/* <Link className="btn btn-primary" to={{ pathname: {quickviewoffer}, state: { data: this.props.data.car, From: "M" } }} >MAKE AN OFFER</Link> */}
                                        <span id="tysOfferPanel" className="btn btn-primary" style={{display : this.props.checkFlag != "exchange" ? "block" : "none"}} onClick={this.quickViewTabchange.bind(this)}>MAKE AN OFFER</span>
                                        {/* <button className="btn btn-primary" onClick={this.setvalues.bind(this)}>Make Offer</button> */}
                                    </div>
                                    <div className="col-md mb-2">
                                        <span id="tysOfferPanel" style={{display : this.props.checkFlag != "exchange" ? "block" : "none"}} className="btn btn-primary" onClick={this.quickViewTabchange_drive.bind(this)}>Schedule a Test Drive</span>
                                    </div>
                                    <div className="col-md mb-2">
                                        <span id="tysOfferPanel" className="btn btn-primary" style={{display : this.props.checkFlag != "exchange" ? "none" : "block"}} onClick={this.selectCar.bind(this)}>Select this Car</span>
                                    </div>
                                </div>
                            </div>

                            <div className="tys-cd-dealer-loc">
                                <strong>Dealer Location</strong>
                                <RenderMap Heigth={300} lat={this.props.data.car.DealerStatLAT} lon={this.props.data.car.DealerStatLong} data={this.props.data.car} />
                                {/* <img src={MapImage} alt="" className="w-100 mt-2 mb-2" /> */}
                            </div>
                            {/* ----emi start------ */}
                            {/* <ul className="nav nav-pills nav-fill onnav-fills" id="cdTabs" role="tablist">
                                <li className="nav-item">
                                    <span className="nav-link active" id="cdEmi-tab" data-toggle="tab" onClick={() => this.setState({ TabCheck: true })} role="tab" aria-controls="cdEmi" aria-selected="true"><i className="icon-forms"></i> EMI Calculator</span>
                                </li>
                                <li className="nav-item" style={{ display: "none" }}>
                                    <span className="nav-link" id="cdInsurance-tab" data-toggle="tab" onClick={() => this.setState({ TabCheck: false })} role="tab" aria-controls="cdInsurance" aria-selected="false"><i className="icon-forms" ></i> Insurance Calculator</span>
                                </li>
                            </ul> */}
                            <div className="tab-content  mb-3" id="cdTabsContent">
                                <div className="tab-pane fade show active" id="cdEmi" role="tabpanel" aria-labelledby="cdEmi-tab">
                                    {/* <!--EMI Start--> */}
                                    {/* <div className="tys-wizard-content" style={{ display: this.state.TabCheck == true ? "block" : "none" }}>
                                        <h2>Calculate your loan</h2>
                                        <div className="tys-bank-slider">
                                            <label htmlFor="">Select Bank</label>
                                            
                                            <Swiper {...params} >
                                                
                                                <div className="swiper-slide"><img src={Layer1} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer2} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer3} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer4} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer5} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer1} alt="" /></div>
                                                <div className="swiper-slide"><img src={Layer2} alt="" /></div>
                                                
                                            </Swiper>
                                            <div className="tys-terms">
                                                <span>*Term & Conditions applys as per your selected bank</span>
                                            </div>
                                        </div>
                                        <div className="wizard-form tys-select-wrapper">
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="">Rate of Interest (%) :</label>
                                                    <input type="text" placeholder="5% - 25%">
                                                       
                                                    </input>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="">Tenure:</label>
                                                    <select className="custom-select">
                                                        <option value="1" >12 MONTHS</option>
                                                        <option value="1" >24 MONTHS</option>
                                                        <option value="1" >36 MONTHS</option>
                                                        <option value="1">48 MONTHS</option>
                                                        <option value="1" >60 MONTHS</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="">Loan Amount</label>
                                                    <input type="text" placeholder="5,111,089" name="title">
                                                    
                                                    </input>
                                                </div>

                                                <div className="col-md-6 align-self-center text-center">
                                                    <button className="btn btn-primary w-75">Calculate</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tys-wizard-total">
                                            <div>
                                                <span>
                                                    Your <strong>EMI amount</strong> for the tenure of <strong>12 months</strong>
                                                </span>
                                            </div>
                                            <div>
                                                <span className="wizard-price"><strong> Rs  1,417,014</strong> per month</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <!--EMI End--> */}
                                </div>
                                <div className="tab-pane fade" id="cdInsurance" role="tabpanel" aria-labelledby="cdInsurance-tab">
                                    ...
				</div>
                            </div>




                            {/* ------emi end--------- */}

                        </div>
                    </div>

                    <div id="tysOfferPanel" className={this.state.quickViewTab_id == "tysOfferPanel" ? 'tys-offer tys-cd-detail mini' : 'tys-offer tys-cd-detail mini d-none'} >
                        <Quickviewoffer isTestdrive={this.state.isTestdrive} onClickfunc={this.quickViewTabchange.bind(this)} VehicleID={this.props.data.car.VehicleID} DealershipStationID={this.props.data.car.DealershipStationID} cardata={this.props.data} />
                    </div>
                    <div id="tysThankUPanel" className={this.state.quickViewTab_id == "tysThankUPanel" ? 'tys-thankyou' : 'tys-thankyou d-none'}>
                        <h2 className="mb-2">{this.props.data.car.ModelName} {this.props.data.car.VariantName}</h2>
                        <small>
                            {/* Add ID: {this.props.data.car.VehicleID} */}
                            </small>

                        {this.state.isTestdrive ? '' : <span className="tys-offered-price d-flex justify-content-center align-items-center ">
                            <span>Offered:</span>
                            <span className="tys-offer-price">PKR {this.state.makeOffer} </span>
                            <span className="tys-offer-icon fa fa-check-circle"></span>
                        </span>}

                        <img src={img1} alt="" />

                        <span className="tys-thankyou-txt">Thank You!</span>
                        <p>Your offer has been submitted.</p>
                        {/* <a href="javascript:void(0)" className="btn btn-primary">View More Car</a> */}
                    </div>
                    </div>
                </div>
                <div className="tys-cd-action">
                    <div className="row">
                        <div className="col" onClick={this.onPrevious1}>
                            <a href="#"><i className="fas fa-arrow-circle-left" aria-hidden="true"></i> Previous</a>
                        </div>
                        <div className="col text-right" onClick={this.onNext}>
                            <a href="#"><i className="fas fa-arrow-circle-right" aria-hidden="true"></i> Next</a>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default withRouter(QucikView);






