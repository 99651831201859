import React, { Component} from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'what-input'





   

//---------------------//
//---------- CSS -----//
//---------------------//
import "./assets/plugins/mediaGallery/css/component.css"
import './assets/main.css';
import './assets/reactCustomStyle.css'
import './assets/main.css.map';
//---------------------//
//---------- CSS-----//
//---------------------//





//---------------------//
//---------- Components -----//
//---------------------//
import auth from "./services/authService";
import NavBar from "./components/navBar";
import LoginForm from "./components/loginForm";
import RegisterForm from "./components/registerForm";
import Logout from "./components/logout";
import Customers from "./components/customers";
import Rentals from "./components/rentals";
import NotFound from "./components/notFound";
import Faqs from './components/faqs/faqs.jsx'
import Home from "./components/Home/home";
import Privacypolicy from "./components/privacy/privacy";
import Dealer from "./components/Dealer/dealer.jsx";
import Header from './components/General/header.jsx'
import Footer from './components/General/footer.jsx'
import Car from './components/cars/car.jsx'
import Certified from "./components/Certified/certified";
import Media from "./components/media/media";
// import quranapp from "./components/quranapp/quranapp";
import ReactDOM from 'react-dom'
//import TagManager from 'react-gtm-module';
import Emi from "./components/emi/emi";
import NavHamBurger from './components/General/navHamBurger'
import QucikView from "./components/cars/quickview";
import About from "./components/about/about";
import Compare from "./components/compare/compare";
import Demoform from './components/cars/demoform'
import ExchangeForm from "./components/General/exchangeform";
import Sellform from "./components/General/sellForm";
import SellACar from './components/SellACar/sellACar';
import ExchangeCar from './components/ExchangeCar/exchangeCar';
import Cardetails from './components/car-details/car-details';
import EmiCalculator from './components/emi/emiCalculator';
import ComingSoon from './components/General/comingSoon';
import CustomError from './components/General/404'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import UsedCarGala from './components/cars/usedcargala.jsx'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

      //---------- HamBurger Flag -----//
      HamBurgerFlag: false,
      //----------//  

      //---------- Header Class -----//
      HeaderClass: "tys-header",
      //----------//  
    
    };
    this.UpdateHamBurgerFlagToOpen = this.UpdateHamBurgerFlagToOpen.bind(this);
    this.HomehandlerForHeaderClass = this.HomehandlerForHeaderClass.bind(this);
     
    const options = {
      autoConfig: true, 
      debug: true, // enable logs
    };
    ReactPixel.init('795757540967618', options);
  }
  
 

  //----------When Click On Header HamBurger This Handler Changes The Flag as True-----//
  UpdateHamBurgerFlagToOpen(e) {
   
   
    if(this.state.HamBurgerFlag == false)
    {
    this.setState({ HamBurgerFlag: true });
    document.querySelector('body').classList.add('tys-draw-body');
    document.children[0].style.overflow = "hidden";
    }
    else
    {
      e.preventDefault();
      this.setState({ HamBurgerFlag: false });
      document.querySelector('body').classList.remove('tys-draw-body');
      document.children[0].style.overflow = "";
    }
  }

  //----------This Handler Will Add Hero Class On Header When Home Component Is Render -----//
  HomehandlerForHeaderClass() {
    if (this.state.HeaderClass == "tys-header hero") {
      this.setState({ HeaderClass: "tys-header" });
    }
    else {
      this.setState({ HeaderClass: this.state.HeaderClass += " hero" });
    }
  }
  //----------//  
  

  render() {
     
    ReactGA.initialize('UA-37000500-3');
    var pagePath = window.location.href.split('#')[1];
    ReactGA.pageview(pagePath);
    
    ReactGA.event({
      category: 'User',
      action: 'Created an Account'
    });
     
    ///Facebook pixel PageViewCall
		ReactPixel.track('Page Render', pagePath); 
   //ReactPixel.pageView();
    return (
      <React.Fragment>

   
<ToastContainer />
        <Header Handler={this.UpdateHamBurgerFlagToOpen} Class={this.state.HeaderClass} />
        <NavHamBurger Check={this.state.HamBurgerFlag} Handler={this.UpdateHamBurgerFlagToOpen}></NavHamBurger>
       <EmiCalculator/>
        <div className="tys-main" aria-label="Content" >
          <Switch>
            <Route path="/Home" render={(routeProps) => (<Home {...routeProps} Handler={this.HomehandlerForHeaderClass} />)} />   
            <Route path="/faqs" component={Faqs} />
            <Route path="/404" component={CustomError} />
            {/* <Route path="/media" component={Media} /> */}
            <Route path="/customers" component={Customers} />
            <Route path="/rentals" component={Rentals} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/dealer" component={Dealer} />
            <Route path="/car" component={Car} />
            <Route path="/usedcargala" component={UsedCarGala} />
            <Route path="/certified" component={Certified} />
            <Route path="/emi" component={Emi} />
            <Route path="/quick" component={QucikView} />
            <Route path="/About" component={About} />
            <Route path="/compare" component={Compare} />
            <Route path="/sell-a-car" component={() => <SellACar isExchange={false} />} />
            <Route path="/cardetails/:CarId/:DealerId" component={Cardetails} />
            <Route path="/exchange-a-car" component={() => <SellACar isExchange={true}  />}  />     
            <Route
              path="/form/:VehicleID?/:DealshipstationID?"
              component={Demoform}
            />
                        {/* <Route path="/quranapp" component={quranapp} /> */}

            <Route path="/sellform" component={Sellform} />
            <Route path="/exchangeForm" component={ExchangeForm} />
            <Route path="/comingSoon" component={ComingSoon} />
            <Route path="/Privacypolicy" component={Privacypolicy} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />    


          </Switch>
          <Footer />
          </div>
          <div id="mediaDivToDisableBody" style={{ display: "none" }} className="modal-backdrop fade show"></div>
          

      </React.Fragment>
    );
  }
}

export default App;
