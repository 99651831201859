import React, { Component } from "react";
import sell_Car from "../../assets/images/about/1.png";
import purchase_Car from "../../assets/images/about/2.png";
import exchange_Car from "../../assets/images/about/3.png";
import { Link } from "react-router-dom";

class Improvement extends Component {
  render() {
    return (
      <div className="tys-section pb-0">
        <div className="container text-center">
          <h1 className="tys-title">
            A JOURNEY OF <strong>CONTINUOUS IMPROVEMENT</strong>
          </h1>
          <p className="w-75 mt-4 ml-auto mr-auto">
            Toyota Sure is the new standard in the used market in
            Pakistan, which offers buying, selling and exchange services of used
            cars. Under the support and guidance of business standards by Indus
            Motor Company, Toyota customers can be confident that they will
            receive the best quality used cars with OEM warranty and stringent
            quality checks. 
          </p>
          <p className="text-center mt-5 mb-4 font-weight-bold">
            Toyota Sure offers the following services
          </p>
        </div>
        <div className="tys-service-tiles-wrapper">
          <section className="tys-service-tiles">
            <div className="tl-item">
              <div
                className="tl-bg"
                style={{ backgroundImage: `url(${sell_Car})` }}
              ></div>

              <div className="tl-year">
                <p className="f2 heading--sanSerif">
                  Car <strong>Sales</strong>
                </p>
              </div>

              <div className="tl-content">
                {/* <!-- <h1 className="f3 text--accent ttu">Vestibulum laoreet lorem</h1> --> */}
                <p>
                  The vehicles displayed at the Toyota SURE showroom must pass
                  the 210 inspection points by the vehicle assessment staff who
                  have passed the Toyota Vehicle Inspection (TVI) training
                  course according to Toyota standards. There are 3 types
                  of vehicles present at the showrooms with varying benefits
                </p>
                <ul>
                  <li>Toyota Certified</li>
                  <li>Retail</li>
                  <li>Wholesale</li>
                </ul>
                <Link to="./sell-a-car" className="btn btn-outlined">
                  Read More
                </Link>
              </div>
            </div>

            <div className="tl-item">
              <div
                className="tl-bg"
                style={{ backgroundImage: `url(${purchase_Car})` }}
              ></div>

              <div className="tl-year">
                <p className="f2 heading--sanSerif">
                  Car <strong>Purchase</strong>
                </p>
              </div>

              <div className="tl-content">
                {/* <!-- <h1>Lorem ipsum dolor sit</h1> --> */}
                <p>
                  Toyota SURE through its Toyota Vehicle Inspection (TVI) System
                  evaluates each vehicle to accurately record its condition. We
                  ensure that customers get a good price for their car which is
                  based on its quality level. Customers selling their vehicle to
                  us are encouraged to be involved in the entire process for
                  complete transparency and peace of mind.
                </p>

                <Link to="./car" className="btn btn-outlined">
                  Read More
                </Link>
              </div>
            </div>

            <div className="tl-item">
              <div
                className="tl-bg"
                style={{ backgroundImage: `url(${exchange_Car})` }}
              ></div>

              <div className="tl-year">
                <p className="f2 heading--sanSerif">
                  Exchange <strong></strong>
                </p>
              </div>

              <div className="tl-content">
                {/* <!-- <h1 className="f3 text--accent ttu">Phasellus mauris elit</h1> --> */}
                <p>
                  At Toyota SURE you can conveniently exchange your used car
                  with a brand new Toyota or a used vehicle by paying the
                  balance payment. We buy and sell used cars of all brands not
                  just limited to Toyota however we strongly recommend our
                  customers to opt for either a brand new Toyota or a certified
                  used Toyota for maximum peace of mind.
                </p>
                <Link to="./exchange-a-car" className="btn btn-outlined">
                  Read More
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Improvement;
