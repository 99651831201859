import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import video1 from '../../assets/videos/newAdd.mp4';
import backgroundImage from "../../assets/images/why_toyota_bg.jpg";


const divStyle = {
	outline: 'none !important' ,  
	
  }

class ToyotaSure extends Component {
	constructor(props){
        super(props);
        this.state ={
             data : [ 
                 {
					Heading : 'Unparalleled Warranty',
					Context : 'Up to 1 Year/15,000 KM OEM Warranty* which can be availed at any Toyota Dealership in Pakistan'
				 } ,
				 {
					Heading : '203 Checkpoints',
					Context : 'Only the best cars are certified by Toyota SURE, ensuring quality and reliability for our customers.'
                 },                 {
					Heading : 'Dealer Network',
					Context : 'Spread across Pakistan, you can avail services at any Toyota 3S Dealership nationwide'
                 }
             ]
        }
	}
	
   
    render() {
        return (
            <div className="tys-section tys-why-toyota"     style={{ backgroundImage: `url(${backgroundImage})`}} >
		<div className="container">
			<h1 className="tys-title mb-4">Why Toyota <strong>sure?</strong></h1>
			<div className="row">
				<div className="col-md mb-5 mb-sm-0 ">
									
				<ReactPlayer url={video1}  playing controls volume="0" muted={true} 
				 style={{maxWidth : "97%"}} height={346} />
			
                                
                            
				</div>
				<div className="col align-self-center">
					{
						this.state.data.map( Items => (
							<div key={Items.Heading}>
								<h2 className="tys-title-sub">{Items.Heading}</h2>
							<p>{Items.Context}</p>
							</div>
						))
					}
				</div>
			</div>
		</div>
	</div>

        )
    }
}

export default ToyotaSure;