import React, { Component } from 'react';


class SpecialContent extends Component {
    constructor(props){
        super(props);
        this.state ={
             data : [ 
                 {
                     content  : 'At Toyota SURE we have a range of exclusive special offers to make owning a used car even more affordable. Most of our offers are for limited time only and are very popular with our customers.'
                 }
             ]
        }
    }
    render() {
        return (

			<div className="tys-so__text text-center">
				<h1 className="tys-title">Special <strong>Offer</strong></h1>
        <p className="w-75 ml-auto mr-auto">{this.state.data[0].content}</p>
				<p className="text-center font-weight-bold">Select Car</p>
			</div>
			
        )
    }
}

export default SpecialContent;