import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import Sell from '../../assets/images/Service/Sell.png';
import Exchange from '../../assets/images/Service/Exchange.png';
import Buy from '../../assets/images/Service/Buy.png';
import Image from "../../assets/images/Service/Section-Background-Image.png";
import Image1 from "../../assets/images/service-icon.png";
import { Autoplay } from 'swiper/js/swiper.esm';

class Servies extends Component {
	constructor(props) {
		super(props);
		// this.state = {
		//        data : [
		// 		   {
		// 			  Image : '../../assets/images/service1.png' ,
		// 			  Heading :'POLD your car has never been easier',
		// 			  Icon :  "../../assets/images/service-icon.png" ,
		// 			  point : [
		// 				  '1' , '2'
		// 			  ]

		// 		   }
		// 	   ]
		// }
	}
	render() {
		var params = {
			slidesPerView: 3,
			spaceBetween: 0,
			loop: true,
			slideToClickedSlide: true,
			allowSlidePrev: true,
			centeredSlides: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				bullets: true
			},
			breakpoints: {
				300: {
					slidesPerView: 1,
					spaceBetween: 20
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 20
				}
			},
			//image:"centre",

			// paginationClickable: true,

			containerClass: 'tys-service-slider'
		};

		return (
			<div className="tys-section tys-services   l-mode" style={{ backgroundImage: `url(${Image})` }}>
				<div className="container">
					<div className="text-center">
						<h1 className="tys-title">ONE WINDOW SOLUTION</h1>
						<p>The process of used car transactions in Pakistan can be tricky, frustrating and time consuming. Car owners can avail the one window solution to fulfill their automotive needs at Toyota SURE dealerships.</p>
					</div>

					{/* <!-- Services Slider --> */}
					<Swiper {...params}>
						<div className="swiper-slide swiper-slide-row">
							<img src={Buy} alt="" />
							<div className="content">
								<img src={Image1} className="tys-srv-icon" alt="" />
								<h2>Best place to Upgrade to a new Toyota
									</h2>
								<ul>
									<li>Nationwide Dealer Network</li>
									<li>Aftersales services</li>
								</ul>
							</div>
						</div>

						<div className="swiper-slide swiper-slide-row">
							<img src={Exchange} alt="" />
							<div className="content">
								<img src={Image1} className="tys-srv-icon" alt="" />
								<h2>Certified cars are as good as new cars
									</h2>
								<ul>
									<li>OEM Warranty</li>
									<li>Accident Free</li>
									<li>Immaculate Condition</li>
								</ul>
							</div>
						</div>
						<div className="swiper-slide swiper-slide-row">
							<img src={Sell} alt="" />
							<div className="content">
								<img src={Image1} className="tys-srv-icon" alt="" />
								
								<h2>Selling your car has never been easier
										</h2>
								<ul>
									<li>Visit any Toyota SURE Dealership</li>
									<li>Get a free 203 checkpoint Evaluation</li>
									<li>We offer the best price for your used car</li>
									<li>One window solution to buy, sell or exchange </li>
								</ul>
							</div>
						</div>


					</Swiper>




				</div>
			</div>
		)
	}
}

export default Servies;