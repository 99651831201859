import React, { Component } from "react";
// import AccordianInput from "./AccordianInput";
class AccoridanforSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
    this.OpenMenu = this.OpenMenu.bind(this);
    // this.changeMaker = this.changeMaker.bind(this);
    this.SearchClearSingle = this.SearchClearSingle.bind(this);
  }

  OpenMenu(e) {
    //
    this.setState({
      currentTab: e.target.id,
      checkOpen:
        this.state.currentTab === e.target.id && this.state.checkOpen === false
          ? true
          : false
    });
  }

  SearchClearSingle(event) {
    
    this.props.ClearSingleData(event.Id, event.Label, event.Name)
    // this.setState({ checked: !this.state.checked });



  }

  render() {
    
    return (
      <React.Fragment>
        <div
          className="cl-f-box tys-collapse"
          id={this.props.id}
          onClick={this.OpenMenu}
          data-toggle="collapse"
          data-target="group-model"
          style={{display: this.props.data.length != 0 ? "block" : "none" }}
        >
          <span className="cl-f-icon fa-filter fas" />
          <h2>{this.props.children}</h2>
        </div>
        <div
          id="group-model"
          className="list-group collapse in show"
          style={{display: this.props.data.length != 0 ? "block" : "none" }}
        >
          <span className="list-group-item">
            {this.props.data !== undefined &&
              this.props.data.map((val, index) => {
                
                return (
                  <div>
                    <label htmlFor={val.Name} className="">
                      {val.Name}
                    </label>

                    <span className="badge badge-light" id={val.Id} style={{ float: "right" }} onClick={() => this.SearchClearSingle(val)} >x</span>
                  </div>
                );
              })}
            <div>
              <button className="mt-1 btn btn-sm btn-danger" style={{ backgroundcolor: "rgb(210, 20, 20)", display: this.props.data.length != 0 ? "block" : "none" }} onClick={() => this.props.ClearAll()}><strong><span>Clear All</span></strong></button>
            </div>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default AccoridanforSearch;
