import React, { Component } from 'react';
import Gallery from "react-photo-gallery";
import Breadcrum from "../breadcrum";
import { GetService, PostService } from "../../services/GeneralServices";
import Swiper from 'react-id-swiper';
import Loader from '../General/loader';
import { withRouter, Link, Redirect } from 'react-router-dom';

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Desc:"",
            filterImages: [],
            DuplicateImages: [],
            imageTab: 'all',
            slideImageSrc: '',
            slideImageHead: '',
            slideImageDec: '',
            Images: [],
            currentIndex: 0,
            SelectedIndex: 0,
            loader : true,
            Exception: false,
        }

        this.handleclick = this.handleclick.bind(this);
    }
    async componentWillMount() {
        try {
            const { data } = await GetService('api/vehicle/GetGalleryImages');
            
            //console.log(data)
            this.setState({ Images: data.Data.ObjTsurePhotoGalleryImages, 
                DuplicateImages: data.Data.ObjTsurePhotoGallery })
        } catch (error) {
           
            this.setState({ loader: false, Exception: true })
          
        }
        
        //this.setState({ DuplicateImages: data })
    }

    renderException = () => {
        
        if (this.state.Exception) {
    
          return <Redirect to={{ pathname: '/404' }} />
    
        }
        else {
    
        }
      }


    galleryFunc = (id) => {
        

        // const { mediaImages, imageTab } = this.state;

        if (id == "all") {
            this.state.filterImages = this.state.DuplicateImages.map((images, index) => {

                return ({

                    src: images.Main_Image,
                    width: 4,
                    height: 4,
                    id: index,
                    name: images.Photo_Gallery_Id

                })
            })

        }
        else {
            this.state.filterImages = this.state.Images.filter(val => {
                return val.Photo_Gallery_Id == id

            });

            this.state.filterImages = this.state.filterImages.map((val, index) => {

                return ({
                    src: val.Name,
                    width: 4,
                    height: 4,
                    id: val.Images_Id,
                    name: index

                })

            })
        }
        if (this.state.filterImages.length == 0) {
            this.state.filterImages = this.state.DuplicateImages.filter(val => {
                return val.Photo_Gallery_Id == id

            });

            this.state.filterImages = this.state.filterImages.map(val => {

                return ({
                    src: val.Main_Image,
                    width: 4,
                    height: 4,
                    id: val.Photo_Gallery_Id
                })

            })
        }

        return <Gallery onClick={this.handleclick} photos={this.state.filterImages} direction={"column"} />
    }

    StateChange = (event) => {
        
        this.setState({
            imageTab: event,
            Desc: null
        })
    }

    handleclick(e) {
        
        //console.log("E",e)
        if(this.state.imageTab == "all")
        {
this.setState({imageTab: e.target.name})     
   }
        else{
        const row = this.state.Images.filter(image => { 
            return image.Images_Id == e.target.id;
        } )
        if(row.length > 0)
        {
            this.setState({
                slideImageSrc: e.target.src, SelectedIndex: e.target.name,
                
                slideImageHead: row[0].ImageHeader,
                
                slideImageDec: row[0].ImageDescription
         
            })
            document.body.style.paddingRight = "17px";
            document.body.classList.add("modal-open");
            document.children[0].style.overflow = "hidden";
        }
        
    }
   

    }
    OnClose(e) {
        
        this.setState({
            slideImageSrc: '',
        })

        document.body.style.paddingRight = "0px";
        document.body.classList.remove("modal-open");
        document.children[0].style.overflow = ""
    }

    goToPrevSlide() {
        

        const AlbumPictures = this.state.Images.filter(val => {
            return val.Photo_Gallery_Id == this.state.imageTab

        })
        if (this.state.SelectedIndex > 0) {

            const newPointer = parseInt(this.state.SelectedIndex) - 1;
            this.state.SelectedIndex = newPointer;
            const src = AlbumPictures[this.state.SelectedIndex].Name;
            this.setState({ 
                slideImageSrc: src,
                slideImageHead : AlbumPictures[this.state.SelectedIndex].ImageHeader,
                slideImageDec : AlbumPictures[this.state.SelectedIndex].ImageDescription
            });
        }
    }

    goToNextSlide() {
        
        const AlbumPictures = this.state.Images.filter(val => {
            return val.Photo_Gallery_Id == this.state.imageTab

        })
        if (this.state.SelectedIndex < AlbumPictures.length - 1) {
            const newPointer = parseInt(this.state.SelectedIndex) + 1;
            this.state.SelectedIndex = newPointer;
            const src = AlbumPictures[this.state.SelectedIndex].Name;
            this.setState({ 
                slideImageSrc: src,
                slideImageHead : AlbumPictures[this.state.SelectedIndex].ImageHeader,
                slideImageDec : AlbumPictures[this.state.SelectedIndex].ImageDescription
            });
        }
    }


    render() {


   
        //console.log("Desc",this.state.Desc)  
        
        //console.log("ModalData",this.state.slideImageSrc)
        if (this.state.DuplicateImages == "" || this.state.DuplicateImages == undefined || this.state.DuplicateImages == null) {
            return this.state.loader ? <Loader /> :      this.renderException();


    
        }

        var params = {
            pagination: '.swiper-pagination',
            slidesPerView: 6,
            paginationClickable: true,
            spaceBetween: 15,
            paginationClickable: true,
            loop:false,
            containerClass:"swiper-container wizard-slider swiper-container-initialized swiper-container-horizontal" ,
            // nextButton: '.swiper-button-next',
            // prevButton: '.swiper-button-prev',
            // navigation: {
    // nextEl: '.swiper-button-next',
    // prevEl: '.swiper-button-prev'
//   },
};
        return (
            <React.Fragment>

                <div className="tys-page tys-page-" >

                    <Breadcrum Name='Media' />

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto">
                                <div className="tys-internal-details">
                                    <h1>Media Gallery</h1>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
                                     <p>{this.state.Desc}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tys-media">
                        <div id="SlideMiddle" className="container">
                            <div id="nav">
                                <div id="nav-bar">
                                    <div id="fix-content">
                                        <div id="nav-bar-filters">
                                        {/* <Swiper {...params} > */}
                                
  
                                            <div data-f=".c2" id='all' className={this.state.imageTab == "all" ? "filter-item active" : " filter-item"} id="filtersure"><span id="toyotasure" onClick={() => this.StateChange("all")}>All</span></div>
                                            {this.state.DuplicateImages.map( (images , index) => {
                                               
                                                return <div data-f=".c2" key={index} id={`filter${images.Photo_Gallery_Id}`} className={this.state.imageTab == images.Photo_Gallery_Id ? "filter-item active" : "filter-item"}  ><span id={images.Photo_Gallery_Id} onClick={() => this.setState({imageTab : images.Photo_Gallery_Id, Desc: images.Description})}>{images.Name}</span></div>

                                            })}
                                        {/* </Swiper> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="grid">
                                <div id="grid-content">
                                    {this.galleryFunc(this.state.imageTab)}
                                </div>
                            </div>
                        </div>

                        {/* <!-- Modal --> */}
                        <div className={this.state.slideImageSrc ? 'modal fade tys-media-modal show tys-media-modal-show' : 'modal fade tys-media-modal'} id="itemModal" tabIndex="-1" role="dialog" aria-labelledby="itemModalLabel" aria-hidden={this.state.slideImageSrc ? 'true' : 'false'}>
                            <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" onClick={() => this.OnClose()} >&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={this.state.slideImageSrc ? this.state.slideImageSrc : ''} alt="" className="w-100" id="itemImg" />
                                        <span className="tys-item-title">
                                        {this.state.slideImageHead}
                            </span>
                                        <p>
                                        {this.state.slideImageDec}
                            </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-arrow" onClick={() => this.goToPrevSlide(this)}><i className="fas fa-arrow-circle-left"></i></button>
                                        <button type="button" className="btn btn-arrow" onClick={() => this.goToNextSlide(this)}><i className="fas fa-arrow-circle-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default Media;
