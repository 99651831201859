import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import Breadcrum from "../../components/breadcrum";


//---------------------//
//---------- IMAGE-----//
//---------------------//
import BGimage from "../../assets/images/emi_bg.jpg";
import Bank1 from '../../assets/images/bank/Layer-1.png'
import Bank2 from '../../assets/images/bank/Layer-2.png'
import Bank3 from '../../assets/images/bank/Layer-3.png'
import Bank4 from '../../assets/images/bank/Layer-4.png'
import Bank5 from '../../assets/images/bank/Layer-5.png'
import Bank6 from '../../assets/images/bank/Layer-5.png'
//---------------------//
//---------- IMAGE-----//
//---------------------//
import EmiCalculator from './emiCalculator';



class Emi extends Component {
   
    render() {
        var params = {
            pagination: '.swiper-pagination',
            slidesPerView: 5,
            paginationClickable: true,
            spaceBetween: 15,
            paginationClickable: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
          };
        return (
          <React.Fragment>
            <EmiCalculator />
            <div className="tys-main" aria-label="Content">
            <div className="tys-page tys-page-" style={{ backgroundImage: `url(${BGimage})`}}>

<Breadcrum Name='EMI'/>

                {/* --------end----------- */}

                <div className="container">
                  <div className="row">
                    <div className="col-md-8 ml-auto mr-auto">
                        <div className="tys-internal-details">
                            <h1>EMI Calculator</h1>
                            
                              <p>Calculate the lowest Equated Monthly Installment (EMI) that you require to pay for your favourite Car. Please enter the loan amount that you are applying for. The EMI (Equated Monthly Installment) DOES NOT include any other processing fee or charges that may be applicable as per the rules of financing institutions and banks. This calculation is for illustrative purposes. Check with the dealer for exact monthly payments. Please also see our LEGAL NOTICE</p>
                            
                          </div>
                    </div>
                  </div>
                </div>
              
                
              <div className="tys-emi">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                          <img src="http://placehold.it/265x540" alt="" className="w-100"/>
                        </div>
                        <div className="col-6">
                            {/* <!--EMI Start--> */}
            <div className="tys-emi-content">
                <h2>Calculate your loan</h2>
                <div className="tys-bank-slider">
                    <label htmlFor="">Select Bank</label>
                    <Swiper {...params}>
                           <div className="swiper-slide">
                               <img src={Bank1} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank2} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank3} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank4} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank6} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank5} alt=""/> 
                              </div>
                              <div className="swiper-slide">
                               <img src={Bank6} alt=""/> 
                              </div>
                                     
                  </Swiper>




                   
            
                      <div className="tys-terms">
                        <span>*Term & Conditions applys as per your selected bank</span>
                      </div>
                </div>
                <div className="emi-form tys-select-wrapper">
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">Rate of Interest (%) :</label>
                            <select className="custom-select">
                                <option selected value="">16% - 25% </option>
                                <option value="1">16% - 25% </option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">Tenure:</label>
                            <select className="custom-select">
                                <option selected value="">12 MONTHS</option>
                                <option value="1">36 MONTHS</option>
                            </select>
                        </div>
                        <div className="col-12">
                            <label htmlFor="">Loan Amount</label>
                            <select className="custom-select">
                                <option selected value="">PKR 10,0000 - 20,0000</option>
                                <option value="1">PKR 10,0000 - 20,0000</option>
                            </select>
                            <hr />
                        </div>
                        {/* <div className="col-md-6">
                            <label htmlFor="">Rate of Interest (%) :</label>
                            <select className="custom-select">
                                <option selected value="">16% - 25% </option>
                                <option value="1">16% - 25% </option>
                            </select>
                        </div> */}
                        <div className="col-md-6 align-self-center text-center">
                            <button className="btn btn-primary w-75">Calculate</button>
                        </div>
                    </div>
                </div>
                <div className="tys-emi-total">
                    <div>
                        <span>
                            Your <strong>EMI amount</strong> for the tenure of <strong>12 months</strong>
                        </span>
                    </div>
                    <div>
                        <span className="emi-price"><strong> Rs  1,417,014</strong> per month</span>
                    </div>
                </div>
            </div>
            </div>
                        <div className="col-3">
                          <img src="http://placehold.it/265x540" alt="" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            </div>    
            </div>
            </React.Fragment>
        )
    }
}

export default Emi;