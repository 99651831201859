import React, { Component } from 'react';
import hero_banner from "../../assets/images/hero_banner.jpg";
import mainslider_buy from "../../assets/images/MAIN-SLIDER-Buy.png";
import mainslider_exchange from "../../assets/images/MAIN-SLIDER-Exchange.png";
// import mainslider_sell from "../../assets/images/MAIN-SLIDER-Sell.png";
//  import mainslider from "../../assets/images/header3.jpg";
import mainslider from "../../assets/images/Header1.png";  //SliderNew.png new slider image IMG_4272.jpg   SSNew.jpg
// 
// import mainslider from "../../assets/images/main-header.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import video1 from '../../assets/videos/car-video.mp4';
import { withRouter, Link, Redirect } from 'react-router-dom';
import ReactPlayer from "react-player";
import { useHistory } from 'react-router-dom';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            Redirect: false,
           }
    }
    
    statusFormatter(current, total) {
        return (
            <div className="swiper-pagination swiper-pagination-fraction">
                <span className="swiper-pagination-current">{current}</span>
                <span className="swiper-pagination-total"> / {total}</span>
            </div>
        )
    }
    renderRedirect = () => {

        if (this.state.Redirect) {
  
            return <Redirect to={{ pathname: '/usedcargala', state: { data: 'IsCertified' } }} />
  
    }
  }
    render() {
        const swiperParams = {
            breakpoints: {
            480: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            // when window width is <= 1024px
            1024: {
                slidesPerView: 2,
                spaceBetween: 30
            }
        },
        containerClass: 'swiper-container promo',
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true
    }
        return (
            <div className="swiper-container tys-slider-hero swiper-container-initialized swiper-container-horizontal">
                {/* <!-- Additional required wrapper --> */}
                <div className="swiper-wrapper" style={{ "height": "100%" }}>
                    {/* <!-- Slides -->*/}
                    {/* <Carousel autoPlay infiniteLoop={true} showStatus={true}  > */}
                    
                    <Carousel {...swiperParams} showStatus={true} statusFormatter={this.statusFormatter} showArrows={true} >

                        <div
                            className="swiper-slide bg-slide"
                            style={{ backgroundImage: `url(${mainslider})` }}>
                                {/* mt-auto */}
                                <h1 className="d-lg-none d-md-none mb-3 mt-md-auto"> Toyota Sure <br/> Used Car Gala in Progress... </h1>
                            
                            {/* <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Delectus, vero!
                            </p> */}
                            <span className="btn btn-red mt-md-auto mb-5" onClick={ () => this.setState({ Redirect : true})}>
                                Used Car Gala
                            </span> 
                            <img src={mainslider} alt="hero"></img>
                        </div>
                      </Carousel>
                    {this.renderRedirect()}
                

                </div>
              

            </div>

        )
    }
}

export default Slider;