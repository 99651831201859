import NumberFormat from 'react-number-format';
import React, { Component } from "react";
import { withRouter, Link, Redirect } from 'react-router-dom';
import carNotFound from "../../assets/images/vehicle_not_available.jpg";
import { PostService } from "../../services/GeneralServices";
//import ReactPixel from 'react-facebook-pixel';

class CarDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [] ,
            check: 0,
            hoverState : '',
            uniqueId:null,
            newDealershipStationID:null,

            data: this.props.data,
        }
         

        this.FixedImage = this.FixedImage.bind(this);
        this.OnMouseHover = this.OnMouseHover.bind(this);
        this.OnMouseHoverOut = this.OnMouseHoverOut.bind(this);
    }

   
  

//     componentWillMount (){
//         
//       this.setState({
//           data : this.props.data 
//       })
//    }

    scheduleHandler(id)
    {
        
    }
    Remove(index) {
    // console.log(index);
    // let Car = { ... this.state.data}
    // let NewCar = this.state.data.filter(val => {
    //     return  val.car.VehicleID != index 
    // })
    // 
    // this.setState({ data :  NewCar})
    this.props.RemoveFunc(index);

}

// componentDidUpdate(prevProps, prevState) {
//     
//     if (prevState.data !== this.props.data) {
//       console.log('pokemons state has changed.')
      
//     this.setState({ data :  this.props.data})
//     }
//   }


    

	//----Add Event Listner To Get The Value Of ScrollTop of WIndow------//  
	componentDidMount() {
        
    // var pagePath = window.location.href.split('#')[1];
    // ReactPixel.trackSingle('795757540967618', 'BuyACar Car Viwed', pagePath);
		window.addEventListener('scroll', this.FixedImage);
	}
	//----------// 
    async RedirectToCarDetail1(event) {
       

        if (event.target.id!="") {
        
             this.setState({ redirect: true })
                let newId;
                let id = parseInt(event.target.id);
                this.setState({ uniqueId: id })
                var obj = this.props.data.map(data=>{

                    if(data.car.VehicleID===id)
                    {
                       newId = data.car.DealershipStationID 
                       
                    }
                });
                this.setState({ newDealershipStationID:newId})
            
                await PostService('api/vehicle/AddPoint?id=' + id, "");

            
        }


    }
    
    RedirectToCarDetail(event) {
         ;
        if (this.state.redirect) {
            return this.props.history.push({ pathname: '/cardetails/' + this.state.uniqueId + '/' + this.state.newDealershipStationID, state: { data: this.props.data } })
        }
    }
	//----Remove Event Listener------//  
	componentWillUnmount() {
		
		window.removeEventListener('scroll', this.FixedImage);
	}
	//----------//  


    FixedImage() {
    
		var windowpos = document.body.scrollTop || document.documentElement.scrollTop
		if (windowpos >= 100 & this.state.check == 0) {
			this.setState({ check: 1 })
		}
		if (windowpos > 1000 & this.state.check == 1 || windowpos < 100 & this.state.check == 1 ) {
            this.setState({ check: 0 })
            }
        
  }


  OnMouseHover(e){

    //console.log(e.target.id);
    const id = e.target.id;
    this.setState({
        hoverState : id
    })
  }

  OnMouseHoverOut(){
      
    this.setState({
        hoverState : ''
    })
  }

    

    render() {
         ;
            return (

            <div className="table-scroll" id="getCode">
                <div id="sortable" className="tblSort">
                    {this.props.data.length > 0 ? this.props.data.map( (val , index) => {
                        
                        //console.log(val.images.FrontImageName);
                        var Details = JSON.parse(val.car.EnquiryStatusData)
                        console.log('Details----',Details);

                        if(Details == null){ Details = {}; }
                        //console.log('HasWarrentyBook', Details.HasWarrentyBook);
                        return (

                            <div className="compare-list compare-datatable ui-sortable-handle">
                                <table className="compare-list compare-datatable" style={{ width: "100%", border: "0", cellspacing: "0", cellpadding: "0" }}>
                                    <tbody>
                                        <tr className="delete">
                                            <td className="odd"><a  className="drop_item">
                                                {/* <span>Ad ID:{val.car.VehicleID}</span> */}
                        <span style={{cursor: 'pointer'}} className="remove" onClick={ ()=> this.Remove(val.car.VehicleID)}  >x</span></a></td>
                                        </tr>
                                        <tr className={this.state.check == 0 ? 'image' : 'image fixed-image' } >
                                            <td className="odd"><span><img className="img-responsive"
                                                src={val.images.FrontImageName ? val.images.FrontImageName : carNotFound} alt="" style={{ width: "190px", height: "138px" }} /></span></td>
                                        </tr>
                                        <tr className="price car-item">
                                            <td className="odd">
                                                <div className="price car-price "><span className="old-price" style={{display : val.car.DiscountedPrice == null ? "none" : "block"}}> {val.car.RetailPrice}</span><span
                                                    className="new-price"> PKR<span className="data"> <NumberFormat value= {val.car.DiscountedPrice == null ? val.car.RetailPrice : val.car.DiscountedPrice}  displayType={'text'} thousandSeparator={true}  />  </span> </span></div>
                                            </td>
                                        </tr>
                                        <tr className="year">
                                            <td id="year" className={this.state.hoverState == "year" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{val.car.ModelYear}</td>
                                        </tr>
                                        <tr className="make">
                                            <td id="make" className={this.state.hoverState == "make" ? 'odd hoverState' : 'odd'}  onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>Toyota</td>
                                        </tr>
                                        <tr className="model">
                                            <td id="model" className={this.state.hoverState == "model" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{val.car.ModelID}</td>
                                        </tr>
                                        {/* <tr className="body_style">
                                            <td className="odd">Coupe</td>
                                        </tr> */}
                                        <tr className="mileage">
                                            <td id="mileage" className={this.state.hoverState == "mileage" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{val.car.Mileage}</td>
                                        </tr>
                                        {/* <tr className="fuel_economy">
                                            <td className="odd">26</td>
                                        </tr> */}
                                        {/* <tr className="transmission">
                                            <td className="odd">6-Speed Automatic</td>
                                        </tr> */}
                                        <tr className="condition">
                                            <td id="condition" className={this.state.hoverState == "condition" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>New</td>
                                        </tr>
                                        {/* <tr className="drivetrain">
                                            <td className="odd">AWD</td>
                                        </tr> */}
                                        <tr className="engine">
                                            <td id="engine" className={this.state.hoverState == "engine" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>1300</td>
                                        </tr>
                                        <tr className="exterior_color">
                                            <td id="exterior_color" className={this.state.hoverState == "exterior_color" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{val.car.Color}</td>
                                        </tr>
                                        {/* <tr className="interior_color">
                                            <td className="odd" style={{ height: '43px' }}>Nougat Brown / Black</td>
                                        </tr> */}
                                        <tr className="stock_number">
                                            <td id="stock_number" className={this.state.hoverState == "stock_number" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>70A267</td>
                                        </tr>
                                        {/* <tr className="vin_number">
                                            <td className="odd">5YFBURHE4GP508652</td>
                                        </tr> */}
                                         <tr className="warrenty">
                                             <td id="warrenty" className={this.state.hoverState == "warrenty" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut} >{Details.HasWarrentyBook != undefined ? Details.HasWarrentyBook != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="ownermanual" className={this.state.hoverState == "ownermanual" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasOwnerManual != undefined ? Details.HasOwnerManual != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="saleinvoice" className={this.state.hoverState == "saleinvoice" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasSaleInvoice != undefined ? Details.HasSaleInvoice != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="registrationbook" className={this.state.hoverState == "registrationbook" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasRegisterationBook != undefined ? Details.HasRegisterationBook != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="runningpage" className={this.state.hoverState == "runningpage" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasRunningPage != undefined ? Details.HasRunningPage != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="cplcclear" className={this.state.hoverState == "cplcclear" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.IsCPLCClear != undefined ? Details.IsCPLCClear != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="jacknrod" className={this.state.hoverState == "jacknrod" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasJackNRod != undefined ? Details.HasJackNRod != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="toolkit" className={this.state.hoverState == "toolkit" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasToolkit != undefined ? Details.HasToolkit != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="sparewheel" className={this.state.hoverState == "sparewheel" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasSpareWheel != undefined ? Details.HasSpareWheel != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="numplate" className={this.state.hoverState == "numplate" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasNumPlate != undefined ? Details.HasNumPlate != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="chasisidplate" className={this.state.hoverState == "chasisidplate" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.HasChasisIdPlate != undefined ? Details.HasChasisIdPlate != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="stock_number">
                                             <td id="numberofkeys" className={this.state.hoverState == "numberofkeys" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>{Details.NumberOfKeys != undefined ? Details.NumberOfKeys != true ? "No" : "Yes" : "No"}</td>
                                        </tr>
                                        <tr className="features_options">
                                            <td id="featuresoptions" className={this.state.hoverState == "featuresoptions" ? 'odd hoverState' : 'odd'} onMouseEnter={this.OnMouseHover} onMouseLeave={this.OnMouseHoverOut}>
                                                <div>GPS - LCD</div>
                                            </td>
                                        </tr>
                                        <tr className="btn_options">
                                            <td className="even">
                                                 <div>
                                                    {/*pathname: '/form', state: {  data: val.car ,From : "S" } id={this.props.data.car.VehicleID} <button onClick={() => this.scheduleHandler(val.car)}  className="btn btn-outlined dark">SCHEDULE TEST DRIVE</button> */}
                                                     <span className="btn btn-primary"  id={val.car.VehicleID} onClick={this.RedirectToCarDetail1.bind(this)} to={{}} >View Details</span> {this.RedirectToCarDetail()}
                                                     
                                                    </div> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        )
                    }) : null 
                    //  <div className="compare-list compare-datatable ui-sortable-handle"> There is no car to compare.</div>
                    }
                </div>
              {this.props.data.length <= 0 ? <div className="compare-list compare-datatable ui-sortable-handle"> There is no car to compare.</div> : ''}

            </div>


        );
    }
}
export default CarDetails;