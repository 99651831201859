import http from "./httpService";
// import { apiUrl } from "../config.json";
// import { func } from "prop-types";
import axios from "axios";

const LOCALURL = "http://localhost:47279/";
// const LIVEURL = "http://74.208.111.168:8005/";
const LIVEURL =  "https://dealers.toyotasure.com.pk/"; 
//"https://dealers.toyotasure.com.pk/" //"http://dealers.toyotasure.com.pk/"; 


export function PostService(Method, Body) {
//  //
// var header = {
//   headers: {
//   "Access-Control-Allow-Origin": "*" 
//   },
// };

//http://52.166.130.104:8086/
//http://localhost:47279/
//return axios.post('http://localhost:47279/' + Method, Body);
//   },
return axios.post(  LIVEURL + Method, Body);

}

export function GetService(Method) {
// 
return http.get(  LIVEURL + Method,{
  headers: {
      'Set-Cookie' : 'HttpOnly;Secure;SameSite=Strict'
  }
  
});
}

export function PostService1(Method, Body)
{
  // 
  return axios.post(Method, { Body },
    {
      headers: {
          'Set-Cookie' : 'HttpOnly;Secure;SameSite=Strict'
      }
      
  })
      .then(res => {
        // 
        //console.log(res);
       //console.log(res.data);
      })
}
export function GetGoogle(Method) {
return http.get(Method);
}