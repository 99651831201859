import React from 'react';
// import toyota from '../../assets/images/logo.png';
import Form from '../common/form'
import { GetService, PostService } from "../../services/GeneralServices";
import { withRouter, Link, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import Loader from '../General/loader';


const image = require("../../assets/images/search_bar_bg.png")

class Search extends Form {
    constructor(props) {
        super(props);
        this.state = {
            maxPrice: [],
            loader: false,
            checkOpen: false,
            volume: 0,
            value: 5,
            value2: 10,
            value3: 10,
            value4: {
                min: 5,
                max: 10,
            },
            value5: {
                min: 3,
                max: 7,
            },

            Redirect: false,
            data: {
                maker: [],
                model: [],
                variant: [],
                cities: [],
                // priceRange: [{ Id: "1", Name: "500,000 - 1,000,000" }, { Id: "2", Name: "1,000,000 - 1,500,000" }, { Id: "3", Name: "1,500,000 - 2,000,000" }, { Id: "4", Name: "2,000,000 - 2,500,000" }, { Id: "5", Name: "2,500,000 - 3,000,000" }, { Id: "6", Name: "3,500,000 - 4,000,000" } , { Id: "7", Name: "4,500,000 - 4 Millions Plus" }],
                priceRange: [{ Id: "1", Name: "500,000" }, { Id: "2", Name: "1,000,000" }, { Id: "3", Name: "1,500,000" }, { Id: "4", Name: "2,000,000" }, { Id: "5", Name: "2,500,000" }, { Id: "6", Name: "3,500,000" }, { Id: "7", Name: "4,500,000" }],

            },
            Model: [],
            Variant: [],
            Cars: [],
            FilterValues: {


                Make: [],
                Model: [],
                Variant: [],
                Year: [],
                City: [],
                Price: "",
                Registration_City: [],
                Mileage: "",
                Transmission: [],
                SortBy: "",
                FilterFrom: ""
            },

            showpricerange: true,
            showpricedropdown: false,
            IsShowDropDown: false,
            pricetext_min: 'Select Range',
            pricetext_max: '',
            Range_min: '',
            Range_max: '',
        };

        this.OnsearchHome = this.OnsearchHome.bind(this)
        this.selectPriceRange = this.selectPriceRange.bind(this);
        this.selectMAXPriceRange = this.selectMAXPriceRange.bind(this);
        this.showpriceLeft = this.showpriceLeft.bind(this);
        this.showpriceRight = this.showpriceRight.bind(this);

        this.onChangeMin = this.onChangeMin.bind(this);
        this.onChangeMax = this.onChangeMax.bind(this);

    }
    handleOnChange = (value) => {



        //         let recipesCopy = JSON.parse(JSON.stringify(this.state.FilterValues))
        //         //make changes to ingredients
        //         recipesCopy[5] =value//whatever new ingredients are
        //         this.setState({
        //             FilterValues:recipesCopy 
        //          }) 

        // 

        this.setState({
            volume: value,
            //   this.state.data.Price:value,

        })
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleclick, false);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleclick, false);
    }
    handleclick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.setState({ showpricedropdown: false })
    }
    async componentDidMount() {

        const { data } = await GetService('api/Vehicle/GetDropDown');

        //console.log("Data", data)
        let data1 = { ...this.state.data };
        data1["maker"] = data.Data.maker;
        data1["model"] = data.Data.model;
        data1["variant"] = data.Data.variant;
        data1["cities"] = data.Data.cities;
        data1["dealershipStation"] = data.Data.dealershipStation;
        this.setState({ data: data1 })



    }
    changeMaker = ({ currentTarget: input }) => {
         ;
        let values = this.state.data.model.filter(val => {

            return val.MakerID == input.value;
        });
        let data = { ...this.state.data };
        data["Make"] = "";
        data["Model"] = "";
        data["Variant"] = "";
        if (values.length == 0) {
            this.setState({ data: data })

        }
        else {
            this.setState({ Model: values })

        }
        console.table(values)

    }
    changeModel = ({ currentTarget: input }) => {
         ;
        let values = this.state.data.variant.filter(val => {

            return val.ModelID == input.value;
        });
        let data = { ...this.state.data };
        data["Variant"] = "";
        if (values.length == 0) {
            this.setState({ data: data })

        }
        else {
            this.setState({ Variant: values })
        }
        //console.log(this.state.Varaint)

    }
    onchangePrice(event) {

        let val = { ...this.state.data }
        val["Price"] = event.target.value;
        this.setState({ data: val })

    }
    renderRedirect = () => {

        if (this.state.Redirect) {

            return <Redirect to={{ pathname: '/car', state: { data: this.state.Cars } }} />

        }
        else {

        }
    }
    async OnsearchHome() {
         ;
        this.state.FilterValues["Make"] = this.state.data.Make == undefined || this.state.data.Make == "" ? [] : [{ Id: this.state.data.Make }];
        this.state.FilterValues["Model"] = this.state.data.Model == undefined || this.state.data.Model == "" ? [] : [{ Id: this.state.data.Model }];
        this.state.FilterValues["Variant"] = this.state.data.Variant == undefined || this.state.data.Variant == "" ? [] : [{ Id: this.state.data.Variant }];
        this.state.FilterValues["City"] = this.state.data.City == undefined || this.state.data.City == "" ? [] : [{ Id: this.state.data.City }];
        //this.state.FilterValues["Price"] = this.state.data.Price == undefined || this.state.data.Price == "" ? "" : this.state.data.Price;
        this.state.FilterValues["Price"] = this.state.pricetext_min == "Select Range" ? "" : `${this.state.pricetext_min} ${this.state.pricetext_max}`;

        // var model = {

        //     Make: [
        //         this.state.data.Make == undefined ? null :
        //             { Id: this.state.data.Make }
        //     ],
        //     Model: [p
        //         this.state.data.Model == undefined ? null :
        //             { Id: this.state.data.Model }
        //     ],
        //     Variant: [
        //         { Id: this.state.data.Variant }
        //     ],
        //     Year: [

        //     ],
        //     City: [
        //         { Id: this.state.data.City }
        //     ],
        //     Registration_City: [

        //     ],
        //     Mileage: "",
        //     Price: this.state.data.Price,
        //     Transmission: [

        //     ],
        //     SortBy: ""



        // }


        try {


            const { Range_min, Range_max } = this.state;

            if (Range_max != "4 Millions Plus") {
                if (parseFloat(Range_min.replace(/,/g, '')) > parseFloat(Range_max.replace(/,/g, ''))) {

                    toast.warn("Min Price must be greater than Max Price", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return
                }
            }

            this.setState({
                loader: true
            })

        } catch (error) {
            this.setState({
                loader: false
            })
            toast.warn(error, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

        const { data } = await PostService("api/vehicle/GetCars", this.state.FilterValues);
        this.setState({ Cars: data.Data.ListOfCarsWithImages, Redirect: true, loader: false });


    }




    selectPriceRange(e) {
         ;
        var data = [];
        if (e.target.value == "4,500,000") {
            data.push({Id:8, Name : "4 Millions Plus"});
        }
        
        else{
            data = this.state.data.priceRange.filter(x => {

                return parseInt(x.Name.trim().replace(/,/g, '')) > parseInt(e.target.value.replace(/,/g, ''))
            })
            data.push({Id: 8, Name: "4 Millions Plus"})
            let mix = data.filter(x => {
                return x.Name !== "4,500,000"
            })
            data = mix;
        }
        const price = e.target.value;
        //console.log(price);

        this.setState({ Range_min: price, showpricerange: false, pricetext_min: price, maxPrice: data })
    }

    selectMAXPriceRange(e) {
         ;
        const price = e.target.value;
        //console.log(price);
        this.setState({ Range_max: price, showpricedropdown: false, pricetext_max: ` - ${price}` })
    }


    showpriceLeft() {
        this.setState({ showpricerange: true })
    }

    showpriceRight() {
        this.setState({ showpricerange: false })
    }

    onChangeMin(value) {
        this.setState({
            Range_min: value,
            pricetext_min: value
        });
    }
    onChangeMax(value) {
        this.setState({
            Range_max: value,
            pricetext_max: ` - ${value}`
        });
    }


    showlaoder() {

        return (
            this.state.loader ? <Loader /> : ''
        )
    }


    render() {

        let { volume } = this.state

        // console.log(this.state)
        // console.log(this.state.maker)

        //console.log('this.state.data.priceRange',this.state.data.priceRange);
        if (this.state.data.maker === "" || this.state.data.maker === undefined || this.state.data.maker === null) {
            return <div></div>;
        }
        return (

            <div className="tys-section tys-search-banner l-mode" style={{ backgroundImage: "url(" + image + ")" }}>
                <div className="container">
                    <div className="row tys-select-wrapper light">
                        <div className="col-md col-sm-6 align-self-center">

                            {this.renderSelect("Make", "Make", this.state.data.maker, 'Id', 'Name', this.changeMaker.bind(this))}
                        </div>
                        <div className="col-md col-sm-6 align-self-center">
                            {this.renderSelect("Model", "Model", this.state.Model, 'Id', 'Name', this.changeModel)}
                        </div>
                        <div className="col-md col-sm-6 align-self-center">
                            {this.renderSelect("Variant", "Variant", this.state.Variant, 'Id', 'Name')}
                        </div>
                        <div className="col-md col-sm-6 align-self-center">
                            {this.renderSelect("City", "City", this.state.data.cities, 'Id', 'Name')}
                        </div>
                        <div className="col-md col-sm-6 align-self-center wd" ref={node => this.node = node}>
                            {/* {this.renderSelect("Price", "Price", this.state.data.priceRange, 'Name', 'Name')} */}
                            <label htmlFor="Price">Price</label>
                            <span className="custom-select align-self-center" style={{ cursor: 'pointer', width: '237px' }} onClick={() => { this.setState({ showpricedropdown:  !this.state.showpricedropdown, showpricerange: true }) }} > {`${this.state.pricetext_min}  ${this.state.pricetext_max}`}  </span>
                            <div className="col-md-12 m-0 row " style={{ display: this.state.showpricedropdown ? 'block' : 'none' }} >
                                <div className="pb-1 pt-1 row padi" style={{ zIndex: '9' }}><div className="col-md-6 p-0 pl-1" >
                                    <div className="bs-example bs-example-form"> <div className="input-group example" >
                                        {/* <input type="number" className="form-control form-control-sm m-md-0 p-lg-1 fc" placeholder="Min" value={this.state.Range_min} onFocus={this.showpriceLeft} onChange={e => this.onChangeMin(e.target.value)} /> */}
                                        <NumberFormat displayType={'input'} thousandSeparator={true} className="form-control form-control-sm m-md-0 p-lg-1 fc" placeholder="Min" value={this.state.Range_min} onFocus={this.showpriceLeft} onChange={e => this.onChangeMin(e.target.value)} />
                                        {/* <span className="input-group-addon mt-2 text-body iput" >lacs</span> */}
                                    </div>
                                    </div>
                                </div>
                                    <div className="border-left col-md-6 p-0"><div className="bs-example bs-example-form"> <div className="input-group m-auto">
                                        {/* <input type="number" className="form-control form-control-sm ml-2 mx-lg-1 price" placeholder="Max" value={this.state.Range_max} onFocus={this.showpriceRight} onChange={e => this.onChangeMax(e.target.value)}/> */}


                                        {this.state.Range_max.toLowerCase() == "4 Millions Plus".toLowerCase() ?
                                            <input type="text" className="form-control form-control-sm ml-2 mx-lg-1 price" value="4 Millions Plus" onFocus={this.showpriceRight} onChange={e => this.onChangeMax(e.target.value)} />
                                            :
                                            <NumberFormat displayType={'input'} thousandSeparator={true} className="form-control form-control-sm ml-2 mx-lg-1 price" placeholder="Max" value={this.state.Range_max} onFocus={this.showpriceRight} onChange={e => this.onChangeMax(e.target.value)} />
                                        }


                                        {/* <span className="input-group-addon ml-2 mr-0 mt-2 text-body ebox" >lacs</span> */}
                                    </div>  </div>
                                    </div>

                                    <div className={this.state.showpricerange ? 'col-md-12 mt-2 pl-1 pt-1 text-dark' : 'col-md-12 mt-2 pl-1 pt-1 text-dark box'}>

                                        {this.state.data.priceRange.length > 0 ? this.state.data.priceRange.map((item, index) => {
                                            return (<option key={index} className="pricedropdown" value={item.Name.trim()} onClick={this.selectPriceRange}>{item.Name.trim()}</option>)
                                        }) : ''}
                                        {/* <option className="pricedropdown" value="1,000,000" onClick={this.selectPriceRange}>1,000,000</option>
                                        <option className="pricedropdown" value="2,000,000" onClick={this.selectPriceRange}>2,000,000</option>
                                        <option className="pricedropdown" value="3,000,000" onClick={this.selectPriceRange}>3,000,000</option>
                                        <option className="pricedropdown" value="4,000,000" onClick={this.selectPriceRange}>4,000,000</option>
                                        <option className="pricedropdown" value="5,000,000" onClick={this.selectPriceRange}>5,000,000</option> */}
                                    </div>
                                    <div className={this.state.showpricerange ? 'col-md-12 mt-2 pl-1 pt-1 text-dark box' : 'col-md-12 mt-2 pl-1 pt-1 text-dark'} style={{ textAlign: 'right' }}>
                                        {this.state.maxPrice.length > 0 ? this.state.maxPrice.map((item, index) => {

                                            return (<option key={index} className="pricedropdown" value={item.Name.trim()} onClick={this.selectMAXPriceRange}>{item.Name.trim()}</option>)
                                        }) : ''}
                                        {/* <option className="pricedropdown" value="6,000,000" onClick={this.selectMAXPriceRange}>1,500,000</option>
                                        <option className="pricedropdown" value="7,000,000" onClick={this.selectMAXPriceRange}>2,500,000</option>
                                        <option className="pricedropdown" value="8,000,000" onClick={this.selectMAXPriceRange}>3,500,000</option>
                                        <option className="pricedropdown" value="9,000,000" onClick={this.selectMAXPriceRange}>4,500,000</option>
                                        <option className="pricedropdown" value="10,000,000" onClick={this.selectMAXPriceRange}>9,900,000</option> */}
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>



                        </div>
                        {/* <div className="col-md col-sm-12 align-self-center text-md-center text-sm-right">
                            <span id="advSearch">
                                {/* <span className="icon-elipses cursor-pointer d-md-block d-none"></span> */}
                        {/* <span className="mb-3 font-weight-bold text-underline d-md-none d-block">Show Advance Search</span>
                            </span>

                        </div> */}
                        {/* <div className="col-md-2 col-sm-6  align-self-center d-lg-block d-none">
                            <span className="font-weight-bold text-upper"style={{display:'block'}}>Find Your Car</span>
                            <a onClick={this.OnsearchHome} >Advance Search &gt;&gt;</a>
                        </div> */}
                        <div className="col-md col-sm-12 align-self-center  d-md-block d-none">
                            <button href="" className="tys-search-btn" onClick={this.OnsearchHome} >
                                <span className="icon-searchBar"></span></button>
                            {this.renderRedirect()}
                        </div>


                    </div>
                    <div className="row d-md-none d-block">
                        <div className="col text-right">
                            <button href="" className="tys-search-btn" onClick={this.OnsearchHome}>
                                <span className="" >Search</span>
                                {this.renderRedirect()}
                            </button>
                        </div>
                    </div>
                </div>
                {this.showlaoder()}
                <ToastContainer />

            </div>

        )
    }
}

export default Search;